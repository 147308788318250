import {TableRow as MuiTableRow} from '@mui/material'
import {styled} from '@mui/material/styles'
import {isFunction} from 'lodash'
import type {ComponentProps, FC} from 'react'


type TableRowProps = ComponentProps<typeof MuiTableRow> & {
  customRowStyles?: Record<string, unknown>
}

const StyledTableRow = styled(MuiTableRow, {
  shouldForwardProp: (prop) => prop !== 'customRowStyles',
})<TableRowProps>(({customRowStyles, onClick}) => ({
  cursor: onClick ? 'pointer' : 'default',
  ...customRowStyles,
}))

const TableRow: FC<TableRowProps> = ({customRowStyles, onClick, children, ...props}) => {
  return (
    <StyledTableRow customRowStyles={customRowStyles} hover={isFunction(onClick)} onClick={onClick} {...props}>
      {children}
    </StyledTableRow>
  )
}

export default TableRow
