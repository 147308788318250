import {Paper} from '@mui/material'
import {styled} from '@mui/material/styles'
import type {FC, ReactNode} from 'react'


const StyledMuiPaper = styled(Paper)(({theme}) => ({
  padding: theme.spacing(2),
  width: '100%',
  height: '100%',
  overflow: 'auto',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}))

const StyledHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: theme.spacing(2),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
}))

const StyledTitle = styled('h2')(({theme}) => ({
  margin: `0 ${theme.spacing(2)} 0 0`,

  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    margin: `0 0 ${theme.spacing(2)} 0`,
  },
}))

const StyledContent = styled('div')(({theme}) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}))


type FormSectionProps = {
  title: string,
  actions?: ReactNode,
  children?: ReactNode,
}

const FormSection: FC<FormSectionProps> = ({title, actions, children, ...props}) => {
  return (
    <StyledMuiPaper {...props}>
      <StyledHeader>
        <StyledTitle>
          {title}
        </StyledTitle>
        {actions}
      </StyledHeader>
      <StyledContent>
        {children}
      </StyledContent>
    </StyledMuiPaper>
  )
}

export default FormSection
