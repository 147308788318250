import type {Getter} from '@tanstack/react-table'
import {isEmpty} from 'lodash'
import type {ReactNode} from 'react'
import Fallback from './Fallback'


type OptionalCellArgs = {
  fallback?: ReactNode
}

const optionalCell = ({fallback = '–'}: OptionalCellArgs) => {
  const renderOptionalCell = <TValue, >(
    {getValue}: {getValue: Getter<TValue | TValue[]>},
  ) => {
    const value = getValue()

    if (isEmpty(value)) return <Fallback>{fallback}</Fallback>
    return value
  }
  return renderOptionalCell
}

export default optionalCell
