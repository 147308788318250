import SearchIcon from '@mui/icons-material/Search'
import {IconButton} from '@mui/material'
import {If} from 'babel-plugin-jsx-control-statements'
import type {ComponentProps, FC} from 'react'
import type {UseFieldConfig} from 'react-final-form'
import {useField} from 'react-final-form'
import {TextInput} from '../visual'
import type {ShowError} from './forms'
import {showErrorOnChange} from './forms'


type TextInputProps = ComponentProps<typeof TextInput> & {
  name: string,
  readOnly?: boolean,
  showError?: ShowError,
  fieldProps?: UseFieldConfig<string, string>,
  onApply?: () => void,
}

const SearchBoxInput: FC<TextInputProps> = ({
  name, label, required, helperText, fullWidth, showError = showErrorOnChange, fieldProps, onApply, ...props
}) => {
  const {input: {value, type, onChange, ...restInput}, meta} = useField<string>(name, fieldProps)
  const {isError, helperTextOrError} = showError({meta, helperText})

  return (
    <TextInput
        wide
        fullWidth={fullWidth}
        label={label}
        helperText={helperTextOrError}
        error={isError}
        onChange={onChange}
        name={name}
        value={value}
        type={type}
        required={required}
        inputProps={{required, ...restInput}}
        InputProps={{
          endAdornment: (
            <If condition={onApply}>
              <IconButton onClick={onApply} title="Hledat"><SearchIcon /></IconButton>
            </If>
          ),
        }}
        margin="none"
        {...props}
    />
  )
}

export default SearchBoxInput
