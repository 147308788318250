import type {GetLicenseResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import {useState} from 'react'
import withForm from '../../form/withForm'
import {FormSection, Tabs} from '../../visual'
import CancellationPanel, {CANCELLATION_PANEL_LABEL} from './panels/CancellationPanel'
import GeneralPanel, {GENERAL_PANEL_LABEL} from './panels/GeneralPanel'


const schema = schemas.license.license

const tabs = [GENERAL_PANEL_LABEL, CANCELLATION_PANEL_LABEL]

type LicenseFormProps = {
  license?: GetLicenseResponseSchema
  readOnly?: boolean
}

const LicensesForm = withForm({schema})<LicenseFormProps>(({license, readOnly}) => {
  const isNewRecord = !license
  const [activeTab, setActiveTab] = useState(0)
  return (
    <FormSection title="Detail licence">
      <Tabs
          labels={tabs}
          value={activeTab}
          onChange={(_e, value: number) => setActiveTab(value)}
      >
        <Tabs.Panel value={activeTab} index={0}>
          <GeneralPanel readOnly={readOnly} />
        </Tabs.Panel>
        <Tabs.Panel value={activeTab} index={1}>
          <CancellationPanel isNewRecord={isNewRecord} readOnly={readOnly} />
        </Tabs.Panel>
      </Tabs>
    </FormSection>
  )
})

export default LicensesForm
