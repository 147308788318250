import {If} from 'babel-plugin-jsx-control-statements'
import type {GetSidingResponseSchema} from 'common/responses'
import {INFRASTRUCTURE} from 'constants/departments'
import * as resources from 'constants/resources'
import type {ComponentProps, FC} from 'react'
import {useState} from 'react'
import {useCreateResource} from '../../../hooks/api'
import {useSession} from '../../../hooks/auth'
import {useAlert} from '../../../hooks/useAlert'
import Button from '../../containers/Button'
import {Modal} from '../../visual'
import SupervisionsForm from './SupervisionsForm'


type SupervisionsCreateProps = {
  sidingId: GetSidingResponseSchema['id']
  permit: GetSidingResponseSchema['permits'][number] | null
  disabled?: boolean
}

const SupervisionsCreate: FC<SupervisionsCreateProps> = ({sidingId, permit, disabled}) => {
  const session = useSession()
  const createCompanies = useCreateResource({resource: resources.SUPERVISIONS})
  const showAlert = useAlert()
  const [open, setOpen] = useState(false)

  const handleCreateSupervision: ComponentProps<typeof SupervisionsForm>['onSubmit'] = async (values) => {
    const {data} = await createCompanies.mutateAsync(values)
    setOpen(false)
    showAlert(`Dozor ${data.id} vytvořen`, 'success')
  }

  return (
    <>
      <Button onClick={() => setOpen(true)} disabled={disabled}>Nový státní dozor</Button>
      <If condition={open}>
        <SupervisionsForm
            initialValues={{
              companyId: permit?.company.id,
              sidingId,
              department: session?.department || undefined,
            }}
            innerProps={{
              canChangeDepartment: session?.department !== INFRASTRUCTURE,
            }}

            onSubmit={handleCreateSupervision}
        >
          {({form, handleSubmit}) => (
            <Modal open onClose={() => setOpen(false)}>
              <Modal.Title>Nový státní dozor</Modal.Title>
              <Modal.Content>{form}</Modal.Content>
              <Modal.Actions>
                <Button variant="contained" onClick={handleSubmit}>
                  Vytvořit záznam dozoru
                </Button>
                <Button onClick={() => setOpen(false)}>Zrušit</Button>
              </Modal.Actions>
            </Modal>
          )}
        </SupervisionsForm>
      </If>
    </>
  )
}

export default SupervisionsCreate
