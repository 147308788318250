import {TableCell} from '@mui/material'
import type {Row, RowData} from '@tanstack/react-table'
import {flexRender} from '@tanstack/react-table'
import {isFunction} from 'lodash'
import type {ComponentProps, MouseEvent} from 'react'
import {TableRow} from '../../visual'


type RowClickHandler<TData> = (
  row: Row<TData>, e?: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>
) => void

type DatagridRowProps<TData> = {
  row: Row<TData>
  onRowClick?: RowClickHandler<TData>
  rowStyles?: ComponentProps<typeof TableRow>['customRowStyles'] | ((row: Row<TData>) => ComponentProps<typeof TableRow>['customRowStyles'])
}

const DatagridRow = <TData extends RowData>({row, rowStyles, onRowClick}: DatagridRowProps<TData>) => {
  const isSelected = row.getCanSelect() && row.getIsSelected()
  const customRowStyles = isFunction(rowStyles) ? rowStyles(row) : rowStyles

  return (
    <TableRow
        selected={isSelected}
        customRowStyles={customRowStyles}
        onClick={onRowClick ? (e) => onRowClick(row, e) : undefined}
    >
      {row.getVisibleCells().map((cell) => (
        <TableCell key={cell.id} padding={cell.column.columnDef.meta?.padding || 'normal'}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </TableCell>
      ))}
    </TableRow>
  )
}


export default DatagridRow
