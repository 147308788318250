import * as permissions from 'constants/permissions'


export default {
  [permissions.EDIT_COMPANIES]: 'Úprava firem',
  [permissions.EDIT_FOREIGN_CARRIERS]: 'Úprava zahraničních dopravců',
  [permissions.EDIT_LICENSES]: 'Úprava licencí',
  [permissions.EDIT_PERMITS]: 'Úprava povolení',
  [permissions.EDIT_SIDINGS]: 'Úprava vleček',
  [permissions.EDIT_SIDING_CANCELLATION]: 'Úprava rušení vleček',
  [permissions.EDIT_SUPERVISIONS]: 'Úprava dohledů',
  [permissions.EDIT_USERS]: 'Úprava uživatelů',
  [permissions.MANAGE_COMPANIES]: 'Správa firem',
  [permissions.MANAGE_FOREIGN_CARRIERS]: 'Správa zahraničních dopravců',
  [permissions.MANAGE_LICENSES]: 'Správa licencí',
  [permissions.MANAGE_PERMITS]: 'Správa povolení',
  [permissions.MANAGE_SIDINGS]: 'Správa vleček',
  [permissions.MANAGE_SIDING_CANCELLATION]: 'Správa rušení vleček',
  [permissions.MANAGE_SUPERVISIONS]: 'Správa dohledů',
  [permissions.MANAGE_USERS]: 'Správa uživatelů',
  [permissions.RESTORE_SIDINGS]: 'Obnovení vleček',
} as const
