import type {RowSelectionState} from '@tanstack/react-table'
import {createColumnHelper} from '@tanstack/react-table'
import {PERMISSIONS} from 'common/labels/enums'
import type {ListRoleResponseSchema} from 'common/responses'
import type {ComponentProps, Dispatch, FC, SetStateAction} from 'react'
import {getSortableColumns} from '../../../utils/lists'
import ControlledDatagrid from '../../containers/Datagrid/ControlledDatagrid'
import enumCell from '../../containers/Datagrid/cells/enumCell'


const columnHelper = createColumnHelper<ListRoleResponseSchema[number]>()

const columns = [
  columnHelper.accessor('id', {header: 'ID'}),
  columnHelper.accessor('name', {header: 'Název'}),
  columnHelper.accessor('permissions', {header: 'Oprávnění', cell: enumCell({enumName: PERMISSIONS}), enableSorting: false}),
]

export const sortableColumns = getSortableColumns(columns)

type Query = ComponentProps<typeof ControlledDatagrid>['query']

type RolesTableProps = {
  roles: ListRoleResponseSchema
  total: number | null
  query: Query
  setQuery: Dispatch<SetStateAction<Query>>
  rowSelection: RowSelectionState
  setRowSelection: Dispatch<SetStateAction<RowSelectionState>>
}

const RolesTable: FC<RolesTableProps> = ({roles, total, query, setQuery, rowSelection, setRowSelection, ...props}) => {
  return (
    <ControlledDatagrid<ListRoleResponseSchema[number]>
        data={roles}
        columns={columns}
        total={total}
        query={query}
        setQuery={setQuery}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        onRowClick={(row) => setQuery((prevQuery) => ({...prevQuery, roleId: row.original.id}))}
        {...props}
    />
  )
}

export default RolesTable
