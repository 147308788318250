export const COMPANIES = 'companies'
export const FOREIGN_CARRIERS = 'foreignCarriers'
export const LICENSES = 'licenses'
export const PERMITS = 'permits'
export const ROLES = 'roles'
export const SIDINGS = 'sidings'
export const SUPERVISIONS = 'supervisions'
export const USERS = 'users'
export const SIDING_CANCELLATIONS = 'sidingCancellations'

export const SIDING_REVISIONS = 'sidingRevisions'
export const PERMIT_REVISIONS = 'permitRevisions'
export const LICENSE_REVISIONS = 'licenseRevisions'
export const COMPANY_REVISIONS = 'companyRevisions'
export const FOREIGN_CARRIER_REVISIONS = 'foreignCarrierRevisions'
export const SIDING_CANCELLATION_REVISIONS = 'sidingCancellationRevisions'

export const ALL_RESOURCES = [
  COMPANIES,
  FOREIGN_CARRIERS,
  LICENSES,
  PERMITS,
  ROLES,
  SIDINGS,
  SUPERVISIONS,
  USERS,
  SIDING_CANCELLATIONS,

  SIDING_REVISIONS,
  PERMIT_REVISIONS,
  LICENSE_REVISIONS,
  COMPANY_REVISIONS,
  FOREIGN_CARRIER_REVISIONS,
  SIDING_CANCELLATION_REVISIONS,
] as const

export type Resource = typeof ALL_RESOURCES[number]
