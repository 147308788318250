import SaveIcon from '@mui/icons-material/Save'
import {useQuery} from '@tanstack/react-query'
import {If} from 'babel-plugin-jsx-control-statements'
import type {GetUserResponseSchema} from 'common/responses'
import {EDIT_USERS} from 'constants/permissions'
import * as resources from 'constants/resources'
import type {ComponentProps} from 'react'
import {Navigate, useNavigate} from 'react-router'
import * as clientRoutes from '../../../constants/routes'
import {createOneQuery, useUpdateResource} from '../../../hooks/api'
import {useSession} from '../../../hooks/auth'
import {useAlert} from '../../../hooks/useAlert'
import useParams from '../../../hooks/useParams'
import usePermitted from '../../../hooks/usePermitted'
import Breadcrumbs from '../../containers/Breadcrumbs'
import Button from '../../containers/Button'
import FormBlocker from '../../containers/FormBlocker'
import ReadOnlyAlert from '../../containers/alerts/ReadOnlyAlert'
import {ScreenHeader, ScreenWrapper} from '../../visual'
import NotFound from '../NotFound'
import UsersForm from './UsersForm'
import UsersPasswordChange from './UsersPasswordChange'


export const queryDef = ({userId}: {userId: number}) => createOneQuery<GetUserResponseSchema>({
  resource: resources.USERS,
  id: userId,
})

const UsersEdit = () => {
  const session = useSession()
  const canEdit = usePermitted([EDIT_USERS])
  const {userId} = useParams()
  const navigate = useNavigate()
  const showAlert = useAlert()
  const {data} = useQuery({...queryDef({userId: Number(userId)}), enabled: Boolean(Number(userId))})
  const updateUser = useUpdateResource({resource: resources.USERS, id: userId})
  const user = data?.data

  if (session?.userId === Number(userId)) return <Navigate to={clientRoutes.PROFILE} />

  if (!user || user.deletedAt) return <NotFound />

  const hadndleUpdateUser: ComponentProps<typeof UsersForm>['onSubmit'] = async (values) => {
    await updateUser.mutateAsync(values)
    navigate(clientRoutes.USERS)
    showAlert(`Uživatel ${userId} uložen`, 'success')
  }

  return (
    <UsersForm
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          roleId: user.roleId || undefined,
          department: user.department || undefined,
        }}
        innerProps={{user, readOnly: !canEdit}}
        onSubmit={hadndleUpdateUser}
    >
      {({form, handleSubmit}) => (
        <ScreenWrapper>
          <FormBlocker />
          <ScreenHeader
              title={`${user.firstName} ${user.lastName}`}
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item>Administrace</Breadcrumbs.Item>
                  <Breadcrumbs.Item to={clientRoutes.USERS}>Uživatelé</Breadcrumbs.Item>
                  <Breadcrumbs.Item active>{user.firstName} {user.lastName}</Breadcrumbs.Item>
                </Breadcrumbs>
              }
          >
            <If condition={canEdit}>
              <UsersPasswordChange userId={user.id} />
              <Button
                  type="submit"
                  variant="contained"
                  icon={<SaveIcon />}
                  onClick={handleSubmit}
              >
                Uložit změny
              </Button>
            </If>
          </ScreenHeader>
          <If condition={!canEdit}>
            <ReadOnlyAlert />
          </If>
          {form}
        </ScreenWrapper>
      )}
    </UsersForm>
  )
}

export default UsersEdit
