import {Stack} from '@mui/material'
import * as enums from 'common/labels/enums'
import * as schemas from 'common/schemas'
import * as resources from '../../../../../constants/resources'
import {useAsyncUniqueValidate} from '../../../hooks/useAsyncValidate'
import EnumInput from '../../form/EnumInput'
import TextInput from '../../form/TextInput'
import withForm from '../../form/withForm'


const schema = schemas.role.role

type RolesFormProps = {
  readOnly?: boolean
}

const RolesForm = withForm({schema})<RolesFormProps>(({readOnly}) => {
  const validateName = useAsyncUniqueValidate({
    resource: resources.ROLES,
    column: 'name',
  })
  return (
    <Stack useFlexGap gap={2}>
      <TextInput
          name="name"
          label="Název"
          margin="dense"
          readOnly={readOnly}
          required
          fieldProps={{validate: validateName}}
      />
      <EnumInput
          name="permissions"
          label="Oprávnění"
          enumName={enums.PERMISSIONS}
          margin="dense"
          readOnly={readOnly}
          multiple
      />
    </Stack>
  )
})


export default RolesForm
