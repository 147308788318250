import type {FC, ReactNode} from 'react'
import {Suspense} from 'react'
import {AppContainer, Loading, Footer} from '../visual'


type ScreenProps = {
  children: ReactNode,
}

const Screen: FC<ScreenProps> = ({children}) => {
  return (
    <AppContainer>
      <Suspense fallback={<Loading />}>
        <div>
          {children}
        </div>
        <Footer from={1997} version="1.0.0" />
      </Suspense>
    </AppContainer>
  )
}

export default Screen
