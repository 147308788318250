import {Alert} from '@mui/material'
import {useFormState} from 'react-final-form'


const FormErrorAlert = () => {
  const form = useFormState()

  if (!form.submitFailed || !form.hasValidationErrors) return null

  return (
    <Alert severity="error">
      Ve formuláři se vyskytla chyba. Prosím, zkontrolujte všechna pole i v jiných kartách
    </Alert>
  )
}

export default FormErrorAlert
