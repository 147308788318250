import {createColumnHelper} from '@tanstack/react-table'
import type {ListForeignCarrierResponseSchema} from 'backend/src/controllers/foreignCarriers/ListForeignCarrier'
import {ACTIVITY_TYPES, LICENSE_NSA_TYPES, TRANSPORT_TYPES} from 'common/labels/enums'
import type {ComponentProps, Dispatch, FC, SetStateAction} from 'react'
import {useNavigate} from 'react-router'
import * as clientRoutes from '../../../constants/routes'
import generatePath from '../../../utils/generatePath'
import ControlledDatagrid from '../../containers/Datagrid/ControlledDatagrid'
import enumCell from '../../containers/Datagrid/cells/enumCell'
import optionalCell from '../../containers/Datagrid/cells/optionalCell'


const columnHelper = createColumnHelper<ListForeignCarrierResponseSchema[number]>()

const columns = [
  columnHelper.accessor('id', {header: 'ID'}),
  columnHelper.accessor('name', {header: 'Obchodní jméno'}),
  columnHelper.accessor('licenseNSA', {header: 'Vydávající NSA', cell: enumCell({enumName: LICENSE_NSA_TYPES})}),
  columnHelper.accessor('transportType', {header: 'Typ dopravy', cell: enumCell({enumName: TRANSPORT_TYPES})}),
  columnHelper.accessor('activityType', {header: 'Působnost', cell: enumCell({enumName: ACTIVITY_TYPES})}),
  columnHelper.accessor('carrierCertificate', {header: 'Evidenční číslo osvědčení', cell: optionalCell({})}),
]

export const sortableColumns = ['id', 'name', 'licenseNSA', 'transportType', 'activityType', 'carrierCertificate'] as const

type Query = ComponentProps<typeof ControlledDatagrid>['query']

type ForeignCarriersTableProps = {
  foreignCarriers: ListForeignCarrierResponseSchema
  total: number | null
  query: Query
  setQuery: Dispatch<SetStateAction<Query>>
}

const ForeignCarriersTable: FC<ForeignCarriersTableProps> = ({
  foreignCarriers, total, query, setQuery, ...props
}) => {
  const navigate = useNavigate()
  return (
    <ControlledDatagrid<ListForeignCarrierResponseSchema[number]>
        data={foreignCarriers}
        columns={columns}
        total={total}
        query={query}
        setQuery={setQuery}
        onRowClick={(row) => navigate(
          generatePath(clientRoutes.FOREIGN_CARRIERS_EDIT, {foreignCarrierId: row.id}),
        )}
        {...props}
    />
  )
}

export default ForeignCarriersTable
