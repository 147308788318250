import type {JSONValue} from 'common/schemas'
import type {Resource} from 'constants/resources'
import type {FieldState} from 'final-form'
import {memoize, some} from 'lodash'
import {useCallback} from 'react'
import type {TypeOf, ZodTypeAny} from 'zod'
import {generateResourcePath} from '../../../constants/routes'
import {api} from '../utils/api'
import {readSession} from './auth'


type AsyncValidateArgs = {
  resource: Resource
  column: string
  deleted?: boolean
}
export const useAsyncUniqueValidate = <TSchema extends ZodTypeAny>({
  resource, column, deleted,
}: AsyncValidateArgs) => {
  const route = generateResourcePath(resource)
  // UseCallback can't be inside memoize function because it could cause multiple unnecessary requests
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadResources = useCallback(memoize((value: JSONValue) => {
    const session = readSession()
    const query = {
      filter: {
        [column]: value,
        deleted: deleted ? undefined : false,
      },
    }
    return api<TypeOf<TSchema>>('GET', route, {query, sessionToken: session?.token})
  }), [column, deleted, route])
  return useCallback(async (
    value: JSONValue, _values: Record<string, JSONValue>, meta: FieldState<JSONValue> | undefined,
  ) => {
    if (!value || !meta?.valid || meta?.pristine) return
    const res = await loadResources(value)
    if (!res?.total) return
    if (some(res.data, {[column]: value})) return 'Hodnota musí byť unikátna'
  }, [column, loadResources])
}
