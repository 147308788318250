import {styled} from '@mui/material/styles'
import type {FC, ReactNode} from 'react'


const StyledMain = styled('main')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'auto',
  width: '100%',
  padding: `calc(${theme.mixins.toolbar.height} + ${theme.spacing(3)}) ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  [theme.breakpoints.down('sm')]: {
    padding: `calc(${theme.mixins.toolbar.height} + ${theme.spacing(2)}) ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)}`,
  },
}))

type AppContainerProps = {
  children: ReactNode,
}

const AppContainer: FC<AppContainerProps> = ({children}) => {
  return (
    <StyledMain>
      {children}
    </StyledMain>
  )
}

export default AppContainer
