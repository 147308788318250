import {List} from '@mui/material'
import {styled} from '@mui/material/styles'
import type {ComponentProps, FC} from 'react'


type NavigationListProps = ComponentProps<typeof List> & {
  open?: boolean,
}

const StyledList = styled(List)<NavigationListProps>(({theme, open}) => ({
  position: 'fixed',
  ...(open ? {
    width: '270px',
    transition: theme.transitions.create(['width', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('md')]: {
      'opacity': '1',
      '& svg': {
        opacity: '1',
      },
    },
  } : {
    width: '57px',
    overflowX: 'hidden',
    transition: theme.transitions.create(['width', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('md')]: {
      'opacity': '0',
      '& svg': {
        opacity: '0',
      },
    },
  }),
}))

const NavigationList: FC<NavigationListProps> = ({open, children, ...props}) => {
  return (
    <StyledList open={open} {...props}>
      {children}
    </StyledList>
  )
}

export default NavigationList
