import type z from 'zod'
import * as common from './common'
import * as types from './types'
import * as schemas from './index'


export const foreignCarrier = types.object({
  name: types.requiredString(),
  licenseNSA: common.licenseNSAType(),
  transportType: common.transportType(),
  activityType: common.activityType(),
  carrierCertificate: types.requiredString().nullable().optional(),
  carrierValidUntil: types.timestamp().nullable().optional(),
  carrierNSA: common.carrierNSAType().nullable().optional(),
  notes: types.text().optional(),
  revisionNote: schemas.text().optional(),
})
export type ForeignCarrier = z.infer<typeof foreignCarrier>
