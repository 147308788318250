import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {Collapse, List} from '@mui/material'
import {Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import {useState} from 'react'
import type {FC, ComponentProps} from 'react'
import NavigationItem from './NavigationItem'


type NestedNavigationListProps = ComponentProps<typeof NavigationItem> & {
  text: string,
  onClick?: () => void,
}

const NestedNavigationList: FC<NestedNavigationListProps> = ({text, icon, children, ...props}) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  return (
    <>
      <NavigationItem icon={icon} onClick={handleClick} {...props}>
        {text}
        <Choose>
          <When condition={open}>
            <ExpandLess />
          </When>
          <Otherwise>
            <ExpandMore />
          </Otherwise>
        </Choose>
      </NavigationItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  )
}

export default NestedNavigationList
