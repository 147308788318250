import LaunchIcon from '@mui/icons-material/Launch'
import RemoveIcon from '@mui/icons-material/Remove'
import {IconButton, Link} from '@mui/material'
import {Choose, When, Otherwise, If} from 'babel-plugin-jsx-control-statements'
import type {ComponentProps, FC} from 'react'
import type {UseFieldConfig} from 'react-final-form'
import {useField} from 'react-final-form'
import * as clientRoutes from '../../constants/routes'
import {ExternalLink} from '../containers/ExternalLinkField'
import {StaticField, TextInput as VisualTextInput} from '../visual'
import type {ShowError} from './forms'
import {showErrorOnChange} from './forms'


type GinisPidProps = ComponentProps<typeof VisualTextInput> & {
  isNewRecord: boolean,
  name: string,
  readOnly?: boolean,
  disableSpacing?: boolean,
  showError?: ShowError,
  fieldProps?: UseFieldConfig<string, string>,
}

const GinisPidInput: FC<GinisPidProps> = ({
  isNewRecord, disableSpacing, readOnly, name, label, wide, disabled, required, helperText, fullWidth = true,
  showError = showErrorOnChange, fieldProps, ...props
}) => {
  const {input: {value, type, onChange, ...restInput}, meta} = useField<string>(name, fieldProps)
  const {isError, helperTextOrError} = showError({meta, helperText})

  return (
    <Choose>
      <When condition={!readOnly}>
        <VisualTextInput
            fullWidth={fullWidth}
            label={label}
            helperText={helperTextOrError}
            error={isError}
            disabled={disabled}
            onChange={onChange}
            name={name}
            value={value}
            type={type}
            required={required}
            wide={wide}
            inputProps={{required, ...restInput}}
            InputProps={{
              endAdornment: (
                <If condition={!isNewRecord}>
                  <IconButton LinkComponent={Link} href={clientRoutes.getGinisUrl(value)} target="_blank" referrerPolicy="no-referrer">
                    <LaunchIcon />
                  </IconButton>
                </If>
              ),
            }}
            {...props}
        />
      </When>
      <Otherwise>
        <StaticField label={label} disableSpacing={disableSpacing}>
          <Choose>
            <When condition={value}>
              <ExternalLink link={clientRoutes.getGinisUrl(value)} linkText="GINIS" />
            </When>
            <Otherwise>
              <RemoveIcon />
            </Otherwise>
          </Choose>
        </StaticField>
      </Otherwise>
    </Choose>
  )
}

export default GinisPidInput
