import {ListItemText, ListItem, Typography} from '@mui/material'
import MuiList from '@mui/material/List'
import {styled} from '@mui/material/styles'
import type {FC, ComponentProps, ReactNode} from 'react'


const StyledTitleContainer = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
}))

const StyledListItem = styled(ListItem)(({theme}) => ({
  display: 'flex',
  alignItems: 'start',
  alignSelf: 'center',
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}))

const StyledListItemText = styled(ListItemText)(() => ({
  '.MuiListItemText-secondary': {
    display: 'block',
    width: '20ch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))


type ItemProps = ComponentProps<typeof ListItem> & {
  date?: ReactNode,
  user?: ReactNode,
  message?: ReactNode,
  action?: ReactNode,
}

const Item: FC<ItemProps> = ({date, user, message, action, ...props}) => {
  return (
    <StyledListItem secondaryAction={action} {...props} title="">
      <div>
        <StyledListItemText primary={date} secondary={user} />
      </div>
      <ListItemText primary={message} />
    </StyledListItem>
  )
}

type RevisionsProps = ComponentProps<typeof MuiList> & {
  actions?: ReactNode,
  title?: ReactNode,
}

const Revisions: FC<RevisionsProps> & {Item: typeof Item} = ({title, actions, children, ...props}) => {
  return (
    <>
      <StyledTitleContainer>
        <Typography variant="subtitle2"><strong>{title}</strong></Typography>
        {actions}
      </StyledTitleContainer>
      <MuiList {...props}>
        {children}
      </MuiList>
    </>
  )
}

Revisions.Item = Item

export default Revisions
