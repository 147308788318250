import {Divider, Typography} from '@mui/material'
import {If} from 'babel-plugin-jsx-control-statements'
import type {GetCompanyInfoResponseSchema} from 'common/responses'
import type {FC} from 'react'
import {useForm} from 'react-final-form'
import BooleanInput from '../../../form/BooleanInput'
import DateInput from '../../../form/DateInput'
import TextInput from '../../../form/TextInput'
import {InputStack, Tabs} from '../../../visual'
import CompanyInput from '../components/CompanyInput'


export const GeneralPanelFieldsGeneral = ({readOnly}: {
  readOnly?: boolean
}) => {
  const form = useForm()

  const handleSelectCompany = (company: GetCompanyInfoResponseSchema[number]) => {
    if (!company) return
    form.batch(() => {
      form.change('name', company?.name)
      form.change('companyNumber', company?.companyNumber)
      form.change('address', company?.address)
      form.change('city', company?.city)
      form.change('postCode', company?.postCode)
      form.change('phone', company?.phone)
      form.change('email', company?.email)
      form.change('notes', company?.notes)
      form.change('isCarrier', Boolean(company?.carrierCertificate))
      form.change('carrierCertificate', company?.carrierCertificate)
      form.change('carrierValidUntil', company?.carrierValidUntil)
      form.change('statutories', company?.statutories)
    })
  }
  return (
    <>
      <InputStack>
        <CompanyInput<GetCompanyInfoResponseSchema>
            name="name"
            label="Obchodní jméno"
            searchMethod="name"
            helperText="Pro vyhledávání v ARES zadejte alespoň 5 znaků"
            getOptionLabel={(option) => `${option.name} (${option.companyNumber})`}
            onCompanyClick={handleSelectCompany}
            readOnly={readOnly}
            required
        />
        <CompanyInput<GetCompanyInfoResponseSchema>
            name="companyNumber"
            label="IČ"
            searchMethod="companyNumber"
            helperText="Pro vyhledávání v ARES zadejte přesně 8 čísel"
            getOptionLabel={(option) => `${option.name} (${option.companyNumber})`}
            onCompanyClick={handleSelectCompany}
            readOnly={readOnly}
            required
        />
      </InputStack>
      <InputStack>
        <TextInput name="address" label="Ulice" required readOnly={readOnly} />
        <InputStack>
          <TextInput name="city" label="Obec" required readOnly={readOnly} />
          <TextInput name="postCode" label="PSČ" required readOnly={readOnly} />
        </InputStack>
      </InputStack>
      <InputStack>
        <TextInput name="phone" label="Telefon" readOnly={readOnly} />
        <TextInput name="email" label="Emailová adresa" type="email" readOnly={readOnly} />
      </InputStack>
    </>
  )
}

export const GeneralPanelFieldsCertificate = ({readOnly, isCarrier}: {readOnly?: boolean, isCarrier?: boolean}) => {
  return (
    <>
      <BooleanInput name="isCarrier" label="Subjektu bylo uděleno osvědčení dopravce" readOnly={readOnly} />
      <If condition={isCarrier}>
        <InputStack>
          <TextInput name="carrierCertificate" label="Číslo osvědčení" required readOnly={readOnly} />
          <DateInput name="carrierValidUntil" label="Platné do" required readOnly={readOnly} />
        </InputStack>
      </If>
    </>
  )
}

export const GeneralPanelFieldsNote = ({readOnly}: {readOnly?: boolean}) => {
  return (
    <TextInput name="notes" label="Poznámka" placeholder="Zadejte libovolnou poznámku" minRows={5} multiline readOnly={readOnly} />
  )
}

type GeneralPanelProps = {
  isCarrier: boolean
  readOnly?: boolean
}

const GeneralPanel: FC<GeneralPanelProps> = ({isCarrier, readOnly}) => {
  return (
    <>
      <Tabs.Section>
        <GeneralPanelFieldsGeneral readOnly={readOnly} />
      </Tabs.Section>
      <Divider />
      <Tabs.Section>
        <Typography fontWeight="bold">Osvědčení dopravce</Typography>
        <GeneralPanelFieldsCertificate isCarrier={isCarrier} readOnly={readOnly} />
      </Tabs.Section>
      <Divider />
      <Tabs.Section>
        <Typography fontWeight="bold">Poznámka</Typography>
        <GeneralPanelFieldsNote readOnly={readOnly} />
      </Tabs.Section>
    </>
  )
}

export const GENERAL_PANEL_LABEL = 'Základní údaje'
export default GeneralPanel
