import RemoveIcon from '@mui/icons-material/Remove'
import {DatePicker} from '@mui/x-date-pickers'
import {Choose, Otherwise, When} from 'babel-plugin-jsx-control-statements'
import {getTime, isDate, format as formatFn} from 'date-fns'
import type {ComponentProps, FC, ReactNode} from 'react'
import type {UseFieldConfig} from 'react-final-form'
import {useField} from 'react-final-form'
import {StaticField} from '../visual'
import type FormHelperText from '../visual/FormHelperText/FormHelperText'
import type {ShowError} from './forms'
import {showErrorOnChange} from './forms'


type DateInputProps = ComponentProps<typeof DatePicker> & {
  name: string
  readOnly?: boolean
  disableSpacing?: boolean
  showError?: ShowError
  fieldProps?: UseFieldConfig<number | null | undefined>
  helperText?: ReactNode
  required?: boolean
  fullWidth?: boolean
  shouldDisplayStaticHelperText?: boolean
  helperTextProps?: ComponentProps<typeof FormHelperText>
}
const dateToTimestamp = (time: Date) => isDate(time) ? getTime(time) : null

const DateInput: FC<DateInputProps> = ({
  disableSpacing, readOnly, name, label, required, disabled, helperText, fullWidth = true,
  showError = showErrorOnChange, format = 'dd/MM/yyyy', shouldDisplayStaticHelperText = true, helperTextProps,
  slotProps, fieldProps, ...props
}) => {
  const allFieldProps = {
    parse: dateToTimestamp,
    format: (value: unknown) => value || null,
    ...fieldProps,
  }
  const {input: {value, onChange, ...restInput}, meta} = useField<number | null | undefined>(name, allFieldProps)
  const {isError, helperTextOrError} = showError({meta, helperText})

  return (
    <Choose>
      <When condition={!readOnly}>
        <DatePicker
            showDaysOutsideCurrentMonth
            label={label}
            value={value}
            onChange={onChange}
            disabled={disabled}
            format={format}
            slotProps={{
              ...slotProps,
              actionBar: {
                ...slotProps?.actionBar,
                actions: ['clear'],
              },
              textField: {
                ...slotProps?.textField,
                fullWidth,
                helperText: helperTextOrError,
                error: isError,
                required,
                FormHelperTextProps: helperTextProps,
              },
            }}
            {...restInput}
            {...props}
        />
      </When>
      <Otherwise>
        <StaticField
            label={label}
            disableSpacing={disableSpacing}
            helperText={shouldDisplayStaticHelperText ? helperText : undefined}
            helperTextProps={helperTextProps}
        >
          <Choose>
            <When condition={value}>
              {value ? formatFn(getTime(value), format) : null}
            </When>
            <Otherwise>
              <RemoveIcon />
            </Otherwise>
          </Choose>
        </StaticField>
      </Otherwise>
    </Choose>
  )
}


export default DateInput
