import {Stack, Tab} from '@mui/material'
import MuiTabs from '@mui/material/Tabs'
import {styled} from '@mui/material/styles'
import type {ComponentProps, FC, ReactNode} from 'react'


type TabPanelProps = {
  children?: ReactNode;
  index: number;
  value: number;
}

const StyledPanel = styled('div')(() => ({
  padding: '1.5rem 0',
}))

const TabPanel: FC<TabPanelProps> = ({value, index, children, ...props}) => {
  return (
    <div role="tabpanel" hidden={value !== index} {...props}>
      <StyledPanel>
        <Stack useFlexGap gap={2}>
          {children}
        </Stack>
      </StyledPanel>
    </div>
  )
}

type TabsProps = ComponentProps<typeof MuiTabs> & {
  labels: string[],
  disabledTabs?: Record<number, boolean>
  children: ReactNode,
}

const StyledTabs = styled(MuiTabs)(({theme}) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

type SectionProps = {
  children: ReactNode,
}

const Section: FC<SectionProps> = ({children}) => {
  return (
    <Stack useFlexGap gap={2}>{children}</Stack>
  )
}

const Tabs: FC<TabsProps> & {Panel: typeof TabPanel, Section: typeof Section} = ({
  labels, disabledTabs, onChange, children, ...props
}) => {
  return (
    <>
      <StyledTabs variant="scrollable" onChange={onChange} {...props}>
        {labels.map((label, idx) => (
          <Tab value={idx} label={label} key={label} disabled={disabledTabs?.[idx]} />
        ))}
      </StyledTabs>
      {children}
    </>
  )
}

Tabs.Panel = TabPanel
Tabs.Section = Section

export default Tabs
