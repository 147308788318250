import {styled} from '@mui/material/styles'
import type {FC, ReactNode} from 'react'


const StyledContainer = styled('div')({
  display: 'flex',
})

type ContentContainerProps = {
  children: ReactNode,
}

const ContentContainer: FC<ContentContainerProps> = ({children, ...props}) => {
  return (
    <StyledContainer {...props}>
      {children}
    </StyledContainer>
  )
}

export default ContentContainer
