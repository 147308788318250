import VisibilityIcon from '@mui/icons-material/Visibility'
import {IconButton} from '@mui/material'


const ActionCell = () => {
  return (
    <IconButton aria-label="visibility">
      <VisibilityIcon />
    </IconButton>
  )
}

export default ActionCell
