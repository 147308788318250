import AddCircleIcon from '@mui/icons-material/AddCircle'
import {useQuery} from '@tanstack/react-query'
import {If} from 'babel-plugin-jsx-control-statements'
import type {ListCompanyResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import {MANAGE_COMPANIES} from 'constants/permissions'
import * as resources from 'constants/resources'
import type {TypeOf, ZodObject} from 'zod'
import * as clientRoutes from '../../../constants/routes'
import {createListQuery} from '../../../hooks/api'
import usePermitted from '../../../hooks/usePermitted'
import useQueryParams from '../../../hooks/useQueryParams'
import {queryToSort} from '../../../utils/lists'
import Breadcrumbs from '../../containers/Breadcrumbs'
import Button from '../../containers/Button'
import ExportButton from '../../containers/ExportButton'
import Filters from '../../containers/Filters'
import Link from '../../containers/Link'
import IndeterminateCheckbox from '../../form/IndeterminateCheckbox'
import SearchBoxInput from '../../form/SearchBoxInput'
import {ScreenHeader, ScreenWrapper} from '../../visual'
import NotFound from '../NotFound'
import CompaniesTable, {sortableColumns} from './CompaniesTable'


const schema = {
  page: schemas.nonNegativeInteger().default(1),
  pageSize: schemas.positiveInteger().default(10).catch(10),
  sortBy: schemas.createEnum(sortableColumns).default('id').catch('id'),
  sortDirection: schemas.createEnum(['asc', 'desc']).default('desc').catch('desc'),
  filter: schemas.optionalObject({
    q: schemas.requiredString().optional(),
    isCarrier: schemas.nullable(schemas.boolean()),
  }).optional().catch({}),
}

const queryDef = ({query}: {query: TypeOf<ZodObject<typeof schema>>}) => createListQuery<ListCompanyResponseSchema>({
  resource: resources.COMPANIES,
  query: {
    ...queryToSort(query),
    page: query.page - 1,
    pageSize: query.pageSize,
    filter: {
      ...query?.filter,
      deleted: false,
    },
  },
})


const CompaniesList = () => {
  const canCreate = usePermitted([MANAGE_COMPANIES])
  const [query, setQuery] = useQueryParams(schema)
  const {data} = useQuery(queryDef({query}))
  const companies = data?.data

  if (!companies) return <NotFound />

  return (
    <ScreenWrapper>
      <ScreenHeader
          title="Firmy"
          breadcrumbs={
            <Breadcrumbs>
              <Breadcrumbs.Item>Adresář</Breadcrumbs.Item>
              <Breadcrumbs.Item active to={clientRoutes.COMPANIES}>Firmy</Breadcrumbs.Item>
            </Breadcrumbs>
          }
      >
        <ExportButton resource={resources.COMPANIES} query={query} />
        <If condition={canCreate}>
          <Link to={clientRoutes.COMPANIES_CREATE}>
            <Button icon={<AddCircleIcon />} variant="contained" fullWidth>
              Přidat nový subjekt
            </Button>
          </Link>
        </If>
      </ScreenHeader>

      <Filters query={query} setQuery={setQuery}>
        {({handleSubmit, handleReset}) => (
          <>
            <SearchBoxInput name="q" label="Hledat mezi firmami" placeholder="Vyhledávání potvrďte Enterem" onApply={handleSubmit} />
            <Filters.Drawer alwaysVisibleFilters={['q']} query={query} onApply={handleSubmit} onReset={handleReset}>
              <IndeterminateCheckbox name="isCarrier" label="Je osvědčení dopravce" fullWidth={false} />
            </Filters.Drawer>
          </>
        )}
      </Filters>

      <CompaniesTable
          companies={companies}
          total={data.total}
          query={query}
          setQuery={setQuery}
      />
    </ScreenWrapper>
  )
}

export default CompaniesList
