import type {Getter} from '@tanstack/react-table'
import {format as formatFn, isDate} from 'date-fns'
import type {ReactNode} from 'react'
import Fallback from './Fallback'


type DateCellArgs = {
  format?: string
  fallback?: ReactNode
}

const dateCell = ({format = 'dd/MM/yyyy', fallback = '–'}: DateCellArgs = {}) => {
  const renderDateCell = ({getValue}: {getValue: Getter<number>}) => {
    const value = getValue()
    const date = new Date(value)
    if (!value || !isDate(date)) return <Fallback>{fallback}</Fallback>
    return formatFn(date, format)
  }
  return renderDateCell
}

export default dateCell
