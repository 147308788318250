import {Checkbox} from '@mui/material'
import type {CellContext, HeaderContext} from '@tanstack/react-table'


export const RowSelectionHeader = <TData, >({table}: HeaderContext<TData, unknown>) => {
  return (
    <div>
      <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          indeterminate={table.getIsSomePageRowsSelected()}
          onClick={table.getToggleAllPageRowsSelectedHandler()}
      />
    </div>
  )
}

export const RowSelectionCell = <TData, >({row}: CellContext<TData, unknown>) => {
  return (
    <div>
      <Checkbox
          checked={row.getIsSelected()}
          onClick={(e) => {
            e.stopPropagation()
            row.getToggleSelectedHandler()(e)
          }}
      />
    </div>
  )
}
