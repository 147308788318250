import SaveIcon from '@mui/icons-material/Save'
import {useQuery} from '@tanstack/react-query'
import type {GetUserResponseSchema} from 'common/responses'
import {INVALID_CREDENTIALS_ERROR} from 'constants/errorCodes'
import * as resources from 'constants/resources'
import invariant from 'invariant'
import type {ComponentProps} from 'react'
import {createOneQuery, useUpdateResource} from '../../../hooks/api'
import {useAuth, useSession} from '../../../hooks/auth'
import {useAlert} from '../../../hooks/useAlert'
import {isFrontendError} from '../../../utils/api'
import Button from '../../containers/Button'
import FormBlocker from '../../containers/FormBlocker'
import {ScreenHeader, ScreenWrapper} from '../../visual'
import NotFound from '../NotFound'
import ProfileForm from './ProfileForm'
import ProfilePasswordChange from './ProfilePasswordChange'


export const queryDef = ({userId}: {userId: number}) => createOneQuery<GetUserResponseSchema>({
  resource: resources.USERS,
  id: userId,
})

const ProfileEdit = () => {
  const session = useSession()
  invariant(session?.userId, 'User id is required')
  const {data} = useQuery({...queryDef({userId: session.userId}), enabled: Boolean(session?.userId)})
  const updateUser = useUpdateResource({resource: resources.USERS, id: session?.userId})
  const {refreshAuth} = useAuth()
  const showAlert = useAlert()
  const user = data?.data

  if (!user || user.deletedAt) return <NotFound />

  const handleSubmit: ComponentProps<typeof ProfileForm>['onSubmit'] = async (values) => {
    try {
      const {data} = await updateUser.mutateAsync(values)
      await refreshAuth()
      showAlert(`Uživatel ${data.id} uložen, pro pokračování se opět přihlašte`, 'success')
    } catch (e: unknown) {
      if (isFrontendError(e) && e.data.errorCode === INVALID_CREDENTIALS_ERROR) return {oldPassword: 'Neplatné staré heslo'}
      return null
    }
  }

  return (
    <ProfileForm
        onSubmit={handleSubmit}
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          department: user.department,
          roleId: user.roleId || undefined,
        }}
    >
      {({submitting, form, handleSubmit}) => (
        <ScreenWrapper>
          <FormBlocker />
          <ScreenHeader title="Profil">
            <ProfilePasswordChange userId={user.id} />
            <Button
                icon={<SaveIcon />}
                variant="contained"
                disabled={submitting}
                onClick={handleSubmit}
            >
              Uložit
            </Button>
          </ScreenHeader>
          {form}
        </ScreenWrapper>
      )}
    </ProfileForm>
  )
}

export default ProfileEdit
