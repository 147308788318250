import type {TableOptions} from '@tanstack/react-table'
import {compact, map} from 'lodash'


type QueryToSortArgs<TSortBy> = {
  sortBy?: TSortBy
  sortDirection?: 'asc' | 'desc'
}

export const queryToSort = <TSortBy>(query: QueryToSortArgs<TSortBy>): {sort: [TSortBy, 'asc' | 'desc']} | Record<string, never> => {
  return query?.sortBy && query?.sortDirection ? {sort: [query.sortBy, query.sortDirection]} : {}
}

export const getSortableColumns = <TData, >(columns: TableOptions<TData>['columns']) => compact(map(columns, (column) => {
  if (!('accessorKey' in column)) return null
  return column.enableSorting !== false ? (column.id || column.accessorKey) : null
}))
