import AddCircleIcon from '@mui/icons-material/AddCircle'
import {useQuery} from '@tanstack/react-query'
import {If} from 'babel-plugin-jsx-control-statements'
import type {ListCompanyResponseSchema, ListPermitResponseSchema, ListSidingResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import {MANAGE_PERMITS} from 'constants/permissions'
import * as resources from 'constants/resources'
import type {TypeOf, ZodObject} from 'zod'
import * as clientRoutes from '../../../constants/routes'
import {createListQuery} from '../../../hooks/api'
import usePermitted from '../../../hooks/usePermitted'
import useQueryParams from '../../../hooks/useQueryParams'
import generatePath from '../../../utils/generatePath'
import {queryToSort} from '../../../utils/lists'
import Breadcrumbs from '../../containers/Breadcrumbs'
import Button from '../../containers/Button'
import ExportButton from '../../containers/ExportButton'
import Filters from '../../containers/Filters'
import Link from '../../containers/Link'
import DateInput from '../../form/DateInput'
import ReferenceInput from '../../form/ReferenceInput'
import SearchBoxInput from '../../form/SearchBoxInput'
import {InputStack, ScreenHeader, ScreenWrapper} from '../../visual'
import NotFound from '../NotFound'
import PermitsTable, {sortableColumns} from './PermitsTable'


const schema = {
  page: schemas.nonNegativeInteger().default(1),
  pageSize: schemas.positiveInteger().default(10).catch(10),
  sortBy: schemas.createEnum(sortableColumns).default('id').catch('id'),
  sortDirection: schemas.createEnum(['asc', 'desc']).default('desc').catch('desc'),
  filter: schemas.optionalObject({
    q: schemas.requiredString().optional(),
    companyId: schemas.oneOrMany(schemas.id()).optional(),
    sidingId: schemas.oneOrMany(schemas.id()).optional(),
    validFrom: schemas.timestamp().nullable().optional(),
    validTo: schemas.timestamp().nullable().optional(),
  }).optional().catch({}),
}

const queryDef = ({query}: {query: TypeOf<ZodObject<typeof schema>>}) => createListQuery<ListPermitResponseSchema>({
  resource: resources.PERMITS,
  query: {
    ...queryToSort(query),
    page: query.page - 1,
    pageSize: query.pageSize,
    filter: {
      ...query?.filter,
      valid: query?.filter?.validFrom && query?.filter?.validTo
        ? [query?.filter?.validFrom, query?.filter?.validTo]
        : undefined,
    },
  },
})

const PermitsList = () => {
  const canCreate = usePermitted([MANAGE_PERMITS])
  const [query, setQuery] = useQueryParams(schema)
  const {data} = useQuery(queryDef({query}))
  const permits = data?.data

  if (!permits) return <NotFound />

  return (
    <ScreenWrapper>
      <ScreenHeader
          title="Úřední povolení"
          breadcrumbs={
            <Breadcrumbs>
              <Breadcrumbs.Item>Evidence vleček</Breadcrumbs.Item>
              <Breadcrumbs.Item active to={clientRoutes.PERMITS}>Úřední povolení</Breadcrumbs.Item>
            </Breadcrumbs>
          }
      >
        <ExportButton resource={resources.PERMITS} query={query} />
        <If condition={canCreate}>
          <Link to={clientRoutes.PERMITS_CREATE}>
            <Button icon={<AddCircleIcon />} variant="contained" fullWidth>
              Přidat nové povolení
            </Button>
          </Link>
        </If>
      </ScreenHeader>

      <Filters query={query} setQuery={setQuery}>
        {({handleSubmit, handleReset}) => (
          <>
            <SearchBoxInput name="q" label="Hledat mezi povoleními" placeholder="Vyhledávání potvrďte Enterem" onApply={handleSubmit} />
            <Filters.Drawer alwaysVisibleFilters={['q']} query={query} onApply={handleSubmit} onReset={handleReset}>
              <ReferenceInput<ListCompanyResponseSchema, true>
                  name="companyId"
                  label="Provozovatel"
                  optionText="name"
                  resource={resources.COMPANIES}
                  tagRoute={({value}) => generatePath(clientRoutes.COMPANIES_EDIT, {companyId: value})}
                  size="small"
                  fullWidth={false}
                  multiple
              />
              <ReferenceInput<ListSidingResponseSchema, true>
                  name="sidingId"
                  label="Vlečka"
                  optionText="name"
                  resource={resources.SIDINGS}
                  tagRoute={({value}) => generatePath(clientRoutes.SIDINGS_EDIT, {sidingId: value})}
                  size="small"
                  fullWidth={false}
                  multiple
              />
              <InputStack>
                <DateInput name="validFrom" label="Platnost od" slotProps={{textField: {size: 'small'}}} fullWidth={false} />
                <DateInput name="validTo" label="Platnost do" slotProps={{textField: {size: 'small'}}} fullWidth={false} />
              </InputStack>
            </Filters.Drawer>
          </>
        )}
      </Filters>

      <PermitsTable
          permits={permits}
          total={data.total}
          query={query}
          setQuery={setQuery}
      />
    </ScreenWrapper>
  )
}

export default PermitsList
