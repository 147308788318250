import SaveIcon from '@mui/icons-material/Save'
import {MANAGE_USERS} from 'constants/permissions'
import * as resources from 'constants/resources'
import type {ComponentProps} from 'react'
import {useNavigate} from 'react-router-dom'
import * as clientRoutes from '../../../constants/routes'
import {useCreateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import usePermitted from '../../../hooks/usePermitted'
import generatePath from '../../../utils/generatePath'
import Breadcrumbs from '../../containers/Breadcrumbs'
import Button from '../../containers/Button'
import FormBlocker from '../../containers/FormBlocker'
import {ScreenHeader, ScreenWrapper} from '../../visual'
import UsersForm from './UsersForm'


const UsersCreate = () => {
  usePermitted([MANAGE_USERS], {renderNotFound: true})
  const createUser = useCreateResource({resource: resources.USERS})
  const showAlert = useAlert()
  const navigate = useNavigate()

  const handleCreateUser: ComponentProps<typeof UsersForm>['onSubmit'] = async (values) => {
    const {data} = await createUser.mutateAsync(values)

    navigate(generatePath(clientRoutes.USERS_EDIT, {userId: data.id}))
    showAlert(`Uživatel ${data.id} vytvořen`, 'success')
  }

  return (
    <UsersForm onSubmit={handleCreateUser}>
      {({form, handleSubmit}) => (
        <ScreenWrapper>
          <FormBlocker />
          <ScreenHeader
              title="Vytvořit uživatele"
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item>Administrace</Breadcrumbs.Item>
                  <Breadcrumbs.Item to={clientRoutes.USERS}>Uživatelé</Breadcrumbs.Item>
                  <Breadcrumbs.Item active>Vytvořit uživatele</Breadcrumbs.Item>
                </Breadcrumbs>
              }
          >
            <Button
                type="submit"
                variant="contained"
                icon={<SaveIcon />}
                onClick={handleSubmit}
            >
              Uložit změny
            </Button>
          </ScreenHeader>
          {form}
        </ScreenWrapper>
      )}
    </UsersForm>
  )
}

export default UsersCreate
