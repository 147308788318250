import RemoveIcon from '@mui/icons-material/Remove'
import {Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import type {Enum, Enums} from 'common/labels/enums'
import allEnums from 'common/labels/enums'
import {map, mapValues, isString, castArray} from 'lodash-es'
import type {ComponentProps, ReactNode} from 'react'
import type {UseFieldConfig} from 'react-final-form'
import {useField} from 'react-final-form'
import {Select, StaticField} from '../visual'
import type {ShowError} from './forms'
import {showErrorOnChange} from './forms'


type Value<TEnum extends Enum> = keyof (Enums[TEnum]) & string

type EnumOptions = {
  [TEnum in Enum]: {value: Value<TEnum>, label: Enums[TEnum][Value<TEnum>]}[]
}
const enumsOptions = mapValues(allEnums, (enums) => map(enums, (label, value) => ({label, value}))) as EnumOptions

type EnumInputProps<TEnum extends Enum> = Omit<ComponentProps<typeof Select>, 'options'> & {
  name: string
  readOnly?: boolean
  disableSpacing?: boolean,
  showError?: ShowError
  enumName: Enum
  fieldProps?: UseFieldConfig<Value<TEnum>>
  helperText?: ReactNode
  multiple?: boolean
}

const EnumInput = <TEnum extends Enum>({
  disableSpacing, readOnly, name, label, required, disabled, helperText, fullWidth = true,
  showError = showErrorOnChange, fieldProps = {}, multiple = false, enumName, ...props
}: EnumInputProps<TEnum>) => {
  const {input: {value, onChange, ...restInput}, meta} = useField<Value<TEnum> | Value<TEnum>[]>(name, fieldProps)
  const {isError, helperTextOrError} = showError({meta, helperText})

  const options = enumsOptions[enumName]
  const selectedOptions = map(castArray(value), (value: Value<typeof enumName>) => allEnums[enumName][value])

  const handleChange: ComponentProps<typeof Select>['onChange'] = (e) => {
    const {value} = e.target
    onChange(isString(value) && multiple ? value.split(',') : value)
  }

  return (
    <Choose>
      <When condition={!readOnly}>
        <Select
            fullWidth={fullWidth}
            options={options}
            multiple={multiple}
            value={value}
            onChange={handleChange}
            required={required}
            name={name}
            label={label}
            helperText={helperTextOrError}
            error={isError}
            inputProps={{required, ...restInput}}
            disabled={disabled}
            {...props}
        />
      </When>
      <Otherwise>
        <StaticField label={label} disableSpacing={disableSpacing}>
          {selectedOptions.join('; ') || <RemoveIcon />}
        </StaticField>
      </Otherwise>
    </Choose>
  )
}
export default EnumInput
