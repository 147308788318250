import {Paper} from '@mui/material'
import {styled} from '@mui/material/styles'
import {If} from 'babel-plugin-jsx-control-statements'
import type {FC, ReactNode} from 'react'


const StyledPaper = styled(Paper)(({theme}) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1.5),
  },
}))

const StyledFilters = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    'width': '100%',
    '& > :first-child': {
      flexShrink: 0,
      flexGrow: 1,
    },
  },
}))

const StyledLoading = styled('div')({
  flexShrink: 0,
})

const StyledWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'stretch',
    gap: theme.spacing(1.5),
  },
}))

const StyledActions = styled('div')(({theme}) => ({
  [theme.breakpoints.down('md')]: {
    'display': 'flex',
    'justifyContent': 'center',
    'width': '100%',
    'position': 'relative',
    'padding-top': theme.spacing(1),
    '&::before': {
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      width: `calc(100% + ${theme.spacing(3)})`,
      height: '1px',
      background: theme.palette.divider,
      content: '""',
    },
  },
}))

type FiltersContainerProps = {
  loading?: ReactNode
  children: ReactNode
  actions?: ReactNode
}

const FiltersContainer: FC<FiltersContainerProps> = ({loading, actions, children, ...props}) => {
  return (
    <StyledPaper {...props}>
      <StyledWrapper>
        <StyledFilters>
          {children}
        </StyledFilters>
        <If condition={actions}>
          <StyledActions>
            {actions}
          </StyledActions>
        </If>
      </StyledWrapper>
      <If condition={loading}>
        <StyledLoading>
          {loading}
        </StyledLoading>
      </If>
    </StyledPaper>
  )
}

export default FiltersContainer
