import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import {If} from 'babel-plugin-jsx-control-statements'
import {formatCoordinates} from 'common/coordinates'
import type {GetSidingRevisionResponseSchema} from 'common/responses'
import * as resources from 'constants/resources'
import {isEmpty, map} from 'lodash'
import type {FC, SyntheticEvent} from 'react'
import {useState} from 'react'
import {createOneQuery} from '../../../hooks/api'
import Button from '../../containers/Button'
import CheckboxInput from '../../form/CheckboxInput'
import FormView from '../../form/FormView'
import RevisionMeta from '../../form/RevisionMeta'
import {Modal} from '../../visual'
import SidingCancellationRevisionDetail from './components/sidingCancellation/SidingCancellationRevisionDetail'
import {GeneralPanelFieldsGeneral, GeneralPanelFieldsLicense, GeneralPanelFieldsNotes, GeneralPanelFieldsOwnership, GeneralPanelFieldsSidingSiding} from './panels/GeneralPanel'
import {InfrastructurePanelFieldsCoordinates, InfrastructurePanelFieldsDocuments, InfrastructurePanelFieldsGeneral, InfrastructurePanelFieldsNotes} from './panels/InfrastructurePanel'


export const queryDef = ({revisionId}: {revisionId: number}) => createOneQuery<GetSidingRevisionResponseSchema>({
  resource: resources.SIDING_REVISIONS,
  id: revisionId,
})

type SidingsRevisionDetailProps = {
  revisionId: GetSidingRevisionResponseSchema['revisionId'] | null
  onClose: () => void
}

const SidingRevisionDetail: FC<SidingsRevisionDetailProps> = ({revisionId, onClose}) => {
  const [expanded, setExpanded] = useState<string | boolean>('general')
  const {data} = useQuery({
    ...queryDef({revisionId: Number(revisionId)}),
    enabled: Boolean(revisionId),
    suspense: false,
  })
  const siding = data?.data

  if (!siding) return null

  const handleChange = (panel: string) => (_event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Modal open={Boolean(revisionId)} onClose={onClose}>
      <Modal.Title>Detail záznamu změny vlečky</Modal.Title>
      <Modal.Content>
        <FormView
            values={{
              ...siding,
              ...formatCoordinates(siding.coordinates),
              ownerIds: map(siding.owners, 'id'),
              renterIds: map(siding.renters, 'id'),
              licenseIds: map(siding.licenses, 'id'),
              sidingSidingIds: map(siding.sidings, 'id'),
            }}
        >
          <RevisionMeta />
          <div>
            <Accordion expanded={expanded === 'general'} onChange={handleChange('general')}>
              <AccordionSummary>Základní údaje</AccordionSummary>
              <AccordionDetails>
                <CheckboxInput
                    name="requiresVerification"
                    label="Potřebné potvrzení změny údajů"
                    formControlLabelProps={{labelPlacement: 'start'}}
                    readOnly
                />
                <GeneralPanelFieldsGeneral readOnly />
                <GeneralPanelFieldsOwnership readOnly />
                <GeneralPanelFieldsLicense readOnly />
                <GeneralPanelFieldsSidingSiding sidingId={siding.id} readOnly />
                <GeneralPanelFieldsNotes readOnly />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'infrastrucutre'} onChange={handleChange('infrastrucutre')}>
              <AccordionSummary>Infrastruktura</AccordionSummary>
              <AccordionDetails>
                <InfrastructurePanelFieldsGeneral readOnly />
                <InfrastructurePanelFieldsCoordinates readOnly />
                <InfrastructurePanelFieldsDocuments isNewRecord readOnly />
                <InfrastructurePanelFieldsNotes readOnly />
              </AccordionDetails>
            </Accordion>
            <If condition={!isEmpty(siding.sidingCancellations)}>
              <Accordion expanded={expanded === 'cancellationRevisions'} onChange={handleChange('cancellationRevisions')}>
                <AccordionSummary>Revize rušení</AccordionSummary>
                <AccordionDetails>
                  <SidingCancellationRevisionDetail sidingCancellations={siding.sidingCancellations} />
                </AccordionDetails>
              </Accordion>
            </If>
          </div>
        </FormView>
      </Modal.Content>
      <Modal.Actions>
        <Button color="neutral" onClick={onClose}>
          Zavřít
        </Button>
      </Modal.Actions>
    </Modal>
  )
}


export default SidingRevisionDetail
