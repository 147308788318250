import RemoveIcon from '@mui/icons-material/Remove'
import {Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import type {ComponentProps, FC} from 'react'
import type {UseFieldConfig} from 'react-final-form'
import {useField} from 'react-final-form'
import {StaticField, TextInput as VisualTextInput} from '../visual'
import type {ShowError} from './forms'
import {showErrorOnChange} from './forms'


type TextInputProps = ComponentProps<typeof VisualTextInput> & {
  name: string,
  readOnly?: boolean,
  disableSpacing?: boolean,
  showError?: ShowError,
  fieldProps?: UseFieldConfig<string, string>,
}

const TextInput: FC<TextInputProps> = ({
  disableSpacing, readOnly, name, label, wide, disabled, required, helperText, fullWidth = true,
  showError = showErrorOnChange, fieldProps, ...props
}) => {
  const {input: {value, type, onChange, ...restInput}, meta} = useField<string>(name, fieldProps)
  const {isError, helperTextOrError} = showError({meta, helperText})

  return (
    <Choose>
      <When condition={!readOnly}>
        <VisualTextInput
            fullWidth={fullWidth}
            label={label}
            helperText={helperTextOrError}
            error={isError}
            disabled={disabled}
            onChange={onChange}
            name={name}
            value={value}
            type={type}
            required={required}
            wide={wide}
            inputProps={{required, ...restInput}}
            {...props}
        />
      </When>
      <Otherwise>
        <StaticField label={label} disableSpacing={disableSpacing}>
          <Choose>
            <When condition={value}>
              {value}
            </When>
            <Otherwise>
              <RemoveIcon />
            </Otherwise>
          </Choose>
        </StaticField>
      </Otherwise>
    </Choose>
  )
}

export default TextInput
