import {Stack} from '@mui/material'
import * as schemas from 'common/schemas'
import PasswordInput from '../../form/PasswordInput'
import {showErrorOnChange} from '../../form/forms'
import withForm from '../../form/withForm'


const schema = schemas.object({
  password: schemas.requiredString(),
  confirmPassword: schemas.requiredString(),
})

const UsersPasswordChangeForm = withForm({schema})(() => (
  <Stack gap={2}>
    <PasswordInput
        name="password"
        label="Nové heslo"
        autoComplete="new-password"
        showError={showErrorOnChange}
        required
    />
    <PasswordInput
        name="confirmPassword"
        label="Nové heslo znovu"
        autoComplete="new-password"
        showError={showErrorOnChange}
        required
        fieldProps={{
          validate: (confirmPassword, {password}: {password: string}) => {
            if (password !== confirmPassword) return 'Hesla se musí zhodovat'
          },
        }}
    />
  </Stack>
))

export default UsersPasswordChangeForm
