import {Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Stack, Typography} from '@mui/material'
import {If} from 'babel-plugin-jsx-control-statements'
import type {GetSidingResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import * as resources from 'constants/resources'
import {every, find, isEmpty, map, omit} from 'lodash'
import {useState} from 'react'
import type {FC, SyntheticEvent} from 'react'
import {useDeleteResource} from '../../../../hooks/api'
import {useAlert} from '../../../../hooks/useAlert'
import useQueryParams from '../../../../hooks/useQueryParams'
import Button from '../../../containers/Button'
import ConfirmModal from '../../../containers/ConfirmModal'
import FormView from '../../../form/FormView'
import SidingCancellationEditModal from '../components/sidingCancellation/SidingCancellationEditModal'
import SidingCancellationModal from '../components/sidingCancellation/SidingCancellationModal'
import {SidingCancellationModalFieldsCancellation, SidingCancellationModalFieldsRemoval} from '../components/sidingCancellation/SidingCancellationModalForm'
import SidingCancellationTitle from '../components/sidingCancellation/SidingCancellationTitle'


const schema = {
  cancellationId: schemas.nonNegativeInteger().optional(),
}

type SidingCancellations = GetSidingResponseSchema['sidingCancellations']

// If a whole siding is cancelled, there is nothing else to cancel
const shouldShowCancellationButton = (sidingCancellations: SidingCancellations) => {
  if (isEmpty(sidingCancellations)) return true
  return every(sidingCancellations, (sidingCancellation) => Boolean(sidingCancellation?.cancelledPart))
}

// If a siding (or its part) is removed, then it is not possible to recover it
const shouldShowRestoreButton = (sidingCancellation: SidingCancellations[number]) => {
  return !sidingCancellation?.removedAt
}

type SidingCancellationProps = {
  sidingId: GetSidingResponseSchema['id']
  sidingCancellations: SidingCancellations
}

const SidingCancellationPanel: FC<SidingCancellationProps> = ({sidingCancellations, sidingId}) => {
  const restoreSiding = useDeleteResource({resource: resources.SIDING_CANCELLATIONS})
  const [query, setQuery] = useQueryParams(schema)
  const showAlert = useAlert()
  const [expanded, setExpanded] = useState<number | false>(0)

  const handleChange = (panel: number) => (_event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleRestoreSiding = (id: number) => async () => {
    await restoreSiding.mutateAsync(id)
    showAlert(`Vlečka (nebo část vlečky) ${sidingId} obnovena`, 'success')
  }

  return (
    <>
      <Stack gap={1} direction={{md: 'row', sm: 'column'}} justifyContent="space-between" alignItems="center">
        <Typography fontWeight="bold">Záznamy rušení</Typography>
        <If condition={shouldShowCancellationButton(sidingCancellations)}>
          <SidingCancellationModal sidingId={sidingId} />
        </If>
      </Stack>
      <If condition={isEmpty(sidingCancellations)}>
        <Alert severity="info">
          Vlečka není zrušena
        </Alert>
      </If>
      {sidingCancellations ? (
        <div>
          {map(sidingCancellations, (sidingCancellation, index) => sidingCancellation ? (
            <Accordion key={sidingCancellation.id} expanded={expanded === index} onChange={handleChange(index)}>
              <AccordionSummary>
                <SidingCancellationTitle
                    cancelledPart={sidingCancellation.cancelledPart}
                    removedAt={sidingCancellation.removedAt}
                />
              </AccordionSummary>
              <AccordionDetails>
                <FormView
                    values={{
                      ...sidingCancellation,
                      isPart: Boolean(sidingCancellation.cancelledPart),
                      isRemoved: Boolean(sidingCancellation.removedAt),
                    }}
                >
                  <SidingCancellationModalFieldsCancellation
                      isPart={Boolean(sidingCancellation.cancelledPart)}
                      readOnly
                  />
                  <SidingCancellationModalFieldsRemoval isRemoved={Boolean(sidingCancellation.removedAt)} readOnly />
                </FormView>
              </AccordionDetails>
              <AccordionActions>
                <Button
                    color="neutral"
                    variant="text"
                    onClick={() => setQuery({cancellationId: sidingCancellation.id})}
                >
                  Upravit
                </Button>
                <If condition={shouldShowRestoreButton(sidingCancellation)}>
                  <ConfirmModal
                      title="Přejete si tuto vlečku obnovit?"
                      buttonText="Obnovit"
                      saveText="Potvrdit obnovení"
                      closeText="Zavřít"
                      buttonIcon={null}
                      onConfirm={handleRestoreSiding(sidingCancellation.id)}
                  >
                    <Typography>
                      Právě se chystáte provést obnovení zrušené vlečky. Záznam o tomto zrušení bude následně smazán
                      z karty a jeho detail budete moci dohledat pouze v historii vlečky.
                    </Typography>
                    <br />
                    <Typography fontWeight="bold">
                      Tento krok je možné provést pouze do té doby, dokud nedojde k odstranění příslušného kolejiště.
                    </Typography>
                  </ConfirmModal>
                </If>
              </AccordionActions>
            </Accordion>
          ) : null)}
        </div>
      ) : null}
      {query.cancellationId ? (
        <SidingCancellationEditModal
            sidingCancellation={find(sidingCancellations, {id: query.cancellationId})}
            onClose={() => setQuery(() => omit(query, 'cancellationId'))}
        />
      ) : null}
    </>
  )
}

export const SIDING_CANCELLATION_PANEL_LABEL = 'Zrušení vlečky'

export default SidingCancellationPanel
