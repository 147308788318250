import AddCircleIcon from '@mui/icons-material/AddCircle'
import {useQuery} from '@tanstack/react-query'
import {If} from 'babel-plugin-jsx-control-statements'
import * as enums from 'common/labels/enums'
import type {ListCompanyResponseSchema, ListLicenseResponseSchema, ListSidingResponseSchema, ListUserResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import {MANAGE_SIDINGS} from 'constants/permissions'
import * as resources from 'constants/resources'
import {ALL_TRACK_CATEGORIES} from 'constants/trackCategories'
import type {TypeOf, ZodObject} from 'zod'
import * as clientRoutes from '../../../constants/routes'
import {createListQuery} from '../../../hooks/api'
import usePermitted from '../../../hooks/usePermitted'
import useQueryParams from '../../../hooks/useQueryParams'
import generatePath from '../../../utils/generatePath'
import {queryToSort} from '../../../utils/lists'
import Breadcrumbs from '../../containers/Breadcrumbs'
import Button from '../../containers/Button'
import ExportButton from '../../containers/ExportButton'
import Filters from '../../containers/Filters'
import Link from '../../containers/Link'
import DateInput from '../../form/DateInput'
import EnumInput from '../../form/EnumInput'
import IndeterminateCheckbox from '../../form/IndeterminateCheckbox'
import ReferenceInput from '../../form/ReferenceInput'
import SearchBoxInput from '../../form/SearchBoxInput'
import TextInput from '../../form/TextInput'
import {InputStack, ScreenHeader, ScreenWrapper} from '../../visual'
import NotFound from '../NotFound'
import SidingsTable, {sortableColumns} from './SidingsTable'


const schema = {
  page: schemas.nonNegativeInteger().default(1),
  pageSize: schemas.positiveInteger().default(10).catch(10),
  sortBy: schemas.createEnum(sortableColumns).default('id').catch('id'),
  sortDirection: schemas.createEnum(['asc', 'desc']).default('desc').catch('desc'),
  filter: schemas.optionalObject({
    q: schemas.requiredString().optional(),
    mouthing: schemas.requiredString().optional(),
    trackExternalKJR: schemas.requiredString().optional(),
    companyId: schemas.oneOrMany(schemas.id()).optional(),
    supervisedAtFrom: schemas.timestamp().nullable().optional(),
    supervisedAtTo: schemas.timestamp().nullable().optional(),
    userId: schemas.oneOrMany(schemas.id()).optional(),
    hasPermit: schemas.boolean().optional(),
    ownerId: schemas.oneOrMany(schemas.id()).optional(),
    renterId: schemas.oneOrMany(schemas.id()).optional(),
    licenseId: schemas.oneOrMany(schemas.id()).optional(),
    validFrom: schemas.timestamp().nullable().optional(),
    validTo: schemas.timestamp().nullable().optional(),
    permitCancelledAtFrom: schemas.timestamp().nullable().optional(),
    permitCancelledAtTo: schemas.timestamp().nullable().optional(),
    cancelledAtFrom: schemas.timestamp().nullable().optional(),
    cancelledAtTo: schemas.timestamp().nullable().optional(),
    isCancelled: schemas.boolean().optional(),
    trackCategory: schemas.oneOrMany(schemas.createEnum(ALL_TRACK_CATEGORIES)).optional(),
  }).optional().catch({}),
}

const queryDef = ({query}: {query: TypeOf<ZodObject<typeof schema>>}) => createListQuery<ListSidingResponseSchema>({
  resource: resources.SIDINGS,
  query: {
    ...queryToSort(query),
    page: query.page - 1,
    pageSize: query.pageSize,
    filter: {
      ...query?.filter,
      supervisedAt: query?.filter?.supervisedAtFrom && query?.filter?.supervisedAtTo
        ? [query?.filter?.supervisedAtFrom, query?.filter?.supervisedAtTo]
        : undefined,
      valid: query?.filter?.validFrom && query?.filter?.validTo
        ? [query?.filter?.validFrom, query?.filter?.validTo]
        : undefined,
      permitCancelledAt: query?.filter?.permitCancelledAtFrom && query?.filter?.permitCancelledAtTo
        ? [query?.filter?.permitCancelledAtFrom, query?.filter?.permitCancelledAtTo]
        : undefined,
      cancelledAt: query?.filter?.cancelledAtFrom && query?.filter?.cancelledAtTo
        ? [query?.filter?.cancelledAtFrom, query?.filter?.cancelledAtTo]
        : undefined,
    },
  },
})


const SidingsList = () => {
  const canCreate = usePermitted([MANAGE_SIDINGS])
  const [query, setQuery] = useQueryParams(schema)
  const {data} = useQuery(queryDef({query}))
  const sidings = data?.data

  if (!sidings) return <NotFound />
  return (
    <ScreenWrapper>
      <ScreenHeader
          title="Vlečky"
          breadcrumbs={
            <Breadcrumbs>
              <Breadcrumbs.Item>Evidence vleček</Breadcrumbs.Item>
              <Breadcrumbs.Item active to={clientRoutes.SIDINGS}>Vlečky</Breadcrumbs.Item>
            </Breadcrumbs>
          }
      >
        <ExportButton resource={resources.SIDINGS} query={query} />
        <If condition={canCreate}>
          <Link to={clientRoutes.SIDINGS_CREATE}>
            <Button icon={<AddCircleIcon />} variant="contained" fullWidth>
              Přidat novou vlečku
            </Button>
          </Link>
        </If>
      </ScreenHeader>
      <Filters query={query} setQuery={setQuery}>
        {({handleSubmit, handleReset}) => (
          <>
            <SearchBoxInput name="q" label="Hledat mezi vlečkami" placeholder="Vyhledávání potvrďte Enterem" onApply={handleSubmit} />
            <Filters.Drawer alwaysVisibleFilters={['q']} query={query} onApply={handleSubmit} onReset={handleReset}>
              <ReferenceInput<ListCompanyResponseSchema, true>
                  name="companyId"
                  label="Provozovatel"
                  optionText="name"
                  resource={resources.COMPANIES}
                  size="small"
                  fullWidth={false}
                  multiple
              />
              <ReferenceInput<ListCompanyResponseSchema, true>
                  name="ownerId"
                  label="Vlastník"
                  optionText="name"
                  resource={resources.COMPANIES}
                  tagRoute={({value}) => generatePath(clientRoutes.COMPANIES_EDIT, {companyId: value})}
                  fullWidth={false}
                  size="small"
                  multiple
              />
              <ReferenceInput<ListCompanyResponseSchema, true>
                  name="renterId"
                  label="Nájemce"
                  optionText="name"
                  resource={resources.COMPANIES}
                  tagRoute={({value}) => generatePath(clientRoutes.COMPANIES_EDIT, {companyId: value})}
                  size="small"
                  fullWidth={false}
                  multiple
              />
              <ReferenceInput<ListLicenseResponseSchema, true>
                  name="licenseId"
                  label="Licence"
                  optionText={({id, company}) => `${id} (${company.name})`}
                  tagRoute={({value}) => generatePath(clientRoutes.LICENSES_EDIT, {licenseId: value})}
                  resource={resources.LICENSES}
                  size="small"
                  fullWidth={false}
                  multiple
              />
              <ReferenceInput<ListUserResponseSchema, true>
                  name="userId"
                  label="Obvod"
                  optionText={({firstName, lastName}) => `${firstName} ${lastName}`}
                  resource={resources.USERS}
                  tagRoute={({value}) => generatePath(clientRoutes.USERS_EDIT, {userId: value})}
                  size="small"
                  fullWidth={false}
                  multiple
              />
              <EnumInput name="trackCategory" label="Kategorie dráhy" enumName={enums.TRACK_CATEGORIES} size="small" fullWidth={false} multiple />
              <TextInput name="mouthing" label="Zaústění" size="small" fullWidth={false} />
              <TextInput name="trackExternalKJR" label="Číslo tratě dle KJŘ" size="small" fullWidth={false} />
              <InputStack>
                <DateInput name="supervisedAtFrom" label="Výkon SD od" slotProps={{textField: {size: 'small'}}} fullWidth={false} />
                <DateInput name="supervisedAtTo" label="Výkon SD do" slotProps={{textField: {size: 'small'}}} fullWidth={false} />
              </InputStack>
              <InputStack>
                <DateInput name="validFrom" label="ÚP platné od" slotProps={{textField: {size: 'small'}}} fullWidth={false} />
                <DateInput name="validTo" label="ÚP platné do" slotProps={{textField: {size: 'small'}}} fullWidth={false} />
              </InputStack>
              <InputStack>
                <DateInput name="permitCancelledAtFrom" label="ÚP ukončeno od" slotProps={{textField: {size: 'small'}}} fullWidth={false} />
                <DateInput name="permitCancelledAtTo" label="ÚP ukončeno do" slotProps={{textField: {size: 'small'}}} fullWidth={false} />
              </InputStack>
              <InputStack>
                <DateInput name="cancelledAtFrom" label="Zrušená od" slotProps={{textField: {size: 'small'}}} fullWidth={false} />
                <DateInput name="cancelledAtTo" label="Zrušená do" slotProps={{textField: {size: 'small'}}} fullWidth={false} />
              </InputStack>
              <IndeterminateCheckbox name="hasPermit" label="Má povolení" fullWidth={false} />
              <IndeterminateCheckbox name="isCancelled" label="Je zrušená" fullWidth={false} />
            </Filters.Drawer>
          </>
        )}
      </Filters>

      <SidingsTable
          sidings={sidings}
          total={data.total}
          query={query}
          setQuery={setQuery}
      />
    </ScreenWrapper>
  )
}

export default SidingsList
