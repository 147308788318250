import activityTypes from './activityTypes'
import carrierNSATypes from './carrierNSATypes'
import closedArealTypes from './closedArealTypes'
import departments from './departments'
import infrastructureDocumentTypes from './infrastructureDocumentTypes'
import licenseCancellationReasons from './licenseCancellationReasons'
import licenseNSATypes from './licenseNSATypes'
import permissions from './permissions'
import permitCancellationReasons from './permitCancellationReasons'
import permitTypes from './permitTypes'
import trackCategories from './trackCategories'
import trackTypes from './trackTypes'
import transportCategories from './transportCategories'
import transportTypes from './transportTypes'


export const ACTIVITY_TYPES = 'activityTypes'
export const CARRIER_NSA_TYPES = 'carrierNSATypes'
export const CLOSED_AREAL_TYPES = 'closedArealTypes'
export const DEPARTMENTS = 'departments'
export const INFRASTRUCTURE_DOCUMENT_TYPES = 'infrastructureDocumentTypes'
export const LICENSE_CANCELLATION_REASONS = 'licenseCancellationReasons'
export const LICENSE_NSA_TYPES = 'licenseNSATypes'
export const PERMISSIONS = 'permissions'
export const PERMIT_CANCELLATION_REASONS = 'permitCancellationReasons'
export const PERMIT_TYPES = 'permitTypes'
export const TRACK_CATEGORIES = 'trackCategories'
export const TRACK_TYPES = 'trackTypes'
export const TRANSPORT_TYPES = 'transportTypes'
export const TRANSPORT_CATEGORIES = 'transportCategories'

const enums = {
  [ACTIVITY_TYPES]: activityTypes,
  [CARRIER_NSA_TYPES]: carrierNSATypes,
  [CLOSED_AREAL_TYPES]: closedArealTypes,
  [DEPARTMENTS]: departments,
  [INFRASTRUCTURE_DOCUMENT_TYPES]: infrastructureDocumentTypes,
  [LICENSE_CANCELLATION_REASONS]: licenseCancellationReasons,
  [LICENSE_NSA_TYPES]: licenseNSATypes,
  [PERMISSIONS]: permissions,
  [PERMIT_CANCELLATION_REASONS]: permitCancellationReasons,
  [PERMIT_TYPES]: permitTypes,
  [TRACK_CATEGORIES]: trackCategories,
  [TRACK_TYPES]: trackTypes,
  [TRANSPORT_TYPES]: transportTypes,
  [TRANSPORT_CATEGORIES]: transportCategories,
} as const

export type Enums = typeof enums
export type Enum = keyof Enums

export default enums
