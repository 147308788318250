import * as enums from 'common/labels/enums'
import type {FC} from 'react'
import DateInput from '../../../form/DateInput'
import EnumInput from '../../../form/EnumInput'
import GinisPidInput from '../../../form/GinisPidInput'
import TextInput from '../../../form/TextInput'
import {InputStack, Tabs} from '../../../visual'


export const CancellationPanelFieldsGeneral = ({isNewRecord, readOnly}: {
  isNewRecord: boolean
  readOnly?: boolean
}) => {
  return (
    <>
      <InputStack>
        <DateInput name="cancelledAt" label="Datum odejmutí licence" readOnly={readOnly} />
        <EnumInput name="cancelledReason" label="Odůvodnění" enumName={enums.LICENSE_CANCELLATION_REASONS} readOnly={readOnly} />
      </InputStack>
      <InputStack>
        <TextInput name="cancelledRef" label="Č.j. rozhodnutí" readOnly={readOnly} />
        <GinisPidInput name="cancelledGinisPid" label="PID GINIS" isNewRecord={isNewRecord} readOnly={readOnly} />
      </InputStack>
    </>
  )
}

type CancellationPanelProps = {
  isNewRecord: boolean
  readOnly?: boolean
}

const CancellationPanel: FC<CancellationPanelProps> = ({isNewRecord, readOnly}) => {
  return (
    <Tabs.Section>
      <CancellationPanelFieldsGeneral isNewRecord={isNewRecord} readOnly={readOnly} />
    </Tabs.Section>
  )
}

export const CANCELLATION_PANEL_LABEL = 'Odejmutí licence'
export default CancellationPanel
