import AddCircleIcon from '@mui/icons-material/AddCircle'
import {useQuery} from '@tanstack/react-query'
import {If} from 'babel-plugin-jsx-control-statements'
import * as enums from 'common/labels/enums'
import type {ListForeignCarrierResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import {ALL_ACTIVITY_TYPES} from 'constants/activityTypes'
import {ALL_LICENSE_NSA_TYPES} from 'constants/licenseNSATypes'
import {MANAGE_FOREIGN_CARRIERS} from 'constants/permissions'
import * as resources from 'constants/resources'
import {ALL_TRANSPORT_TYPES} from 'constants/transportTypes'
import {Link} from 'react-router-dom'
import type {TypeOf, ZodObject} from 'zod'
import * as clientRoutes from '../../../constants/routes'
import {createListQuery} from '../../../hooks/api'
import usePermitted from '../../../hooks/usePermitted'
import useQueryParams from '../../../hooks/useQueryParams'
import {queryToSort} from '../../../utils/lists'
import Breadcrumbs from '../../containers/Breadcrumbs'
import Button from '../../containers/Button'
import ExportButton from '../../containers/ExportButton'
import Filters from '../../containers/Filters'
import EnumInput from '../../form/EnumInput'
import SearchBoxInput from '../../form/SearchBoxInput'
import {ScreenHeader, ScreenWrapper} from '../../visual'
import NotFound from '../NotFound'
import ForeignCarriersTable, {sortableColumns} from './ForeignCarriersTable'


const schema = {
  page: schemas.nonNegativeInteger().default(1),
  pageSize: schemas.positiveInteger().default(10).catch(10),
  sortBy: schemas.createEnum(sortableColumns).default('id').catch('id'),
  sortDirection: schemas.createEnum(['asc', 'desc']).default('desc').catch('desc'),
  filter: schemas.optionalObject({
    q: schemas.requiredString().optional(),
    licenseNSA: schemas.oneOrMany(schemas.createEnum(ALL_LICENSE_NSA_TYPES)).optional(),
    transportType: schemas.oneOrMany(schemas.createEnum(ALL_TRANSPORT_TYPES)).optional(),
    activityType: schemas.oneOrMany(schemas.createEnum(ALL_ACTIVITY_TYPES)).optional(),
  }).optional().catch({}),
}

const queryDef = (
  {query}: {query: TypeOf<ZodObject<typeof schema>>},
) => createListQuery<ListForeignCarrierResponseSchema>({
  resource: resources.FOREIGN_CARRIERS,
  query: {
    ...queryToSort(query),
    page: query.page - 1,
    pageSize: query.pageSize,
    filter: {
      ...query?.filter,
      deleted: false,
    },
  },
})


const ForeignCarriersList = () => {
  const canCreate = usePermitted([MANAGE_FOREIGN_CARRIERS])
  const [query, setQuery] = useQueryParams(schema)
  const {data} = useQuery(queryDef({query}))
  const foreignCarriers = data?.data

  if (!foreignCarriers) return <NotFound />

  return (
    <ScreenWrapper>
      <ScreenHeader
          title="Zahraniční dopravci"
          breadcrumbs={
            <Breadcrumbs>
              <Breadcrumbs.Item>Adresář</Breadcrumbs.Item>
              <Breadcrumbs.Item active to={clientRoutes.FOREIGN_CARRIERS}>Zahraniční dopravci</Breadcrumbs.Item>
            </Breadcrumbs>
          }
      >
        <ExportButton resource={resources.FOREIGN_CARRIERS} query={query} />
        <If condition={canCreate}>
          <Link to={clientRoutes.FOREIGN_CARRIERS_CREATE}>
            <Button icon={<AddCircleIcon />} variant="contained" fullWidth>
              Přidat Zahraničního dopravce
            </Button>
          </Link>
        </If>
      </ScreenHeader>

      <Filters query={query} setQuery={setQuery}>
        {({handleSubmit, handleReset}) => (
          <>
            <SearchBoxInput name="q" label="Hledat mezi dopravci" placeholder="Vyhledávání potvrďte Enterem" onApply={handleSubmit} />
            <Filters.Drawer alwaysVisibleFilters={['q']} query={query} onApply={handleSubmit} onReset={handleReset}>
              <EnumInput name="licenseNSA" label="Vydávající NSA" enumName={enums.LICENSE_NSA_TYPES} size="small" fullWidth={false} multiple />
              <EnumInput name="transportType" label="Typ dopravy" enumName={enums.TRANSPORT_TYPES} size="small" fullWidth={false} multiple />
              <EnumInput name="activityType" label="Působnost" enumName={enums.ACTIVITY_TYPES} size="small" fullWidth={false} multiple />
            </Filters.Drawer>
          </>
        )}
      </Filters>

      <ForeignCarriersTable
          foreignCarriers={foreignCarriers}
          total={data.total}
          query={query}
          setQuery={setQuery}
      />
    </ScreenWrapper>
  )
}

export default ForeignCarriersList
