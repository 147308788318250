import ClearIcon from '@mui/icons-material/Clear'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import DoneIcon from '@mui/icons-material/Done'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import {Checkbox, FormControl, FormControlLabel, FormHelperText} from '@mui/material'
import {Choose, When, Otherwise, If} from 'babel-plugin-jsx-control-statements'
import {indexOf} from 'lodash'
import type {FC} from 'react'
import type {UseFieldConfig} from 'react-final-form'
import {useField} from 'react-final-form'
import {StaticField} from '../visual'
import type {ShowError} from './forms'
import {showErrorOnChange} from './forms'


const checkboxStates = ['', true, false]


type IndeterminateCheckboxProps = {
  readOnly?: boolean
  name: string
  label: string
  required?: boolean
  helperText?: string
  fullWidth?: boolean
  showError?: ShowError
  fieldProps?: UseFieldConfig<boolean | string>
}


const IndeterminateCheckbox: FC<IndeterminateCheckboxProps> = ({
  readOnly, name, label, required, helperText, fullWidth = true, showError = showErrorOnChange, fieldProps, ...props
}) => {
  const {input: {value, onChange, ...restInput}, meta} = useField<boolean | string>(name, fieldProps)
  const {isError, helperTextOrError} = showError({meta, helperText})

  const handleClick = () => {
    const index = indexOf(checkboxStates, value) + 1 % checkboxStates.length
    onChange(checkboxStates[index])
  }

  return (
    <Choose>
      <When condition={!readOnly}>
        <FormControl
            fullWidth={fullWidth}
            error={isError}
            required={required}
        >
          <FormControlLabel
              label={label}
              control={
                <Checkbox
                    name={name}
                    onClick={handleClick}
                    checked={Boolean(value)}
                    icon={<DisabledByDefaultIcon color="error" />}
                    indeterminateIcon={<IndeterminateCheckBoxIcon color="neutral" />}
                    indeterminate={value === ''}
                    inputProps={{required, ...restInput}}
                    {...props}
                />
              }
          />
          <If condition={helperTextOrError}>
            <FormHelperText>{helperTextOrError}</FormHelperText>
          </If>
        </FormControl>
      </When>
      <Otherwise>
        <StaticField label={label}>
          <Choose>
            <When condition={value === true}>
              <DoneIcon color="primary" />
            </When>
            <When condition={value === false}>
              <ClearIcon color="error" />
            </When>
            <Otherwise>
              <IndeterminateCheckBoxIcon color="neutral" />
            </Otherwise>
          </Choose>
        </StaticField>
      </Otherwise>
    </Choose>
  )
}

export default IndeterminateCheckbox
