import z from 'zod'
import * as schemas from './schemas'


export const coordinatesFieldsSchema = schemas.object({
  coordinatesX: schemas.number(),
  coordinatesY: schemas.number(),
  coordinatesX2: schemas.number(),
  coordinatesY2: schemas.number(),
}).or(schemas.optionalObject({
  coordinatesX: z.undefined(),
  coordinatesY: z.undefined(),
  coordinatesX2: z.undefined(),
  coordinatesY2: z.undefined(),
}))

type Coordinates = [[number, number], [number, number]]

export const formatCoordinates = (coordinates: Coordinates | null | undefined) => {
  if (!coordinates) return {}
  return {
    coordinatesX: coordinates?.[0]?.[0],
    coordinatesY: coordinates?.[0]?.[1],
    coordinatesX2: coordinates?.[1]?.[0],
    coordinatesY2: coordinates?.[1]?.[1],
  }
}

type CoordinatesFields = {
  coordinatesX?: number,
  coordinatesY?: number,
  coordinatesX2?: number,
  coordinatesY2?: number,
}

export const parseCoordinates = (coordinatesFields: CoordinatesFields): Coordinates | null => {
  if (
    !coordinatesFields.coordinatesX ||
    !coordinatesFields.coordinatesY ||
    !coordinatesFields.coordinatesX2 ||
    !coordinatesFields.coordinatesY2
  ) return null

  return [
    [coordinatesFields.coordinatesX, coordinatesFields.coordinatesY],
    [coordinatesFields.coordinatesX2, coordinatesFields.coordinatesY2],
  ]
}
