import RemoveIcon from '@mui/icons-material/Remove'
import {Autocomplete, TextField} from '@mui/material'
import {Choose, Otherwise, When} from 'babel-plugin-jsx-control-statements'
import {isEmpty, isObject} from 'lodash'
import type {ComponentProps} from 'react'
import type {UseFieldConfig} from 'react-final-form'
import {useField} from 'react-final-form'
import type {SearchMethod} from '../../../../hooks/api'
import {useCompanyInfo} from '../../../../hooks/api'
import type {ShowError} from '../../../form/forms'
import {showErrorOnChange} from '../../../form/forms'
import {StaticField} from '../../../visual'


const getEnabledByMethod = (method: SearchMethod, value: string) => {
  if (method === 'name') return !isEmpty(value) && value?.length > 4
  if (method === 'companyNumber') return !isEmpty(value) && value?.length === 8
  return false
}

const formatByMethod = (method: SearchMethod, value: string) => {
  if (method === 'companyNumber') return value?.replace(/\s/g, '')
  return value
}

type TextFieldProps<TValues extends Record<string, unknown>[]> = ComponentProps<typeof TextField> & {
  disableSpacing?: boolean
  readOnly?: boolean
  name: string
  searchMethod: SearchMethod
  showError?: ShowError
  fieldProps?: UseFieldConfig<string>
  onCompanyClick?: (company: TValues[number]) => void
  getOptionLabel?: (option: TValues[number]) => string
}

const CompanyInput = <TValues extends Record<string, unknown>[]>({
  disableSpacing, readOnly, name, searchMethod, label, disabled, required, helperText, fullWidth = true, onCompanyClick,
  getOptionLabel, showError = showErrorOnChange, fieldProps, ...props
}: TextFieldProps<TValues>) => {
  const {input: {value, onChange, ...restInput}, meta} = useField<string>(name, {
    ...fieldProps,
    format: (value) => formatByMethod(searchMethod, value),
  })
  const {isError, helperTextOrError} = showError({meta, helperText})
  const {companies, searchCompanies, loading} = useCompanyInfo<TValues>(searchMethod)

  return (
    <Choose>
      <When condition={!readOnly}>
        <Autocomplete
            fullWidth
            freeSolo
            loading={loading}
            options={companies || []}
            getOptionLabel={(option: TValues[number]) => getOptionLabel ? getOptionLabel(option) : ''}
            inputValue={value || ''}
            onChange={(_e, newValue: TValues[number]) => (
              isObject(newValue) && onCompanyClick) ? onCompanyClick(newValue) : undefined
            }
            onInputChange={async (event, newValue, reason) => {
              if (reason === 'clear') {
                onChange('')
                return
              }
              if ((!event || event.type !== 'change')) return
              event.preventDefault()
              const enabled = getEnabledByMethod(searchMethod, newValue)
              onChange(newValue)
              await searchCompanies(newValue, enabled)
            }}
            renderInput={(params) => (
              <TextField
                  {...params}
                  {...restInput}
                  fullWidth={fullWidth}
                  helperText={helperTextOrError}
                  error={isError}
                  label={label}
                  disabled={disabled}
                  onChange={onChange}
                  name={name}
                  value={value}
                  required={required}
                  {...props}
              />
            )}
        />
      </When>
      <Otherwise>
        <StaticField label={label} disableSpacing={disableSpacing}>
          <Choose>
            <When condition={value}>
              {value}
            </When>
            <Otherwise>
              <RemoveIcon />
            </Otherwise>
          </Choose>
        </StaticField>
      </Otherwise>
    </Choose>
  )
}


export default CompanyInput
