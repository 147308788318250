import {Accordion, AccordionDetails, AccordionSummary, Alert, Stack} from '@mui/material'
import {If} from 'babel-plugin-jsx-control-statements'
import type {GetSidingResponseSchema} from 'common/responses'
import {format} from 'date-fns'
import {isEmpty, map, noop, orderBy} from 'lodash'
import type {FC, SyntheticEvent} from 'react'
import {useState} from 'react'
import {Tabs} from '../../../visual'
import SupervisionsCreate from '../../supervisions/SupervisionsCreate'
import SupervisionsForm from '../../supervisions/SupervisionsForm'


type Supervision = GetSidingResponseSchema['supervisions'][number]
type SupervisionsProps = {
  supervisions: Supervision[]
  defaultIndex?: number
}

const Supervisions: FC<SupervisionsProps> = ({supervisions, defaultIndex = false}) => {
  const [expanded, setExpanded] = useState<number | boolean>(defaultIndex)
  const handleChange = (panel: number) => (_event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  if (isEmpty(supervisions)) return null

  return (
    <div>
      {supervisions.map((supervision, index) => (
        <Accordion key={supervision.id} expanded={expanded === index} onChange={handleChange(index)}>
          <AccordionSummary>
            Státní dozor {supervision.id} ({format(supervision.supervisedAt, 'dd/MM/yyyy')})
          </AccordionSummary>
          <AccordionDetails>
            <SupervisionsForm
                initialValues={{
                  ...supervision,

                  userIds: map(supervision.users, 'id'),
                }}
                onSubmit={noop}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}

type SupervisionsPanelProps = {
  sidingId: GetSidingResponseSchema['id']
  currentPermit?: GetSidingResponseSchema['permits'][number] | null
  supervisions: GetSidingResponseSchema['supervisions']
  canCreateSupervisions?: boolean
}


const SupervisionsPanel:FC<SupervisionsPanelProps> = ({
  sidingId, currentPermit, supervisions, canCreateSupervisions,
}) => {
  const orderedSupervisions = orderBy(supervisions, ['supervisedAt', 'createdAt'], ['desc', 'desc'])

  return (
    <>
      <Tabs.Section>
        <If condition={canCreateSupervisions}>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <SupervisionsCreate sidingId={sidingId} permit={currentPermit ? currentPermit : null} />
          </Stack>
        </If>
        <Supervisions supervisions={orderedSupervisions} defaultIndex={0} />
        <If condition={isEmpty(orderedSupervisions)}>
          <Alert>Nebyly provedeny státní dozory</Alert>
        </If>
      </Tabs.Section>
    </>
  )
}

export const SUPERVISIONS_PANEL_LABEL = 'Státní dozory'
export default SupervisionsPanel
