import {MANAGE_PERMITS} from 'constants/permissions'
import * as permitTypes from 'constants/permitTypes'
import * as resources from 'constants/resources'
import {isObject} from 'lodash'
import type {ComponentProps} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import * as clientRoutes from '../../../constants/routes'
import {useCreateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import usePermitted from '../../../hooks/usePermitted'
import generatePath from '../../../utils/generatePath'
import AsymetricLayout from '../../containers/AsymetricLayout'
import Breadcrumbs from '../../containers/Breadcrumbs'
import ConfirmModal from '../../containers/ConfirmModal'
import FormBlocker from '../../containers/FormBlocker'
import FormErrorAlert from '../../containers/alerts/FormErrorAlert'
import TextInput from '../../form/TextInput'
import {ScreenHeader, ScreenWrapper} from '../../visual'
import NotFound from '../NotFound'
import PermitsForm from './PermitsForm'


const PermitsCreate = () => {
  const canCreate = usePermitted([MANAGE_PERMITS], {renderNotFound: true})
  const createPermits = useCreateResource({resource: resources.PERMITS})
  const showAlert = useAlert()
  const navigate = useNavigate()
  const location = useLocation()
  const sidingId = isObject(location.state) && 'sidingId' in location.state ? Number(location.state.sidingId) : undefined

  if (!canCreate) {
    return <NotFound />
  }

  const handleCreatePermits: ComponentProps<typeof PermitsForm>['onSubmit'] = async (values) => {
    const {data} = await createPermits.mutateAsync(values)

    navigate(generatePath(clientRoutes.PERMITS_EDIT, {permitId: data.id}))
    showAlert(`Úřední povolení ${data.id} vytvořeno`, 'success')
  }

  return (
    <PermitsForm
        initialValues={{
          type: permitTypes.SIDING,
          sidingId,
        }}
        onSubmit={handleCreatePermits}
    >
      {({form, valid, handleSubmit}) => (
        <ScreenWrapper>
          <FormBlocker />
          <ScreenHeader
              title="Vytvořit úřední povolení"
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item>Evidence vleček</Breadcrumbs.Item>
                  <Breadcrumbs.Item to={clientRoutes.PERMITS}>Úřední povolení</Breadcrumbs.Item>
                  <Breadcrumbs.Item active>Vytvořit úřední povolení</Breadcrumbs.Item>
                </Breadcrumbs>
              }
          >
            <ConfirmModal
                title="Přejete si uložit změnu údajů?"
                valid={valid}
                onConfirm={handleSubmit}
            >
              <p>
                Chystáte se uložit veškeré změny, které byly na kartě Úřední povolení provedeny.
                Detail změn si budete moci zobrazit v historii úředního povolení.
                Veškeré údaje lze kdykoli opět přepsat.
              </p>
              <TextInput name="revisionNote" label="Zpráva" multiline />
            </ConfirmModal>
          </ScreenHeader>
          <FormErrorAlert />
          <AsymetricLayout>
            <AsymetricLayout.Items>
              {form}
            </AsymetricLayout.Items>
          </AsymetricLayout>

        </ScreenWrapper>
      )}
    </PermitsForm>
  )
}

export default PermitsCreate
