import * as trackTypes from 'constants/trackTypes'


export default {
  [trackTypes.SIDING]: 'Vlečka',
  [trackTypes.LOCAL]: 'Místní dráha',
  [trackTypes.NATIONWIDE_PLUS_REGIONAL]: 'Celostátní + Regionální dráha',
  [trackTypes.NATIONWIDE]: 'Celostátní dráha',
  [trackTypes.REGIONAL]: 'Regionální dráha',
  [trackTypes.SIDING_PLUS_PERIMETER]: 'Vlečka + Obvod stanice',
  [trackTypes.STATION]: 'Stanice',
} as const
