import type {Radio} from '@mui/material'
import {FormControl, FormHelperText, RadioGroup as MuiRadioGroup, FormLabel} from '@mui/material'
import type {ComponentProps, FC, ReactNode} from 'react'


type RadioGroupProps = ComponentProps<typeof Radio> & {
  label?: string
  helperText?: ReactNode,
  error?: boolean,
  row?: boolean,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void | undefined,
  children?: ReactNode,
}

const RadioGroup: FC<RadioGroupProps> = ({
  name, label, onChange, helperText, error, required, disabled, row = true, children,
}) => {
  return (
    <>
      <FormControl error={error} required={required} disabled={disabled}>
        <FormLabel error={error}>{label}</FormLabel>
        <MuiRadioGroup onChange={onChange} row={row} name={name}>
          {children}
        </MuiRadioGroup>
      </FormControl>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </>
  )
}

export default RadioGroup
