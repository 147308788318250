import {coordinatesFieldsSchema, formatCoordinates, parseCoordinates} from 'common/coordinates'
import type {GetSidingResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import {INFRASTRUCTURE} from 'constants/departments'
import {MANAGE_PERMITS, MANAGE_SUPERVISIONS} from 'constants/permissions'
import {useState} from 'react'
import type {TypeOf} from 'zod'
import {useSession} from '../../../hooks/auth'
import usePermitted from '../../../hooks/usePermitted'
import CheckboxInput from '../../form/CheckboxInput'
import withForm from '../../form/withForm'
import {FormSection, Tabs} from '../../visual'
import GeneralPanel, {GENERAL_PANEL_LABEL} from './panels/GeneralPanel'
import InfrastructurePanel, {INFRASTRUCTURE_PANEL_LABEL} from './panels/InfrastructurePanel'
import PermitsPanel, {PERMITS_PANEL_LABEL} from './panels/PermitsPanel'
import SidingCancellationPanel, {SIDING_CANCELLATION_PANEL_LABEL} from './panels/SidingCancellationPanel'
import SupervisionsPanel, {SUPERVISIONS_PANEL_LABEL} from './panels/SupervisionsPanel'


const schema = schemas.siding.siding.and(coordinatesFieldsSchema)

const tabs = [
  GENERAL_PANEL_LABEL,
  PERMITS_PANEL_LABEL,
  SUPERVISIONS_PANEL_LABEL,
  INFRASTRUCTURE_PANEL_LABEL,
  SIDING_CANCELLATION_PANEL_LABEL,
]

const format = (values: TypeOf<typeof schema>) => {
  return {
    ...values,
    ...formatCoordinates(values?.coordinates),
  }
}

const parse = (values: TypeOf<typeof schema>) => {
  return {
    ...values,
    coordinates: parseCoordinates(values),
  }
}

type SidingsFormProps = {
  sidingId?: GetSidingResponseSchema['id']
  readOnly?: boolean
  permits: GetSidingResponseSchema['permits']
  supervisions: GetSidingResponseSchema['supervisions']
  currentSupervisionInfrastructure?: GetSidingResponseSchema['supervisions'][number]
  currentSupervisionOperational?: GetSidingResponseSchema['supervisions'][number]
  currentPermit: GetSidingResponseSchema['permits'][number] | null
  sidingCancellations?: GetSidingResponseSchema['sidingCancellations'] | null
}

const SidingsForm = withForm({schema, format, parse})<SidingsFormProps>(({
  sidingId,
  readOnly,
  permits,
  supervisions,
  currentPermit,
  currentSupervisionInfrastructure,
  currentSupervisionOperational,
  sidingCancellations,
}) => {
  const canCreatePermit = usePermitted([MANAGE_PERMITS])
  const canCreateSupervisions = usePermitted([MANAGE_SUPERVISIONS])
  const session = useSession()
  const [activeTab, setActiveTab] = useState(0)
  const isInfrastrucure = session?.department === INFRASTRUCTURE
  const isNewRecord = !sidingId

  return (
    <FormSection
        title="Detail vlečky"
        actions={
          <CheckboxInput
              name="requiresVerification"
              label="Potřebné potvrzení změny údajů"
              formControlLabelProps={{labelPlacement: 'start'}}
              disabled={readOnly || isInfrastrucure}
          />
        }
    >
      <Tabs
          labels={tabs}
          value={activeTab}
          disabledTabs={{
            2: isNewRecord,
            4: isNewRecord,
          }}
          onChange={(_e, value: number) => setActiveTab(value)}
      >
        <Tabs.Panel value={activeTab} index={0}>
          <GeneralPanel
              sidingId={sidingId}
              currentSupervisionOperational={currentSupervisionOperational}
              readOnly={readOnly || isInfrastrucure}
          />
        </Tabs.Panel>
        <Tabs.Panel value={activeTab} index={1}>
          <PermitsPanel
              sidingId={!isNewRecord ? sidingId : null}
              permit={currentPermit}
              permits={permits}
              canCreatePermit={canCreatePermit && !currentPermit && Boolean(sidingId)}
          />
        </Tabs.Panel>
        {sidingId ? (
          <Tabs.Panel value={activeTab} index={2}>
            <SupervisionsPanel
                sidingId={sidingId}
                currentPermit={currentPermit}
                supervisions={supervisions}
                canCreateSupervisions={canCreateSupervisions}
            />
          </Tabs.Panel>
        ) : null}
        <Tabs.Panel value={activeTab} index={3}>
          <InfrastructurePanel
              isNewRecord={isNewRecord}
              currentSupervisionInfrastructure={currentSupervisionInfrastructure}
              readOnly={readOnly}
          />
        </Tabs.Panel>
        {sidingId && sidingCancellations ? (
          <Tabs.Panel value={activeTab} index={4}>
            <SidingCancellationPanel sidingId={sidingId} sidingCancellations={sidingCancellations} />
          </Tabs.Panel>
        ) : null}
      </Tabs>
    </FormSection>
  )
})

export default SidingsForm
