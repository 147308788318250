import {useQuery} from '@tanstack/react-query'
import {If} from 'babel-plugin-jsx-control-statements'
import type {GetCompanyResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import {EDIT_COMPANIES} from 'constants/permissions'
import * as resources from 'constants/resources'
import {omit} from 'lodash'
import type {ComponentProps} from 'react'
import {useNavigate} from 'react-router'
import * as clientRoutes from '../../../constants/routes'
import {createOneQuery, useUpdateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import useParams from '../../../hooks/useParams'
import usePermitted from '../../../hooks/usePermitted'
import useQueryParams from '../../../hooks/useQueryParams'
import AsymetricLayout from '../../containers/AsymetricLayout'
import Breadcrumbs from '../../containers/Breadcrumbs'
import ConfirmModal from '../../containers/ConfirmModal'
import ExternalLinkField from '../../containers/ExternalLinkField'
import FormBlocker from '../../containers/FormBlocker'
import Revisions from '../../containers/Revisions'
import FormErrorAlert from '../../containers/alerts/FormErrorAlert'
import ReadOnlyAlert from '../../containers/alerts/ReadOnlyAlert'
import TextInput from '../../form/TextInput'
import {FormSection, ScreenHeader, ScreenWrapper} from '../../visual'
import NotFound from '../NotFound'
import CompaniesForm from './CompaniesForm'
import CompanyRevisionDetail from './CompaniesRevisionDetail'


const schema = {
  revisionId: schemas.nonNegativeInteger().optional(),
}

export const queryDef = ({companyId}: {companyId: number}) => createOneQuery<GetCompanyResponseSchema>({
  resource: resources.COMPANIES,
  id: companyId,
})

const CompaniesEdit = () => {
  const canEdit = usePermitted([EDIT_COMPANIES])
  const {companyId} = useParams()
  const [query, setQuery] = useQueryParams(schema)
  const navigate = useNavigate()
  const showAlert = useAlert()
  const {data} = useQuery({...queryDef({companyId: Number(companyId)}), enabled: Boolean(Number(companyId))})
  const updateCompanies = useUpdateResource({resource: resources.COMPANIES, id: companyId})
  const company = data?.data


  if (!company) return <NotFound />

  const hadndleUpdateCompanies: ComponentProps<typeof CompaniesForm>['onSubmit'] = async (values) => {
    await updateCompanies.mutateAsync({
      name: values.name,
      companyNumber: values.companyNumber,
      address: values.address,
      city: values.city,
      postCode: values.postCode,
      phone: values.phone || null,
      email: values.email || null,
      carrierCertificate: values.carrierCertificate,
      carrierValidUntil: values.carrierValidUntil,
      statutories: values.statutories,
      notes: values.notes || '',
      revisionNote: values.revisionNote || '',
    })
    navigate(clientRoutes.COMPANIES)
    showAlert(`Firma ${companyId} uložena`, 'success')
  }

  const justiceUrl = clientRoutes.getEJusticeUrl(company.companyNumber)

  return (
    <CompaniesForm
        initialValues={{
          ...company,
          isCarrier: Boolean(company.carrierCertificate),
        }}
        innerProps={{readOnly: !canEdit}}
        onSubmit={hadndleUpdateCompanies}
    >
      {({form, initialValues, valid, handleSubmit}) => (
        <ScreenWrapper>
          <FormBlocker />
          <ScreenHeader
              title={company.name}
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item>Adresář</Breadcrumbs.Item>
                  <Breadcrumbs.Item to={clientRoutes.COMPANIES}>Firmy</Breadcrumbs.Item>
                  <Breadcrumbs.Item active>{company.name}</Breadcrumbs.Item>
                </Breadcrumbs>
              }
          >
            <If condition={canEdit}>
              <ConfirmModal
                  title="Přejete si uložit změnu údajů?"
                  valid={valid}
                  onConfirm={handleSubmit}
              >
                <p>
                  Chystáte se uložit veškeré změny, které byly na kartě Firma provedeny.
                  Detail změn si budete moci zobrazit v historii firmy. Veškeré údaje lze kdykoli opět přepsat.
                </p>
                <TextInput name="revisionNote" label="Zpráva" multiline />
              </ConfirmModal>
            </If>
          </ScreenHeader>
          <FormErrorAlert />
          <If condition={!canEdit}>
            <ReadOnlyAlert />
          </If>
          <AsymetricLayout>
            <AsymetricLayout.Items>
              {form}
            </AsymetricLayout.Items>
            <AsymetricLayout.Items>
              <If condition={justiceUrl}>
                <FormSection title="Užitečné odkazy">
                  <ExternalLinkField
                      label="Zobrazení subjektu ve veřejném rejstříku"
                      link={justiceUrl}
                      linkText="Otevřít eJustice"
                  />
                </FormSection>
              </If>
              <Revisions
                  resource={resources.COMPANY_REVISIONS}
                  query={{companyId: 'id' in initialValues && Number(initialValues?.id)}}
                  onRevisionClick={(revisionId) => setQuery((prevQuery) => ({...prevQuery, revisionId}))}
              />
              <CompanyRevisionDetail revisionId={query.revisionId || null} onClose={() => setQuery((prevQuery) => omit(prevQuery, 'revisionId'))} />
            </AsymetricLayout.Items>
          </AsymetricLayout>
        </ScreenWrapper>
      )}
    </CompaniesForm>
  )
}

export default CompaniesEdit
