import ClearIcon from '@mui/icons-material/Clear'
import DoneIcon from '@mui/icons-material/Done'
import {Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import type {ComponentProps, FC} from 'react'
import type {UseFieldConfig} from 'react-final-form'
import {useField} from 'react-final-form'
import {StaticField, Switch} from '../visual'
import type {ShowError} from './forms'
import {showErrorOnChange} from './forms'


type BooleanInputProps = ComponentProps<typeof Switch> & {
  name: string
  label: string
  required?: boolean
  helperText?: string
  fullWidth?: boolean
  disableSpacing?: boolean,
  showError?: ShowError
  fieldProps?: UseFieldConfig<string, string>,
}

const BooleanInput: FC<BooleanInputProps> = ({
  disableSpacing, readOnly, name, label, required, disabled, helperText, fullWidth = true, 
  showError = showErrorOnChange, fieldProps, ...props
}) => {
  const {input: {value, onChange, checked, ...restInput}, meta} = useField(
    name, {...fieldProps, type: 'checkbox'},
  )
  const {isError, helperTextOrError} = showError({meta, helperText})

  return (
    <Choose>
      <When condition={!readOnly}>
        <Switch
            fullWidth={fullWidth}
            name={name}
            label={label}
            value={value}
            onChange={onChange}
            checked={checked}
            helperText={helperTextOrError}
            error={isError}
            required={required}
            disabled={disabled}
            inputProps={{required, ...restInput}}
            {...props}
        />
      </When>
      <Otherwise>
        <StaticField label={label} disableSpacing={disableSpacing}>
          <Choose>
            <When condition={checked}>
              <DoneIcon color="primary" />
            </When>
            <Otherwise>
              <ClearIcon color="error" />
            </Otherwise>
          </Choose>
        </StaticField>
      </Otherwise>
    </Choose>
  )
}

export default BooleanInput
