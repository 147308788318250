import CloseIcon from '@mui/icons-material/Close'
import {Drawer, IconButton, Stack, Typography, styled} from '@mui/material'
import {If} from 'babel-plugin-jsx-control-statements'
import type {ComponentProps, FC, ReactNode} from 'react'


const drawerWidth = '450px'

const StyledFiltersContainerDrawer = styled(Drawer)(({theme}) => ({
  '.MuiDrawer-paper': {
    padding: theme.spacing(2),
    paddingTop: `calc(57px + ${theme.spacing(2)})`,
    width: drawerWidth,
    height: '100%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

const StyledHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(1),
}))

const StyledContent = styled('div')(({theme}) => ({
  flex: '1 1 auto',
  overflowY: 'auto',
  padding: `${theme.spacing(1)} 0`,
}))

const StyledActions = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  padding: `${theme.spacing(2)} 0 0`,
  flex: '0 0 auto',
}))

type FiltersContainerDrawerProps = ComponentProps<typeof Drawer> & {
  actions?: ReactNode
}

const FiltersContainerDrawer: FC<FiltersContainerDrawerProps> = ({actions, children, onClose, ...props}) => {
  return (
    <StyledFiltersContainerDrawer onClose={onClose} {...props}>
      <If condition={onClose}>
        <StyledHeader>
          <Typography fontWeight="bold">
            Filtry
          </Typography>
          <IconButton
              title="Zavřít"
              onClick={(e) => {
                if (onClose) {
                  onClose(e, 'escapeKeyDown')
                }
              }}
          >
            <CloseIcon />
          </IconButton>
        </StyledHeader>
      </If>
      <StyledContent>
        <Stack useFlexGap gap={2}>
          {children}
        </Stack>
      </StyledContent>
      <If condition={actions}>
        <StyledActions>
          {actions}
        </StyledActions>
      </If>
    </StyledFiltersContainerDrawer>
  )
}

export default FiltersContainerDrawer
