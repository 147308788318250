import {Chip} from '@mui/material'
import type {Getter} from '@tanstack/react-table'
import type {Enum, Enums} from 'common/labels/enums'
import enums from 'common/labels/enums'
import {castArray, isEmpty, map} from 'lodash'
import type {ReactNode} from 'react'
import Fallback from './Fallback'


type EnumCellArgs = {
  enumName: Enum
  fallback?: ReactNode
}

const enumCell = ({enumName, fallback = '–'}: EnumCellArgs) => {
  const renderEnumCell = <TValue extends keyof Enums[Enum]>(
    {getValue}: {getValue: Getter<TValue | TValue[]>},
  ) => {
    const value = getValue()

    if (isEmpty(value)) return <Fallback>{fallback}</Fallback>
    return map(castArray(value), (item, index) => (
      <Chip key={index} label={enums[enumName][item]} />
    ))
  }
  return renderEnumCell
}

export default enumCell
