import * as carrierNSATypes from 'constants/carrierNSATypes'


export default {
  [carrierNSATypes.ERA]: 'Evropská agentura (ERA)',
  [carrierNSATypes.SLOVAKIA]: 'Slovensko',
  [carrierNSATypes.CZECH_REPUBLIC]: 'Česká repubilika',
  [carrierNSATypes.POLAND]: 'Polsko',
  [carrierNSATypes.AUSTRIA]: 'Rakousko',
  [carrierNSATypes.GERMANY]: 'Spolková republika Německo',
}
