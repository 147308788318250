import {extendApi} from '@anatine/zod-openapi'
import {ALL_ACTIVITY_TYPES} from 'constants/activityTypes'
import {ALL_CARRIER_NSA_TYPES} from 'constants/carrierNSATypes'
import {ALL_CLOSED_AREAL_TYPES} from 'constants/closedArealTypes'
import {ALL_DEPARTMENTS} from 'constants/departments'
import {ALL_INFRASTRUCTURE_DOCUMENT_TYPES} from 'constants/infrastructureDocumentTypes'
import {ALL_LICENSE_CANCELLATION_REASONS} from 'constants/licenseCancellationReasons'
import {ALL_LICENSE_NSA_TYPES} from 'constants/licenseNSATypes'
import {ALL_PERMISSIONS} from 'constants/permissions'
import {ALL_PERMIT_CANCELLATION_REASONS} from 'constants/permitCancellationReasons'
import {ALL_PERMIT_TYPES} from 'constants/permitTypes'
import {ALL_RESOURCES} from 'constants/resources'
import {ALL_TRACK_CATEGORIES} from 'constants/trackCategories'
import {ALL_TRACK_TYPES} from 'constants/trackTypes'
import {ALL_TRANSPORT_CATEGORIES} from 'constants/transportCategories'
import {ALL_TRANSPORT_TYPES} from 'constants/transportTypes'
import z from 'zod'
import {createEnum, number} from './types'

// General
export const email = (example = 'example@example.com') => extendApi(z.string().email(), {example})

export const telephone = () => extendApi(
  z.string(),
  {example: '+421903123456'},
)

export const date = () => extendApi(
  z.string()
    .trim()
    .regex(/^\d{4}-(0[1-9]|1[0-2])-([0][1-9]|[12][0-9]|[3][01])$/),
  {example: '1980-03-24'},
)

export const coordinates = () => extendApi(
  z.tuple([z.tuple([number(), number()]), z.tuple([number(), number()])]),
)

export const resources = () => createEnum(ALL_RESOURCES)
export const permissions = () => createEnum(ALL_PERMISSIONS)
export const permitType = () => createEnum(ALL_PERMIT_TYPES)
export const permitCancellationReason = () => createEnum(ALL_PERMIT_CANCELLATION_REASONS)
export const licenseCancellationReason = () => createEnum(ALL_LICENSE_CANCELLATION_REASONS)
export const department = () => createEnum(ALL_DEPARTMENTS)
export const trackType = () => createEnum(ALL_TRACK_TYPES)
export const transportType = () => createEnum(ALL_TRANSPORT_TYPES)
export const transportCategory = () => createEnum(ALL_TRANSPORT_CATEGORIES)
export const trackCategory = () => createEnum(ALL_TRACK_CATEGORIES)
export const closedArealType = () => createEnum(ALL_CLOSED_AREAL_TYPES)
export const infrastructureDocumentType = () => createEnum(ALL_INFRASTRUCTURE_DOCUMENT_TYPES)
export const licenseNSAType = () => createEnum(ALL_LICENSE_NSA_TYPES)
export const activityType = () => createEnum(ALL_ACTIVITY_TYPES)
export const carrierNSAType = () => createEnum(ALL_CARRIER_NSA_TYPES)
