import {createColumnHelper} from '@tanstack/react-table'
import type {ListSupervisionResponseSchema} from 'backend/src/controllers/supervisions/ListSupervision'
import * as enums from 'common/labels/enums'
import * as resources from 'constants/resources'
import type {ComponentProps, Dispatch, FC, SetStateAction} from 'react'
import {getGinisUrl} from '../../../constants/routes'
import {getSortableColumns} from '../../../utils/lists'
import ControlledDatagrid from '../../containers/Datagrid/ControlledDatagrid'
import Fallback from '../../containers/Datagrid/cells/Fallback'
import dateCell from '../../containers/Datagrid/cells/dateCell'
import enumCell from '../../containers/Datagrid/cells/enumCell'
import relationalCell from '../../containers/Datagrid/cells/relationalCell'
import {ExternalLink} from '../../containers/ExternalLinkField'
import {getSupervisionColor} from '../../form/forms'
import {ColorDot} from '../../visual'


const columnHelper = createColumnHelper<ListSupervisionResponseSchema[number]>()

const columns = [
  columnHelper.accessor('id', {header: 'ID'}),
  columnHelper.accessor('siding.name', {
    id: 'siding.name',
    header: 'Název vlečky',
    cell: relationalCell({resource: resources.SIDINGS, id: 'siding.id'}),
  }),
  columnHelper.accessor('company.name', {
    id: 'company.name',
    header: 'Kontrolovaný subjekt',
    cell: relationalCell({resource: resources.COMPANIES, id: 'company.id'}),
  }),
  columnHelper.accessor('users', {
    header: 'Oprávněná úřední osoba',
    cell: relationalCell({resource: resources.USERS, id: 'id', render: ({firstName, lastName}) => `${firstName} ${lastName}`}),
    enableSorting: false,
  }),
  columnHelper.accessor('department', {header: 'Vydala sekce', cell: enumCell({enumName: enums.DEPARTMENTS})}),
  columnHelper.accessor('supervisedAt', {
    header: 'Výkon SD',
    cell: ({getValue}) => {
      const value = getValue()
      if (!value) return <Fallback>Neuvedeno</Fallback>
      return (
        <>
          <ColorDot color={getSupervisionColor(new Date(value)) || undefined}>
            {dateCell({})({getValue})}
          </ColorDot>
        </>
      )
    },
  }),
  columnHelper.accessor('ginisPid', {
    header: 'PID GINIS',
    cell: ({getValue}) => {
      const value = getValue()
      if (!value) return <Fallback>–</Fallback>
      return <ExternalLink link={getGinisUrl(value)} linkText="Otevřít" />
    },
    enableSorting: false,
  }),
]

export const sortableColumns = getSortableColumns(columns)

type Query = ComponentProps<typeof ControlledDatagrid>['query']

type SupervisionsTableProps = {
  supervisions: ListSupervisionResponseSchema
  total: number | null
  query: Query
  setQuery: Dispatch<SetStateAction<Query>>
}

const SupervisionsTable: FC<SupervisionsTableProps> = ({
  supervisions, total, query, setQuery, ...props
}) => {
  return (
    <ControlledDatagrid<ListSupervisionResponseSchema[number]>
        data={supervisions}
        columns={columns}
        total={total}
        query={query}
        setQuery={setQuery}
        onRowClick={(row) => setQuery((prevQuery) => ({...prevQuery, supervisionId: row.original.id}))}
        {...props}
    />
  )
}

export default SupervisionsTable
