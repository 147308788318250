export const EDIT_COMPANIES = 'EDIT_COMPANIES'
export const EDIT_FOREIGN_CARRIERS = 'EDIT_FOREIGN_CARRIERS'
export const EDIT_LICENSES = 'EDIT_LICENSES'
export const EDIT_PERMITS = 'EDIT_PERMITS'
export const EDIT_SIDINGS = 'EDIT_SIDINGS'
export const EDIT_SIDING_CANCELLATION = 'EDIT_SIDING_CANCELLATION'
export const EDIT_SUPERVISIONS = 'EDIT_SUPERVISIONS'
export const EDIT_USERS = 'EDIT_USERS'
export const MANAGE_COMPANIES = 'MANAGE_COMPANIES'
export const MANAGE_FOREIGN_CARRIERS = 'MANAGE_FOREIGN_CARRIERS'
export const MANAGE_LICENSES = 'MANAGE_LICENSES'
export const MANAGE_PERMITS = 'MANAGE_PERMITS'
export const MANAGE_SIDINGS = 'MANAGE_SIDINGS'
export const MANAGE_SIDING_CANCELLATION = 'MANAGE_SIDING_CANCELLATION'
export const MANAGE_SUPERVISIONS = 'MANAGE_SUPERVISIONS'
export const MANAGE_USERS = 'MANAGE_USERS'
export const RESTORE_SIDINGS = 'RESTORE_SIDINGS'

export const ALL_PERMISSIONS = [
  EDIT_COMPANIES,
  EDIT_FOREIGN_CARRIERS,
  EDIT_LICENSES,
  EDIT_PERMITS,
  EDIT_SIDINGS,
  EDIT_SIDING_CANCELLATION,
  EDIT_SUPERVISIONS,
  EDIT_USERS,
  MANAGE_COMPANIES,
  MANAGE_FOREIGN_CARRIERS,
  MANAGE_LICENSES,
  MANAGE_PERMITS,
  MANAGE_SIDINGS,
  MANAGE_SIDING_CANCELLATION,
  MANAGE_SUPERVISIONS,
  MANAGE_USERS,
  RESTORE_SIDINGS,
] as const

export type Permission = typeof ALL_PERMISSIONS[number]
