import {Button as MuiButton, CircularProgress} from '@mui/material'
import {Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import type {ComponentProps, FC, ReactNode} from 'react'


type BaseButtonProps = ComponentProps<typeof MuiButton> & {
  isPending?: boolean,
  disabled?: boolean,
  icon?: ReactNode,
}

const BaseButton: FC<BaseButtonProps> = ({color, disabled, isPending, variant, onClick, icon, children, ...props}) => {
  return (
    <MuiButton
        disabled={isPending || disabled}
        onClick={onClick}
        variant={variant}
        color={color}
        {...props}
        startIcon={
          <Choose>
            <When condition={isPending}>
              <CircularProgress size={20} />
            </When>
            <Otherwise>
              {icon}
            </Otherwise>
          </Choose>}
    >
      {children}
    </MuiButton>
  )
}

export default BaseButton
