import {styled} from '@mui/material/styles'
import {If} from 'babel-plugin-jsx-control-statements'
import {isEmpty} from 'lodash'
import type {FC} from 'react'
import React from 'react'


type StyledShapeProps = {
  color?: string,
}

const StyledContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const StyledShape = styled('div')<StyledShapeProps>(({theme, color}) => ({
  backgroundColor: color && color,
  minWidth: '8px',
  height: '8px',
  borderRadius: '50%',
  marginRight: theme.spacing(1),
}))

type ColorDotProps = {
  color?: string,
  children: React.ReactNode,
}

const ColorDot: FC<ColorDotProps> = ({color, children, ...props}) => {
  return (
    <StyledContainer {...props}>
      <StyledShape color={color} title={color} />
      <If condition={!isEmpty(children)}>
        {children}
      </If>
    </StyledContainer>
  )
}

export default ColorDot
