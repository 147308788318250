import {Typography} from '@mui/material'
import {styled} from '@mui/material/styles'
import type {FC, ReactNode} from 'react'


const StyledContainer = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'start',
  },
}))

const StyledTitleContainer = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.75),

  [theme.breakpoints.down('md')]: {
    alignItems: 'stretch',
  },
}))

const StyledMuiTypography = styled(Typography)({
  whiteSpace: 'nowrap',
  fontSize: '1.5rem',
  fontWeight: 'bold',
})

const StyledScreenHeaderItems = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: 'auto',
  gap: theme.spacing(1.5),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'stretch',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(0),
  },
}))

type ScreenHeaderProps = {
  title: string,
  children?: ReactNode,
  breadcrumbs?: ReactNode,
}

const ScreenHeader: FC<ScreenHeaderProps> = ({title, breadcrumbs, children, ...props}) => {
  return (
    <StyledContainer {...props}>
      <StyledTitleContainer>
        <StyledMuiTypography variant="h1">{title}</StyledMuiTypography>
        {breadcrumbs}
      </StyledTitleContainer>
      <StyledScreenHeaderItems>
        {children}
      </StyledScreenHeaderItems>
    </StyledContainer>
  )
}

export default ScreenHeader
