import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import {useQuery} from '@tanstack/react-query'
import {If} from 'babel-plugin-jsx-control-statements'
import type {GetRoleResponseSchema} from 'common/responses'
import {EDIT_USERS} from 'constants/permissions'
import type {ComponentProps, FC} from 'react'
import * as resources from '../../../../../constants/resources'
import {useUpdateResource, createOneQuery} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import usePermitted from '../../../hooks/usePermitted'
import Button from '../../containers/Button'
import {Modal} from '../../visual'
import RolesForm from './RolesForm'


export const queryDef = ({roleId}: {roleId: number}) => createOneQuery<GetRoleResponseSchema>({
  resource: resources.ROLES,
  id: roleId,
})

type RolesEditProps = {
  roleId: number
  handleClose: () => void
  refetch?: () => void
}

const RolesEdit: FC<RolesEditProps> = ({roleId, handleClose, refetch}) => {
  const canEdit = usePermitted([EDIT_USERS])
  const {data} = useQuery({...queryDef({roleId}), suspense: false})
  const updateRole = useUpdateResource({resource: resources.ROLES, id: roleId})
  const showAlert = useAlert()
  const role = data?.data

  const handleSubmit: ComponentProps<typeof RolesForm>['onSubmit'] = async (values) => {
    await updateRole.mutateAsync(values)
    showAlert(`Role ${roleId} uložena`, 'success')
    if (refetch) refetch()
    handleClose()
  }

  if (!role) return null

  return (
    <RolesForm onSubmit={handleSubmit} initialValues={role} innerProps={{readOnly: !canEdit}}>
      {({submitting, form, handleSubmit}) => (
        <Modal open onClose={handleClose}>
          <Modal.Title>Rola {roleId}</Modal.Title>
          <Modal.Content>
            {form}
          </Modal.Content>
          <Modal.Actions>
            <Button icon={<CloseIcon />} onClick={handleClose}>Zavřít</Button>
            <If condition={canEdit}>
              <Button
                  icon={<SaveIcon />}
                  variant="contained"
                  disabled={submitting}
                  onClick={handleSubmit}
              >
                Uložit
              </Button>
            </If>
          </Modal.Actions>
        </Modal>
      )}
    </RolesForm>
  )
}

export default RolesEdit
