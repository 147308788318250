import type {Theme} from '@mui/material'
import {Backdrop, useMediaQuery} from '@mui/material'
import type {ComponentProps, FC} from 'react'
import ContentContainer from '../ContentContainer/ContentContainer'
import Drawer from '../Drawer/Drawer'


type NavigationProps = ComponentProps<typeof Drawer>

const Navigation: FC<NavigationProps> = ({open, children, onClose, ...props}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  return (
    <ContentContainer>
      <Drawer open={open} onClose={onClose} {...props}>
        {children}
      </Drawer>
      <Backdrop open={Boolean(isMobile && open)} onClick={onClose ? (e) => onClose(e, 'backdropClick') : undefined} />
    </ContentContainer>
  )
}

export default Navigation
