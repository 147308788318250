import AddCircleIcon from '@mui/icons-material/AddCircle'
import CloseIcon from '@mui/icons-material/Close'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import {Alert, IconButton} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import type {RowSelectionState} from '@tanstack/react-table'
import {If} from 'babel-plugin-jsx-control-statements'
import type {ListUserResponseSchema, ListRoleResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import {REFERENCE_ERROR} from 'constants/errorCodes'
import {MANAGE_USERS} from 'constants/permissions'
import * as resources from 'constants/resources'
import {keys} from 'lodash'
import {useState} from 'react'
import type {TypeOf, ZodObject} from 'zod'
import * as clientRoutes from '../../../constants/routes'
import {createListQuery, useDeleteManyResources} from '../../../hooks/api'
import usePermitted from '../../../hooks/usePermitted'
import useQueryParams from '../../../hooks/useQueryParams'
import {isFrontendError} from '../../../utils/api'
import {queryToSort} from '../../../utils/lists'
import Breadcrumbs from '../../containers/Breadcrumbs'
import Button from '../../containers/Button'
import DeleteButton from '../../containers/DeleteButton'
import Filters from '../../containers/Filters'
import Link from '../../containers/Link'
import ReferenceInput from '../../form/ReferenceInput'
import SearchBoxInput from '../../form/SearchBoxInput'
import {ScreenHeader, ScreenWrapper} from '../../visual'
import NotFound from '../NotFound'
import UsersTable, {sortableColumns} from './UsersTable'


const schema = {
  page: schemas.nonNegativeInteger().default(1),
  pageSize: schemas.positiveInteger().default(10).catch(10),
  sortBy: schemas.createEnum(sortableColumns).default('id').catch('id'),
  sortDirection: schemas.createEnum(['asc', 'desc']).default('desc').catch('desc'),
  filter: schemas.optionalObject({
    q: schemas.requiredString().optional(),
    roleId: schemas.oneOrMany(schemas.id()).optional(),
  }).optional().catch({}),
  roleId: schemas.nonNegativeInteger().optional(),
}

const queryDef = ({query}: {query: TypeOf<ZodObject<typeof schema>>}) => createListQuery<ListUserResponseSchema>({
  resource: resources.USERS,
  query: {
    ...queryToSort(query),
    page: query.page - 1,
    pageSize: query.pageSize,
    filter: {
      ...query?.filter,
      deleted: false,
    },
  },
})


const UsersList = () => {
  const canCreate = usePermitted([MANAGE_USERS])
  const [query, setQuery] = useQueryParams(schema)
  const [error, setError] = useState('')
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const {data} = useQuery(queryDef({query}))
  const deleteUsers = useDeleteManyResources({resource: resources.USERS})
  const users = data?.data

  if (!users) return <NotFound />

  const handleDelete = async () => {
    try {
      await deleteUsers.mutateAsync(keys(rowSelection))
      setRowSelection({})
    } catch (e: unknown) {
      if (isFrontendError(e) && e.data.errorCode === REFERENCE_ERROR) return setError('Některé záznamy nebylo možné vymazat kvůli existující referenci')
      return null
    }
  }

  return (
    <ScreenWrapper>
      <ScreenHeader
          title="Uživatelé"
          breadcrumbs={
            <Breadcrumbs>
              <Breadcrumbs.Item>Administrace</Breadcrumbs.Item>
              <Breadcrumbs.Item active to={clientRoutes.USERS}>Uživatelé</Breadcrumbs.Item>
            </Breadcrumbs>
          }
      >
        <If condition={error}>
          <Alert
              action={
                <IconButton onClick={() => setError('')}>
                  <CloseIcon />
                </IconButton>
              }
              severity="error"
          >
            {error}
          </Alert>
        </If>
        <DeleteButton onDelete={handleDelete} rowSelection={rowSelection} />
        <Link to={clientRoutes.ROLES}>
          <Button icon={<SupervisorAccountIcon />} fullWidth>
            Role
          </Button>
        </Link>
        <If condition={canCreate}>
          <Link to={clientRoutes.USERS_CREATE}>
            <Button icon={<AddCircleIcon />} variant="contained" fullWidth>
              Přidat nového uživatele
            </Button>
          </Link>
        </If>
      </ScreenHeader>

      <Filters query={query} setQuery={setQuery}>
        {({handleSubmit, handleReset}) => (
          <>
            <SearchBoxInput name="q" label="Hledat mezi uživateli" placeholder="Vyhledávání potvrďte Enterem" onApply={handleSubmit} />
            <Filters.Drawer query={query} onApply={handleSubmit} onReset={handleReset}>
              <ReferenceInput<ListRoleResponseSchema, true>
                  name="roleId"
                  label="Role"
                  optionText="name"
                  resource={resources.ROLES}
                  tagRoute={({value}) => `${clientRoutes.ROLES}?roleId=${value}`}
                  size="small"
                  fullWidth={false}
                  multiple
              />
            </Filters.Drawer>
          </>
        )}
      </Filters>

      <UsersTable
          users={users}
          total={data.total}
          query={query}
          setQuery={setQuery}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
      />
    </ScreenWrapper>
  )
}

export default UsersList
