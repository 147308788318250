import type {RowSelectionState} from '@tanstack/react-table'
import {createColumnHelper} from '@tanstack/react-table'
import type {ListUserResponseSchema} from 'common/responses'
import type {ComponentProps, Dispatch, FC, SetStateAction, SyntheticEvent} from 'react'
import {useNavigate} from 'react-router'
import * as clientRoutes from '../../../constants/routes'
import generatePath from '../../../utils/generatePath'
import {getSortableColumns} from '../../../utils/lists'
import ControlledDatagrid from '../../containers/Datagrid/ControlledDatagrid'
import Link from '../../containers/Link'


const columnHelper = createColumnHelper<ListUserResponseSchema[number]>()

const columns = [
  columnHelper.accessor('id', {header: 'ID'}),
  columnHelper.accessor('firstName', {header: 'Jméno'}),
  columnHelper.accessor('lastName', {header: 'Příjmení'}),
  columnHelper.accessor('email', {header: 'Email'}),
  columnHelper.accessor('role.name', {
    id: 'role.name',
    header: 'Role',
    cell: ({row}) => {
      if (row.original.role.deletedAt) return row.original.role.name
      return (
        <Link
            to={{pathname: clientRoutes.ROLES, search: `?roleId=${row.original.roleId}`}}
            onClick={(e: SyntheticEvent) => {
              e.stopPropagation()
            }}
        >
          {row.original.role.name}
        </Link>
      )
    },
  }),
]

export const sortableColumns = getSortableColumns(columns)

type Query = ComponentProps<typeof ControlledDatagrid>['query']

type UsersTableProps = {
  users: ListUserResponseSchema
  total: number | null
  query: Query
  setQuery: Dispatch<SetStateAction<Query>>
  rowSelection: RowSelectionState
  setRowSelection: Dispatch<SetStateAction<RowSelectionState>>
}

const UsersTable: FC<UsersTableProps> = ({
  users, total, query, setQuery, rowSelection, setRowSelection, ...props
}) => {
  const navigate = useNavigate()
  return (
    <ControlledDatagrid<ListUserResponseSchema[number]>
        data={users}
        columns={columns}
        total={total}
        query={query}
        setQuery={setQuery}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        onRowClick={(row) => navigate(
          generatePath(clientRoutes.USERS_EDIT, {userId: row.id}),
        )}
        {...props}
    />
  )
}

export default UsersTable
