import * as trackCategories from 'constants/trackCategories'


export default {
  [trackCategories.NATIONWIDE]: 'Celostátní',
  [trackCategories.REGIONAL]: 'Regionální',
  [trackCategories.LOCAL]: 'Místní',
  [trackCategories.SIDING]: 'Vlečka',
  [trackCategories.EXPERIMENTAL]: 'Experimentální',
  [trackCategories.NATIONWIDE_PLUS_SIDING]: 'Celostátní + Vlečka',
  [trackCategories.REGIONAL_PLUS_SIDING]: 'Regionální + Vlečka',
} as const
