import AddIcon from '@mui/icons-material/Add'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {IconButton, Stack, Typography} from '@mui/material'
import {If} from 'babel-plugin-jsx-control-statements'
import {isString} from 'lodash'
import type {FC} from 'react'
import {FieldArray} from 'react-final-form-arrays'
import Button from '../../../containers/Button'
import CheckboxInput from '../../../form/CheckboxInput'
import TextInput from '../../../form/TextInput'
import {Tabs} from '../../../visual'


export const StatutoryPanelFieldsGeneral = ({readOnly}: {readOnly?: boolean}) => {
  return (
    <FieldArray name="statutories">
      {({fields, meta: {error, touched}}) => (
        <>
          {fields.map((name, index) => (
            <Stack useFlexGap direction="row" alignItems="center" gap={2} key={name}>
              <CheckboxInput name={`${name}.competent`} label={readOnly ? 'Kompetentní' : ''} readOnly={readOnly} />
              <TextInput name={`${name}.name`} label="Jméno člena / OOZ" required readOnly={readOnly} />
              <If condition={!readOnly}>
                <IconButton
                    color="error"
                    onClick={() => {
                      fields.remove(index)
                    }}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </If>
            </Stack>
          ))}
          <If condition={!readOnly}>
            <Stack alignItems="end">
              <If condition={touched && isString(error)}>
                <Typography color="error">{error}</Typography>
              </If>
              <Button variant="text" color="neutral" onClick={() => fields.push({})} endIcon={<AddIcon />}>Přidat člena</Button>
            </Stack>
          </If>
        </>
      )}
    </FieldArray>
  )
}

type StatutoryPanelProps = {
  readOnly?: boolean
}

const StatutoryPanel: FC<StatutoryPanelProps> = ({readOnly}) => {
  return (
    <Tabs.Section>
      <StatutoryPanelFieldsGeneral readOnly={readOnly} />
    </Tabs.Section>
  )
}

export const STATUTORY_PANEL_LABEL = 'Statutární orgán'
export default StatutoryPanel
