import {Skeleton} from '@mui/material'
import {styled} from '@mui/material/styles'
import type {ComponentProps, FC} from 'react'


const StyledSkeletonContainer = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('md')]: {

  },
}))

const StyledSkeleton = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'space-between',
  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'stretch',
  },
}))

const StyledSkeletonTitle = styled(Skeleton)(({theme}) => ({
  [theme.breakpoints.down('md')]: {
    maxWidth: '80%',
  },
}))

const StyledSkeletonButtonsWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  gap: theme.spacing(2),
  width: '500px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '100%',
  },
}))

const StyledSkeletonSpace = styled(Skeleton)(({theme}) => ({
  marginTop: theme.spacing(0.5),
}))

type LoadingProps = ComponentProps<typeof Skeleton>

const Loading: FC<LoadingProps> = () => {
  return (
    <StyledSkeletonContainer>
      <StyledSkeleton>
        <StyledSkeletonTitle variant="rectangular" height={60} width={300} />
        <StyledSkeletonButtonsWrapper>
          <Skeleton variant="rounded" width="100%" height={40} />
          <Skeleton variant="rounded" width="100%" height={40} />
        </StyledSkeletonButtonsWrapper>
      </StyledSkeleton>
      <Skeleton variant="rounded" height={80} />
      <StyledSkeletonSpace variant="rounded" height={500} />
    </StyledSkeletonContainer>
  )
}

export default Loading
