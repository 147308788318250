import type {TableCell} from '@mui/material'
import {Paper, TableContainer, TablePagination} from '@mui/material'
import type {RowSelectionState, SortingState, TableOptions} from '@tanstack/react-table'
import {createColumnHelper, getSortedRowModel, getPaginationRowModel, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import {If} from 'babel-plugin-jsx-control-statements'
import {isFunction} from 'lodash'
import type {ChangeEvent, ComponentProps, Dispatch, SetStateAction} from 'react'
import {useCallback} from 'react'
import ActionCell from './ActionCell'
import DatagridTable from './DatagridTable'
import {RowSelectionCell, RowSelectionHeader} from './RowSelection'


declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData, TValue> {
    padding?: ComponentProps<typeof TableCell>['padding']
    hasAction?: boolean
  }
}

const sortToSorting = ({sortBy, sortDirection}: {sortBy: Query['sortBy'], sortDirection: Query['sortDirection']}) => {
  if (!sortBy || !sortDirection) return []

  return [{id: sortBy, desc: sortDirection === 'desc'}] as SortingState
}

type Query = {
  page: number
  pageSize: number
  sortBy?: string
  sortDirection?: 'asc' | 'desc'
}

type ControlledDatagridProps <TData extends {id: number}> = Omit<TableOptions<TData>, 'enableRowSelection' | 'getCoreRowModel'> & {
  pageOptions?: number[]
  enablePagination?: boolean
  disableAction?: boolean
  total: number | null,
  query: Query,
  setQuery: Dispatch<SetStateAction<Query>>
  rowStyles?: ComponentProps<typeof DatagridTable<TData>>['rowStyles']
  rowSelection?: RowSelectionState,
  setRowSelection?: Dispatch<SetStateAction<RowSelectionState>>
  onRowClick?: ComponentProps<typeof DatagridTable<TData>>['onRowClick']
  tableOptions?: Partial<TableOptions<TData>>
}

const ControlledDatagrid = <TData extends {id: number}, >({
  data,
  columns,
  pageOptions = [5, 10, 25],
  enablePagination = true,
  enableSorting = true,
  enableSortingRemoval = true,
  disableAction = false,
  total,
  query,
  setQuery,
  rowStyles,
  onRowClick,
  rowSelection,
  setRowSelection,
  tableOptions,
}: ControlledDatagridProps<TData>) => {
  const enableRowSelection = Boolean(rowSelection && setRowSelection)
  const sorting = sortToSorting({sortBy: query?.sortBy, sortDirection: query?.sortDirection})
  const setSorting = useCallback((newSorting: SortingState | ((prevSorting: SortingState) => SortingState)) => {
    return setQuery((prevQuery) => {
      const {sortBy: prevSortBy, sortDirection: prevSortDirection, ...rest} = prevQuery
      const prevSorting = sortToSorting({sortBy: prevSortBy, sortDirection: prevSortDirection})
      const sorting = isFunction(newSorting) ? newSorting(prevSorting) : newSorting
      const sortItem = sorting?.[0]
      const sortDirection = sortItem?.desc ? 'desc' : 'asc'
      return {
        ...rest,
        page: 1,
        ...(sortItem?.id ? {sortBy: sortItem?.id, sortDirection} : {}),
      }
    })
  }, [setQuery])

  const columnsHelper = createColumnHelper<TData>()

  const table = useReactTable<TData>({
    data,
    columns: [
      ...(enableRowSelection ? [columnsHelper.display({
        id: 'row_select_id',
        header: RowSelectionHeader,
        cell: RowSelectionCell,
        meta: {
          padding: 'checkbox',
          hasAction: true,
        },
      })] : []),
      ...columns,
      ...(!disableAction ? [columnsHelper.display({
        id: 'action',
        header: 'Akce',
        cell: ActionCell,
      })] : []),
    ],
    getRowId: (row) => String(row?.id),
    state: {
      sorting,
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    pageCount: total || 0,
    manualPagination: true,
    manualSorting: true,
    enableSorting,
    enableSortingRemoval,
    enableRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: enablePagination ? getPaginationRowModel() : undefined,
    getSortedRowModel: getSortedRowModel(),
    ...tableOptions,
  })

  const handlePageChange = (_e: unknown, page: number) => {
    setQuery((prevQuery) => ({...prevQuery, page: page + 1}))
  }

  const handleRowsPerPageChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    return setQuery((prevQuery) => ({...prevQuery, page: 1, pageSize: Number(e.target.value)}))
  }

  return (
    <TableContainer component={Paper}>
      <DatagridTable
          table={table}
          rowStyles={rowStyles}
          onRowClick={onRowClick}
      />
      <If condition={enablePagination}>
        <TablePagination
            component="div"
            rowsPerPage={query?.pageSize || 10}
            page={query?.page ? query?.page - 1 : 0}
            count={table.getPageCount()}
            rowsPerPageOptions={pageOptions}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
        />
      </If>
    </TableContainer>
  )
}

export default ControlledDatagrid
