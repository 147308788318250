import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import {isEmpty, toArray} from 'lodash'
import type {ComponentProps, FC, MouseEvent} from 'react'
import {useState} from 'react'
import {Modal} from '../visual'
import Button from './Button'


type DeleteButtonProps = Omit<ComponentProps<typeof Button>, 'onDelete'> & {
  onDelete: ((e: MouseEvent<HTMLButtonElement>) => void)
  rowSelection?: {number?: boolean, length?: number},
}

  
const DeleteButton: FC<DeleteButtonProps> = ({onDelete, rowSelection, ...props}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    onDelete(e)
    setOpen(false)
  }

  if (isEmpty(rowSelection)) return null
  return (
    <>
      <Button
          icon={<DeleteIcon />}
          onClick={handleOpen}
          color="error"
          variant="contained"
          data-test-id="deleteButton"
          {...props}
      >
        Odstranit {toArray(rowSelection).length}
      </Button>
      <Modal
          open={open}
          onClose={handleClose}
      >
        <Modal.Title>Opravdu chcete vymazat označené záznamy?</Modal.Title>
        <Modal.Actions>
          <Button icon={<CloseIcon />} onClick={handleClose}>Zavřít</Button>
          <Button
              icon={<DeleteIcon />}
              color="error"
              variant="contained"
              onClick={handleDelete}
          >
            Smazat
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default DeleteButton
