import SaveIcon from '@mui/icons-material/Save'
import type {GetSidingResponseSchema} from 'common/responses'
import * as resources from 'constants/resources'
import {format} from 'date-fns'
import type {ComponentProps, FC} from 'react'
import {useUpdateResource} from '../../../../../hooks/api'
import Button from '../../../../containers/Button'
import {Modal} from '../../../../visual'
import SidingCancellationModalForm from './SidingCancellationModalForm'


type SidingsCancellationModalProps = {
  sidingCancellation: GetSidingResponseSchema['sidingCancellations'][number]
  onClose: () => void
}

const SidingCancellationEditModal: FC<SidingsCancellationModalProps> = ({sidingCancellation, onClose}) => {
  const updateSidingCancellation = useUpdateResource(
    {resource: resources.SIDING_CANCELLATIONS, id: sidingCancellation?.id},
  )

  if (!sidingCancellation) return null

  const handleSubmit: ComponentProps<typeof SidingCancellationModalForm>['onSubmit'] = async (values) => {
    await updateSidingCancellation.mutateAsync({
      referenceNumber: values.referenceNumber,
      ginisPid: values.ginisPid,
      cancelledAt: values.cancelledAt,
      cancelledPart: values.cancelledPart || null,
      removalReferenceNumber: values.removalReferenceNumber,
      removalGinisPid: values.removalGinisPid || null,
      removedAt: values.removedAt || null,
      revisionNote: values.revisionNote,
    })
    onClose()
  }

  return (
    <SidingCancellationModalForm
        initialValues={{
          ...sidingCancellation,
          cancelledAt: sidingCancellation.cancelledAt || undefined,
        }}
        onSubmit={handleSubmit}
    >
      {({form, handleSubmit}) => (
        <Modal open onClose={onClose}>
          <Modal.Title>Úprava zrušení vlečky {sidingCancellation.cancelledAt ? format(sidingCancellation.cancelledAt, 'dd/MM/yyyy') : null}</Modal.Title>
          <Modal.Content>
            {form}
          </Modal.Content>
          <Modal.Actions>
            <Button icon={<SaveIcon />} variant="contained" onClick={handleSubmit}>Uložit</Button>
            <Button color="neutral" onClick={onClose}>Zavřít</Button>
          </Modal.Actions>
        </Modal>
      )}
    </SidingCancellationModalForm>
  )
}

export default SidingCancellationEditModal
