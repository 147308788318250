import type {ListUserResponseSchema} from 'common/responses'
import * as resources from 'constants/resources'
import type {FC} from 'react'
import * as clientRoutes from '../../constants/routes'
import generatePath from '../../utils/generatePath'
import {InputStack} from '../visual'
import DateInput from './DateInput'
import ReferenceInput from './ReferenceInput'
import TextInput from './TextInput'


const RevisionMeta: FC = () => {
  return (
    <>
      <InputStack>
        <DateInput name="revisionCreatedAt" label="Datum revize" readOnly />
        <ReferenceInput<ListUserResponseSchema>
            name="revisionUserId"
            label="Revizi provedl"
            resource={resources.USERS}
            optionText={(user) => `${user.firstName} ${user.lastName}`}
            tagRoute={(user) => generatePath(clientRoutes.USERS_EDIT, {userId: user.value})}
            readOnly
        />
      </InputStack>
      <TextInput name="revisionNote" label="Zpráva" multiline readOnly />
    </>
  )
}


export default RevisionMeta
