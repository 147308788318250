import type z from 'zod'
import * as types from './types'
import * as schemas from './index'


export const company = types.object({
  name: types.requiredString(),
  companyNumber: types.requiredString(),
  address: types.requiredString(),
  city: types.requiredString(),
  postCode: types.requiredString(),
  phone: types.requiredString().nullable().optional(),
  email: types.requiredString().nullable().optional(),
  notes: types.text().optional(),
  carrierCertificate: types.requiredString().nullable().optional(),
  carrierValidUntil: types.timestamp().nullable().optional(),
  statutories: types.array(types.object({
    name: types.requiredString(),
    competent: types.boolean().optional(),
  })).min(1),
  revisionNote: schemas.text().optional(),
})
export type Company = z.infer<typeof company>
