import type z from 'zod'
import * as types from './types'
import * as schemas from './index'


export const sidingCancellation = types.object({
  cancelledAt: types.timestamp(),
  referenceNumber: types.requiredString(),
  ginisPid: types.requiredString(),
  cancelledPart: types.requiredString().optional().nullable(),

  removalReferenceNumber: types.optionalString().optional(),
  removalGinisPid: types.requiredString().optional().nullable(),
  removedAt: types.timestamp().optional().nullable(),

  sidingId: types.id(),
  revisionNote: schemas.text().optional(),
})
export type SidingCancellation = z.infer<typeof sidingCancellation>
