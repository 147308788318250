import LaunchIcon from '@mui/icons-material/Launch'
import {Link, Stack} from '@mui/material'
import type {ComponentProps, FC, ReactNode} from 'react'


type ExternalLinkProps = {
  link: string
  linkText?: ReactNode
}

export const ExternalLink: FC<ExternalLinkProps> = ({link, linkText}) => {
  return (
    <Link
        underline="always"
        display="flex"
        alignItems="center"
        href={link}
        target="_blank"
    >
      {linkText || link}&nbsp;<LaunchIcon fontSize="inherit" />
    </Link>
  )
}
type ExternalLinkFieldProps = ComponentProps<typeof ExternalLink> & {
  label: ReactNode
}

const ExternalLinkField: FC<ExternalLinkFieldProps> = ({label, link, linkText}) => {
  return (
    <Stack direction={{xs: 'column', md: 'row'}} justifyContent="space-between" spacing={1}>
      <span>
        {label}
      </span>
      <ExternalLink link={link} linkText={linkText} />
    </Stack>
  )
}

export default ExternalLinkField
