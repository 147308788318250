import {useQuery} from '@tanstack/react-query'
import {If} from 'babel-plugin-jsx-control-statements'
import type {GetPermitResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import {EDIT_PERMITS} from 'constants/permissions'
import * as resources from 'constants/resources'
import {omit} from 'lodash-es'
import type {ComponentProps} from 'react'
import {useNavigate} from 'react-router'
import * as clientRoutes from '../../../constants/routes'
import {createOneQuery, useUpdateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import useParams from '../../../hooks/useParams'
import usePermitted from '../../../hooks/usePermitted'
import useQueryParams from '../../../hooks/useQueryParams'
import AsymetricLayout from '../../containers/AsymetricLayout'
import Breadcrumbs from '../../containers/Breadcrumbs'
import ConfirmModal from '../../containers/ConfirmModal'
import FormBlocker from '../../containers/FormBlocker'
import Revisions from '../../containers/Revisions'
import FormErrorAlert from '../../containers/alerts/FormErrorAlert'
import ReadOnlyAlert from '../../containers/alerts/ReadOnlyAlert'
import TextInput from '../../form/TextInput'
import {ScreenHeader, ScreenWrapper} from '../../visual'
import NotFound from '../NotFound'
import PermitsForm from './PermitsForm'
import PermitRevisionDetail from './PermitsRevisionDetail'


const schema = {
  revisionId: schemas.nonNegativeInteger().optional(),
}

export const queryDef = ({permitId}: {permitId: number}) => createOneQuery<GetPermitResponseSchema>({
  resource: resources.PERMITS,
  id: permitId,
})

const PermitsEdit = () => {
  const canEdit = usePermitted([EDIT_PERMITS])
  const {permitId} = useParams()
  const [query, setQuery] = useQueryParams(schema)
  const navigate = useNavigate()
  const showAlert = useAlert()
  const {data} = useQuery({...queryDef({permitId: Number(permitId)}), enabled: Boolean(Number(permitId))})
  const updatePermits = useUpdateResource({resource: resources.PERMITS, id: permitId})
  const permit = data?.data


  if (!permit) return <NotFound />

  const hadndleUpdatePermits: ComponentProps<typeof PermitsForm>['onSubmit'] = async (values) => {
    await updatePermits.mutateAsync({
      notes: values.notes || '',
      type: values.type,
      validFrom: values.validFrom || null,
      sidingId: values.sidingId,
      operatorId: values.operatorId,
      cancelledAt: values.cancelledAt || null,
      cancelledRef: values.cancelledRef || '',
      cancelledReason: values.cancelledReason || null,
      cancelledGinisPid: values.cancelledGinisPid || null,
      revisionNote: values.revisionNote || '',
    })
    navigate(clientRoutes.PERMITS)
    showAlert(`Úřední povolení ${permitId} uloženo`, 'success')
  }

  return (
    <PermitsForm
        initialValues={permit}
        onSubmit={hadndleUpdatePermits}
        innerProps={{permit, readOnly: !canEdit}}
    >
      {({form, initialValues, valid, handleSubmit}) => (
        <ScreenWrapper>
          <FormBlocker />
          <ScreenHeader
              title={`Úřední povolení ${permit.id}`}
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item>Evidence vleček</Breadcrumbs.Item>
                  <Breadcrumbs.Item to={clientRoutes.PERMITS}>Úřední povolení</Breadcrumbs.Item>
                  <Breadcrumbs.Item active>Úřední povolení {permit.id}</Breadcrumbs.Item>
                </Breadcrumbs>
              }
          >
            <If condition={canEdit}>
              <ConfirmModal
                  title="Přejete si uložit změnu údajů?"
                  valid={valid}
                  onConfirm={handleSubmit}
              >
                <p>
                  Chystáte se uložit veškeré změny, které byly na kartě Úřední povolení provedeny.
                  Detail změn si budete moci zobrazit v historii úředního povolení.
                  Veškeré údaje lze kdykoli opět přepsat.
                </p>
                <TextInput name="revisionNote" label="Zpráva" multiline />
              </ConfirmModal>
            </If>
          </ScreenHeader>
          <FormErrorAlert />
          <If condition={!canEdit}>
            <ReadOnlyAlert />
          </If>
          <AsymetricLayout>
            <AsymetricLayout.Items>
              {form}
            </AsymetricLayout.Items>
            <AsymetricLayout.Items>
              <Revisions
                  resource={resources.PERMIT_REVISIONS}
                  query={{permitId: 'id' in initialValues && Number(initialValues?.id)}}
                  onRevisionClick={(revisionId) => setQuery((prevQuery) => ({...prevQuery, revisionId}))}
              />
              <PermitRevisionDetail revisionId={query.revisionId || null} onClose={() => setQuery((prevQuery) => omit(prevQuery, 'revisionId'))} />
            </AsymetricLayout.Items>
          </AsymetricLayout>
        </ScreenWrapper>
      )}
    </PermitsForm>
  )
}

export default PermitsEdit
