import {Alert} from '@mui/material'


const ReadOnlyAlert = () => {
  return (
    <Alert>
      Nemáte dostačující oprávnění k úpravě této agendy
    </Alert>
  )
}

export default ReadOnlyAlert
