import {Typography} from '@mui/material'
import type {FC, ReactNode} from 'react'


type FallbackProps = {
  children?: ReactNode
}

const Fallback: FC<FallbackProps> = ({children}) => {
  return (
    <Typography variant="caption" fontStyle="italic" color="text.disabled">{children}</Typography>
  )
}

export default Fallback
