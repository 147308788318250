import {Typography} from '@mui/material'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'
import type {FC, ComponentProps} from 'react'
import Link from './Link'


type ItemProps = (ComponentProps<typeof Link> | ComponentProps<typeof Typography>) & {
  active?: boolean,
}

const Item: FC<ItemProps> = ({active, ...props}) => {
  if ('to' in props) {
    return (
      <Link color={active ? 'text.secondary' : 'text.primary'} {...props} to={props.to} />
    )
  }

  return (
    <Typography color={active ? 'text.secondary' : 'text.primary'} {...props} />
  )
}

type BreadcrumbsProps = ComponentProps<typeof MuiBreadcrumbs>

const Breadcrumbs: FC<BreadcrumbsProps> & {Item: typeof Item} = ({children, ...props}) => {
  return (
    <MuiBreadcrumbs {...props}>
      {children}
    </MuiBreadcrumbs>
  )
}

Breadcrumbs.Item = Item

export default Breadcrumbs
