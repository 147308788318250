import {Chip} from '@mui/material'
import {createColumnHelper} from '@tanstack/react-table'
import type {ListLicenseResponseSchema} from 'backend/src/controllers/licenses/ListLicense'
import * as enums from 'common/labels/enums'
import * as resources from 'constants/resources'
import type {ComponentProps, Dispatch, FC, SetStateAction} from 'react'
import {useNavigate} from 'react-router'
import * as clientRoutes from '../../../constants/routes'
import generatePath from '../../../utils/generatePath'
import {getSortableColumns} from '../../../utils/lists'
import ControlledDatagrid from '../../containers/Datagrid/ControlledDatagrid'
import dateCell from '../../containers/Datagrid/cells/dateCell'
import enumCell from '../../containers/Datagrid/cells/enumCell'
import relationalCell from '../../containers/Datagrid/cells/relationalCell'
import {isLicenseValid} from '../../form/forms'


const columnHelper = createColumnHelper<ListLicenseResponseSchema[number]>()

const columns = [
  columnHelper.accessor('id', {header: 'ID'}),
  columnHelper.accessor('company.name', {
    id: 'company.name',
    header: 'Dopravce',
    cell: relationalCell({resource: resources.COMPANIES, id: 'carrierId'}),
  }),
  columnHelper.accessor('transportType', {header: 'Typ dopravy', cell: enumCell({enumName: enums.TRANSPORT_TYPES})}),
  columnHelper.accessor('transportCategory', {header: 'Kategorie dopravy', cell: enumCell({enumName: enums.TRANSPORT_CATEGORIES})}),
  columnHelper.accessor('trackType', {header: 'Kategorie dráhy', cell: enumCell({enumName: enums.TRACK_TYPES})}),
  columnHelper.accessor('validFrom', {header: 'Platnost od', cell: dateCell({fallback: 'Neuvedeno'})}),
  columnHelper.accessor('cancelledAt', {header: 'Platnost do', cell: dateCell({fallback: 'Neurčito'})}),
  columnHelper.display({
    id: 'validity',
    header: 'Platnost',
    cell: ({row}) => {
      if (isLicenseValid(row.original)) return <Chip color="error" label="Neplatná" />
      return <Chip color="success" label="Platná" />
    },
  }),
]

export const sortableColumns = getSortableColumns(columns)

type Query = ComponentProps<typeof ControlledDatagrid>['query']

type LicensesTableProps = {
  licenses: ListLicenseResponseSchema
  total: number | null
  query: Query
  setQuery: Dispatch<SetStateAction<Query>>
}

const LicensesTable: FC<LicensesTableProps> = ({
  licenses, total, query, setQuery, ...props
}) => {
  const navigate = useNavigate()
  return (
    <ControlledDatagrid<ListLicenseResponseSchema[number]>
        data={licenses}
        columns={columns}
        total={total}
        query={query}
        setQuery={setQuery}
        onRowClick={(row) => navigate(
          generatePath(clientRoutes.LICENSES_EDIT, {licenseId: row.id}),
        )}
        {...props}
    />
  )
}

export default LicensesTable
