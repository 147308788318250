import AddCircleIcon from '@mui/icons-material/AddCircle'
import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import {If} from 'babel-plugin-jsx-control-statements'
import {MANAGE_USERS} from 'constants/permissions'
import type {ComponentProps, FC} from 'react'
import {useState} from 'react'
import * as resources from '../../../../../constants/resources'
import {useCreateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import usePermitted from '../../../hooks/usePermitted'
import Button from '../../containers/Button'
import {Modal, ScreenWrapper} from '../../visual'
import RolesForm from './RolesForm'


type RolesCreateProps = {
  refetch?: () => void
}


const RolesCreate: FC<RolesCreateProps> = ({refetch}) => {
  const canCreate = usePermitted([MANAGE_USERS])
  const createRole = useCreateResource({resource: resources.ROLES})
  const [open, setOpen] = useState(false)
  const showAlert = useAlert()

  if (!canCreate) return null

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit: ComponentProps<typeof RolesForm>['onSubmit'] = async (values) => {
    const {data} = await createRole.mutateAsync(values)
    showAlert(`Role ${data.id} vytvořena`, 'success')
    if (refetch) refetch()
    handleClose()
  }

  return (
    <ScreenWrapper>
      <Button
          icon={<AddCircleIcon />}
          onClick={handleOpen}
          variant="contained"
          data-test-id="createButton"
      >
        Přidat novou roli
      </Button>
      <If condition={open}>
        <RolesForm onSubmit={handleSubmit}>
          {({submitting, form, handleSubmit}) => (
            <Modal open onClose={handleClose}>
              <Modal.Title>Vytvořit roli</Modal.Title>
              <Modal.Content>
                {form}
              </Modal.Content>
              <Modal.Actions>
                <Button icon={<CloseIcon />} onClick={handleClose}>Zavřít</Button>
                <Button icon={<SaveIcon />} variant="contained" disabled={submitting} onClick={handleSubmit}>
                  Uložit
                </Button>
              </Modal.Actions>
            </Modal>
          )}
        </RolesForm>
      </If>
    </ScreenWrapper>
  )
}

export default RolesCreate
