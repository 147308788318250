import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import type {Getter} from '@tanstack/react-table'
import {createColumnHelper} from '@tanstack/react-table'
import type {ListCompanyResponseSchema} from 'backend/src/controllers/companies/ListCompany'
import {filter, isEmpty} from 'lodash'
import type {ComponentProps, Dispatch, FC, ReactNode, SetStateAction} from 'react'
import {useNavigate} from 'react-router'
import * as clientRoutes from '../../../constants/routes'
import generatePath from '../../../utils/generatePath'
import {getSortableColumns} from '../../../utils/lists'
import ControlledDatagrid from '../../containers/Datagrid/ControlledDatagrid'
import Fallback from '../../containers/Datagrid/cells/Fallback'
import dateCell from '../../containers/Datagrid/cells/dateCell'


const carrierCell = ({fallback = '–'}: {fallback: ReactNode}) => {
  const renderCell = <TValue extends {competent: boolean, name: string}>(
    {getValue}: {getValue: Getter<TValue[]>},
  ) => {
    const value = getValue()
    const competentStatutories = filter(value, 'competent')

    if (isEmpty(competentStatutories)) return <Fallback>{fallback}</Fallback>
    return competentStatutories.map((statutory) => statutory.name).join(', ')
  }
  return renderCell
}

const columnHelper = createColumnHelper<ListCompanyResponseSchema[number]>()

const columns = [
  columnHelper.accessor('id', {header: 'ID'}),
  columnHelper.accessor('name', {header: 'Obchodní jméno'}),
  columnHelper.accessor('companyNumber', {header: 'IČ'}),
  columnHelper.accessor('statutories', {
    header: 'OOZ',
    cell: carrierCell({fallback: 'Neuvedeno'}),
    enableSorting: false,
  }),
  columnHelper.accessor('carrierCertificate', {
    header: 'Osvědčení dopravce',
    cell: ({getValue}) => {
      const certificate = getValue()
      if (certificate) return <CheckCircleIcon color="success" />
      return <CancelIcon color="error" />
    },
  }),
  columnHelper.accessor('carrierValidUntil', {header: 'Platnost do', cell: dateCell({})}),
]

export const sortableColumns = getSortableColumns(columns)

type Query = ComponentProps<typeof ControlledDatagrid>['query']

type CompaniesTableProps = {
  companies: ListCompanyResponseSchema
  total: number | null
  query: Query
  setQuery: Dispatch<SetStateAction<Query>>
}

const CompaniesTable: FC<CompaniesTableProps> = ({
  companies, total, query, setQuery, ...props
}) => {
  const navigate = useNavigate()
  return (
    <ControlledDatagrid<ListCompanyResponseSchema[number]>
        data={companies}
        columns={columns}
        total={total}
        query={query}
        setQuery={setQuery}
        onRowClick={(row) => navigate(
          generatePath(clientRoutes.COMPANIES_EDIT, {companyId: row.id}),
        )}
        {...props}
    />
  )
}

export default CompaniesTable
