import {useQueryErrorResetBoundary} from '@tanstack/react-query'
import type {LoginResponseSchema} from 'common/responses'
import type * as schemas from 'common/schemas'
import {AUTHENTICATION_ERROR} from 'constants/errorCodes'
import * as routes from 'constants/routes'
import {isAfter} from 'date-fns'
import invariant from 'invariant'
import {noop} from 'lodash-es'
import type {FC, ReactNode} from 'react'
import {useEffect, useContext, useCallback, Component, useMemo, useState, createContext} from 'react'
import SplashScreen from '../components/screens/SplashScreen'
import type {FrontendError} from '../utils/api'
import {api} from '../utils/api'
import * as storage from '../utils/storage'
import {useAlert} from './useAlert'
import useIsMounted from './useIsMounted'


export type Session = LoginResponseSchema

interface AuthContextContext {
  refreshAuth: () => Promise<void>,
  login: (data: schemas.auth.Login) => Promise<Session | Error>
  logout: () => Promise<void>
}

const AuthContext = createContext<AuthContextContext | null>(null)
const SessionContext = createContext<Session | null>(null)
const STORAGE_KEY = 'session'

/**
 * Tries to copy session to sessionStorage if opened in different tab.
 */
const initSession = () => {
  const session = storage.get<Session>(window.localStorage, STORAGE_KEY)
  if (!session) return storage.set(window.sessionStorage, STORAGE_KEY, null)
  if (!session.validUntil || storage.get<Session>(window.sessionStorage, STORAGE_KEY)) return

  storage.set(window.sessionStorage, STORAGE_KEY, session)
}

export const readSession = (): Session | null => {
  const session = storage.get<Session>(window.localStorage, STORAGE_KEY)
  if (!session) return null

  const validUntil = session.validUntil === null ? null : new Date(session.validUntil)
  if (validUntil && !storage.get(window.sessionStorage, STORAGE_KEY)) return null
  if (validUntil && !isAfter(validUntil, new Date())) return null

  return session
}

const removeSession = () => {
  storage.set(window.localStorage, STORAGE_KEY, null)
  storage.set(window.sessionStorage, STORAGE_KEY, null)
}

const writeSession = (session: Session | null) => {
  if (!session) return removeSession()

  storage.set(window.localStorage, STORAGE_KEY, session)
  if (session.validUntil) storage.set(window.sessionStorage, STORAGE_KEY, session)
}

type ErrorBoundaryProps = {
  onError: () => void
  children: ReactNode
}

class ErrorBoundary extends Component<ErrorBoundaryProps> {
  componentDidCatch(error: FrontendError) {
    if (error?.data?.errorCode === AUTHENTICATION_ERROR) {
      this.props.onError()
      return
    }
    throw error
  }

  render() {
    return this.props.children
  }
}

type AuthProviderProps = {
  children: ReactNode
}

export const AuthProvider: FC<AuthProviderProps> = ({children}) => {
  const [isInitialized, setInitialized] = useState(false)
  const [session, setSession] = useState<Session | null>(null)
  const showAlert = useAlert()
  const isMounted = useIsMounted()
  const {reset} = useQueryErrorResetBoundary()

  const handleError = () => {
    showAlert('Byl jste odhlášen', 'error')
    writeSession(null)
    if (isMounted.current) setSession(null)
    reset()
  }

  const login: AuthContextContext['login'] = useCallback(async (data) => {
    let newSession: Session | null
    try {
      newSession = (await api<LoginResponseSchema>('POST', routes.API_LOGIN, {data})).data
      writeSession(newSession)
      if (isMounted.current) setSession(newSession)
      return newSession
    } catch (e: unknown) {
      newSession = null
      writeSession(newSession)
      if (isMounted.current) setSession(newSession)
      return e as Error
    }
  }, [isMounted])

  const logout: AuthContextContext['logout'] = useCallback(async () => {
    if (session) {
      try {
        await api('POST', routes.API_LOGOUT, {sessionToken: session.token})
      } catch (e) {
        window.console.error(e)
      }
    }
    writeSession(null)
    if (isMounted.current) setSession(null)
  }, [isMounted, session])

  const refreshAuth: AuthContextContext['refreshAuth'] = useCallback(async () => {
    const currentSession = readSession()
    let newSession: Session | null = null
    if (currentSession) {
      try {
        newSession = (await api<Session>(
          'POST', routes.API_LOGIN, {sessionToken: currentSession.token},
        )).data
      } catch (e) {
        newSession = null
      }
    }

    writeSession(newSession)
    if (isMounted.current) setSession(newSession)
    if (isMounted.current) setInitialized(true)
  }, [isMounted])

  useEffect(() => {
    initSession()
    refreshAuth().catch(noop)
  }, [refreshAuth])

  const utils = useMemo(() => ({
    refreshAuth,
    login,
    logout,
  }), [refreshAuth, login, logout])

  if (!isInitialized) return <SplashScreen />

  return (
    <>
      <AuthContext.Provider value={utils}>
        <SessionContext.Provider value={session}>
          <ErrorBoundary onError={handleError}>
            {children}
          </ErrorBoundary>
        </SessionContext.Provider>
      </AuthContext.Provider>
    </>
  )
}

export const useAuth = () => {
  const authUtils = useContext(AuthContext)
  invariant(authUtils, 'useAuth must be used within AuthProvider')
  return authUtils
}

export const useSession = () => {
  const session = useContext(SessionContext)
  return session
}
