import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import RefreshIcon from '@mui/icons-material/Refresh'
import SettingsIcon from '@mui/icons-material/Settings'
import {MenuItem, IconButton} from '@mui/material'
import {useIsFetching, useQueryClient} from '@tanstack/react-query'
import type {FC, ReactNode} from 'react'
import {useMemo} from 'react'
import LogoDuf from '../../assets/logo-white.svg'
import * as clientRoutes from '../../constants/routes'
import {useAuth} from '../../hooks/auth'
import useResourceMutationSubscription from '../../hooks/useResourceMutationSubscription'
import {AppBar, Brand, ButtonMenu} from '../visual'
import Link from './Link'
import LinkBehavior from './LinkBehaviour'


type AppShellProps = {
  onMenuClick: () => void,
  openMenu: boolean,
  children: ReactNode,
}


const AppShell: FC<AppShellProps> = ({onMenuClick, openMenu, children}) => {
  const {logout, refreshAuth} = useAuth()
  const isFetching = useIsFetching()
  const queryClient = useQueryClient()
  useResourceMutationSubscription()

  const handleRefresh = async () => {
    await refreshAuth()
    await queryClient.invalidateQueries({
      type: 'all',
      refetchType: 'active',
    })
  }

  const memoizedChildren = useMemo(() => children, [openMenu]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <AppBar
          isFetching={Boolean(isFetching)}
          onMenuClick={onMenuClick}
          brand={<Link to="/"><Brand src={LogoDuf} alt="Logo Drážního úřadu" /></Link>}
          title={<strong>Evidence vleček Drážního úřadu</strong>}
      >
        <IconButton onClick={handleRefresh}>
          <RefreshIcon />
        </IconButton>
        <ButtonMenu icon={<AccountCircleIcon />}>
          <MenuItem component={LinkBehavior} href={clientRoutes.PROFILE}>
            <SettingsIcon />
            Profil
          </MenuItem>
          <MenuItem onClick={logout}>
            <PowerSettingsNewIcon />
            Odhlásit
          </MenuItem>
        </ButtonMenu>
      </AppBar>
      {memoizedChildren}
    </>
  )
}

export default AppShell
