import * as licenseNSATypes from 'constants/licenseNSATypes'


export default {
  [licenseNSATypes.BELGIUM]: 'Belgie',
  [licenseNSATypes.BULGARIA]: 'Bulharsko',
  [licenseNSATypes.CZECH_REPUBLIC]: 'Česká republika',
  [licenseNSATypes.DENMARK]: 'Dánsko',
  [licenseNSATypes.ESTONIA]: 'Estonsko',
  [licenseNSATypes.FINLAND]: 'Finsko',
  [licenseNSATypes.FRANCE]: 'Francie',
  [licenseNSATypes.CHANNEL_TUNNEL]: 'Channel Tunnel',
  [licenseNSATypes.CROATIA]: 'Chorvatsko',
  [licenseNSATypes.IRELAND]: 'Irsko',
  [licenseNSATypes.ITALY]: 'Itálie',
  [licenseNSATypes.LITHUANIA]: 'Litva',
  [licenseNSATypes.LATVIA]: 'Lotyšsko',
  [licenseNSATypes.LUXEMBOURG]: 'Lucembursko',
  [licenseNSATypes.HUNGARY]: 'Maďarsko',
  [licenseNSATypes.GERMANY]: 'Spolková republika Německo',
  [licenseNSATypes.NETHERLANDS]: 'Nizozemí',
  [licenseNSATypes.NORWAY]: 'Norsko',
  [licenseNSATypes.POLAND]: 'Polsko',
  [licenseNSATypes.PORTUGAL]: 'Potugalsko',
  [licenseNSATypes.AUSTRIA]: 'Rakousko',
  [licenseNSATypes.ROMANIA]: 'Rumunsko',
  [licenseNSATypes.GREECE]: 'Řecko',
  [licenseNSATypes.SLOVAKIA]: 'Slovensko',
  [licenseNSATypes.SLOVENIA]: 'Slovinsko',
  [licenseNSATypes.SPAIN]: 'Španělsko',
  [licenseNSATypes.SWEDEN]: 'Švédsko',
  [licenseNSATypes.SWITZERLAND]: 'Švýcarsko',
}
