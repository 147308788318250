import {useFormState} from 'react-final-form'
import {unstable_useBlocker as useBlocker} from 'react-router-dom'
import {Modal} from '../visual'
import Button from './Button'


const FormBlocker = () => {
  const {dirty, submitSucceeded, submitting} = useFormState()
  const blocker = useBlocker(dirty && !submitSucceeded && !submitting)

  return (
    <>
      <Modal open={blocker.state === 'blocked'} onClose={blocker.reset}>
        <Modal.Title>Ve formuláři jsou neuložená data</Modal.Title>
        <Modal.Content>
          Opuštěním přijdete o všechna neuložená data. Opravdu chcete opustit stránku?
        </Modal.Content>
        <Modal.Actions>
          <Button variant="contained" color="error" onClick={blocker.proceed}>Opustit</Button>
          <Button color="neutral" onClick={blocker.reset}>Zůstat</Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}


export default FormBlocker
