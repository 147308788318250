import * as resources from 'constants/resources'
import type {Resource} from 'constants/resources'


const invalidationMap = {
  [resources.COMPANIES]: [resources.COMPANY_REVISIONS, resources.LICENSES, resources.PERMITS, resources.SIDINGS],
  [resources.FOREIGN_CARRIERS]: [resources.FOREIGN_CARRIER_REVISIONS],
  [resources.LICENSES]: [resources.LICENSE_REVISIONS, resources.SIDINGS],
  [resources.PERMITS]: [resources.PERMIT_REVISIONS, resources.SIDINGS],
  [resources.ROLES]: [resources.USERS],
  [resources.SIDINGS]: [
    resources.SIDING_REVISIONS,
    resources.PERMITS,
    resources.SUPERVISIONS,
    resources.SIDING_CANCELLATIONS,
  ],
  [resources.SUPERVISIONS]: [resources.SIDINGS],
  [resources.USERS]: [resources.SUPERVISIONS, resources.SIDINGS],
  [resources.SIDING_CANCELLATIONS]: [
    resources.SIDINGS,
    resources.SIDING_CANCELLATION_REVISIONS,
    resources.SIDING_REVISIONS,
  ],

  [resources.SIDING_REVISIONS]: [resources.SIDINGS],
  [resources.COMPANY_REVISIONS]: [resources.COMPANIES],
  [resources.PERMIT_REVISIONS]: [resources.PERMITS],
  [resources.LICENSE_REVISIONS]: [resources.LICENSES],
  [resources.FOREIGN_CARRIER_REVISIONS]: [resources.FOREIGN_CARRIERS],
  [resources.SIDING_CANCELLATION_REVISIONS]: [resources.SIDING_CANCELLATIONS],
} as const satisfies {[r in Resource]: readonly Resource[]}

export default invalidationMap
