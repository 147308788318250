import * as permitCancellationReasons from 'constants/permitCancellationReasons'


export default {
  [permitCancellationReasons.REASON1]: 'Změna provozovatele dráhy',
  [permitCancellationReasons.REASON2]: 'Ztráta právního vztahu k dráze',
  [permitCancellationReasons.REASON3]: 'Žádost provozovatele dráhy',
  [permitCancellationReasons.REASON4]: 'Zaniklo zrušením dráhy',
  [permitCancellationReasons.REASON5]: 'Zánik provozovatele dráhy',
  [permitCancellationReasons.REASON6]: 'Změna kategorie dráhy',
  [permitCancellationReasons.REASON7]: 'Nesplnění podmínek pro vydání ÚP',
  [permitCancellationReasons.REASON8]: 'ÚP nevydáno',
  [permitCancellationReasons.REASON9]: 'Jiné důvody',
  [permitCancellationReasons.REASON10]: 'Úmrtí provozovatele',
  [permitCancellationReasons.REASON11]: 'Porušení ustanovení zákona',
} as const
