import {Drawer as MuiDrawer} from '@mui/material'
import {styled} from '@mui/material/styles'
import type {ComponentProps, FC} from 'react'


const drawerWidth = '270px'

type DrawerProps = ComponentProps<typeof MuiDrawer>

const StyledMuiDrawer = styled(MuiDrawer)<DrawerProps>(({theme, open}) => ({
  'width': drawerWidth,
  'flexShrink': 0,
  'whiteSpace': 'nowrap',
  'background': `linear-gradient(to right,
    ${theme.palette.primary.light} 57px,
    ${theme.palette.primary.dark} 20px);`,
  '& span': {
    opacity: 1,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: 150,
    }),
    [theme.breakpoints.down('md')]: {
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: 250,
      }),
    },
  },
  '& .MuiPaper-root': {
    position: 'relative',
    marginTop: '57px',
    height: 'calc(100vh - 57px)',
    background: `linear-gradient(to right,
      ${theme.palette.primary.light} 57px,
      ${theme.palette.primary.dark} 20px);`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [theme.breakpoints.down('md')]: {
    pointerEvents: open ? 'all' : 'none',
    position: 'fixed',
    zIndex: '100',
  },
  ...(open ? {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  } : {
    'transition': theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    'overflowX': 'hidden',
    'width': 0,
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(7),
    },
    '& span': {
      opacity: 0,
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: 400,
      }),
    },
    '& .MuiPaper-root': {
      position: 'relative',
      marginTop: '57px',
      height: 'calc(100vh - 57px)',
      background: `linear-gradient(to right,
        ${theme.palette.primary.light} 57px,
        ${theme.palette.primary.dark} 20px);`,
      [theme.breakpoints.up('md')]: {
        width: theme.spacing(7),
      },
    },
  }),
}))

const Drawer: FC<DrawerProps> = ({open, children, ...props}) => {
  return (
    <StyledMuiDrawer variant="permanent" open={open} {...props}>
      {children}
    </StyledMuiDrawer>
  )
}

export default Drawer
