import {ListItemButton, ListItemIcon, ListItemText} from '@mui/material'
import {styled} from '@mui/material/styles'
import type {ComponentProps, FC, ForwardRefExoticComponent, ReactNode} from 'react'


type NestedNavigationItemProps = ComponentProps<typeof ListItemButton> & {
  nested?: boolean,
}

const StyledListItem = styled(ListItemButton)<NestedNavigationItemProps>(({theme, selected}) => ({
  'width': '100%',
  'maxWidth': 360,
  'color': theme.palette.common.white,
  'textDecoration': 'none',
  'backgroundColor': 'rgba(255, 255, 255, 0.03)',

  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
  },

  ...(selected && {
    'backgroundColor': 'rgba(255, 255, 255, 0.08) !important',

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.12) !important',
    },

    '& span': {
      fontWeight: 'bold',
    },
  }),
}))

const StyledListItemIcon = styled(ListItemIcon)<NestedNavigationItemProps>(({theme}) => ({
  color: theme.palette.common.white,
}))


type NavigationItemProps = ComponentProps<typeof ListItemButton> & {
  icon?: ReactNode,
  component?: ForwardRefExoticComponent<React.RefAttributes<HTMLAnchorElement>> | string,
  href?: string,
  to?: string,
}

const NestedNavigationItem: FC<NavigationItemProps> = ({selected, icon, children, ...props}) => {
  return (
    <StyledListItem selected={selected} {...props}>
      <StyledListItemIcon>
        {icon}
      </StyledListItemIcon>
      <ListItemText>
        {children}
      </ListItemText>
    </StyledListItem>
  )
}

export default NestedNavigationItem
