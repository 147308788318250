import CloseIcon from '@mui/icons-material/Close'
import {Alert, IconButton} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import type {RowSelectionState} from '@tanstack/react-table'
import {If} from 'babel-plugin-jsx-control-statements'
import * as enums from 'common/labels/enums'
import type {ListRoleResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import {REFERENCE_ERROR} from 'constants/errorCodes'
import {ALL_PERMISSIONS} from 'constants/permissions'
import * as resources from 'constants/resources'
import {omit, keys} from 'lodash'
import {useState} from 'react'
import type {TypeOf, ZodObject} from 'zod'
import * as clientRoutes from '../../../constants/routes'
import {createListQuery, useDeleteManyResources} from '../../../hooks/api'
import useQueryParams from '../../../hooks/useQueryParams'
import {isFrontendError} from '../../../utils/api'
import {queryToSort} from '../../../utils/lists'
import Breadcrumbs from '../../containers/Breadcrumbs'
import DeleteButton from '../../containers/DeleteButton'
import Filters from '../../containers/Filters'
import EnumInput from '../../form/EnumInput'
import SearchBoxInput from '../../form/SearchBoxInput'
import {ScreenHeader, ScreenWrapper} from '../../visual'
import NotFound from '../NotFound'
import RolesCreate from './RolesCreate'
import RolesEdit from './RolesEdit'
import RolesTable, {sortableColumns} from './RolesTable'


const schema = {
  page: schemas.nonNegativeInteger().default(1),
  pageSize: schemas.positiveInteger().default(10).catch(10),
  sortBy: schemas.createEnum(sortableColumns).default('id').catch('id'),
  sortDirection: schemas.createEnum(['asc', 'desc']).default('desc').catch('desc'),
  roleId: schemas.nonNegativeInteger().optional(),
  filter: schemas.optionalObject({
    q: schemas.requiredString().optional(),
    permission: schemas.oneOrMany(schemas.createEnum(ALL_PERMISSIONS)).optional(),
  }).optional().catch({}),
}

const queryDef = ({query}: {query: TypeOf<ZodObject<typeof schema>>}) => createListQuery<ListRoleResponseSchema>({
  resource: resources.ROLES,
  query: {
    ...queryToSort(query),
    page: query.page - 1,
    pageSize: query.pageSize,
    filter: {
      ...query?.filter,
      deleted: false,
    },
  },
})

const RolesList = () => {
  const [query, setQuery] = useQueryParams(schema)
  const [error, setError] = useState('')
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const {data, refetch} = useQuery(queryDef({query}))
  const deleteRoles = useDeleteManyResources({resource: resources.ROLES})
  const roles = data?.data

  if (!roles) return <NotFound />

  const handleCloseRole = () => {
    setQuery((prevQuery) => omit(prevQuery, 'roleId'))
  }

  const handleDelete = async () => {
    try {
      await deleteRoles.mutateAsync(keys(rowSelection))
      setRowSelection({})
    } catch (e: unknown) {
      if (isFrontendError(e) && e.data.errorCode === REFERENCE_ERROR) return setError('Některé záznamy nebylo možné vymazat kvůli existující referenci')
      return null
    }
  }

  return (
    <ScreenWrapper>
      <ScreenHeader
          title="Role"
          breadcrumbs={
            <Breadcrumbs>
              <Breadcrumbs.Item>Administrace</Breadcrumbs.Item>
              <Breadcrumbs.Item to={clientRoutes.USERS}>Uživatelé</Breadcrumbs.Item>
              <Breadcrumbs.Item active to={clientRoutes.ROLES}>Role</Breadcrumbs.Item>
            </Breadcrumbs>
          }
      >
        <If condition={error}>
          <Alert 
              action={
                <IconButton onClick={() => setError('')}>
                  <CloseIcon />
                </IconButton>
              }
              severity="error"
          >
            {error}
          </Alert>
        </If>
        <DeleteButton onDelete={handleDelete} rowSelection={rowSelection} />
        <RolesCreate refetch={refetch} />
      </ScreenHeader>

      <Filters query={query} setQuery={setQuery}>
        {({handleSubmit, handleReset}) => (
          <>
            <SearchBoxInput name="q" label="Hledat mezi rolemi" placeholder="Vyhledávání potvrďte Enterem" onApply={handleSubmit} />
            <Filters.Drawer alwaysVisibleFilters={['q']} query={query} onApply={handleSubmit} onReset={handleReset}>
              <EnumInput name="permission" label="Oprávnění" enumName={enums.PERMISSIONS} size="small" fullWidth={false} multiple />
            </Filters.Drawer>
          </>
        )}
      </Filters>

      <RolesTable
          roles={roles}
          total={data.total}
          query={query}
          setQuery={setQuery}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
      />
      <If condition={query?.roleId}>
        <RolesEdit
            roleId={Number(query.roleId)}
            handleClose={handleCloseRole}
            refetch={refetch}
        />
      </If>
    </ScreenWrapper>
  )
}

export default RolesList
