import {Divider, Typography} from '@mui/material'
import * as enums from 'common/labels/enums'
import type {FC} from 'react'
import DateInput from '../../../form/DateInput'
import EnumInput from '../../../form/EnumInput'
import TextInput from '../../../form/TextInput'
import {InputStack, Tabs} from '../../../visual'


export const GeneralPanelFieldsGeneral = ({readOnly}: {readOnly?: boolean}) => {
  return (
    <>
      <InputStack>
        <TextInput name="name" label="Obchodní jméno" readOnly={readOnly} required />
        <EnumInput name="licenseNSA" label="Vydávající NSA" enumName={enums.LICENSE_NSA_TYPES} readOnly={readOnly} required />
      </InputStack>
      <InputStack>
        <EnumInput name="transportType" label="Typ dopravy" enumName={enums.TRANSPORT_TYPES} readOnly={readOnly} required />
        <EnumInput name="activityType" label="Působnost" enumName={enums.ACTIVITY_TYPES} readOnly={readOnly} required />
      </InputStack>
    </>
  )
}

export const GeneralPanelFieldsCertificate = ({readOnly}: {readOnly?: boolean}) => {
  return (
    <>
      <InputStack>
        <TextInput name="carrierCertificate" label="Evidenční číslo osvědčení" readOnly={readOnly} />
        <EnumInput name="carrierNSA" label="Vydávající orgán osvědčení" enumName={enums.CARRIER_NSA_TYPES} readOnly={readOnly} />
      </InputStack>
      <DateInput name="carrierValidUntil" label="Platnost osvědčení" readOnly={readOnly} />
    </>
  )
}

export const GeneralPanelFieldsNote = ({readOnly}: {readOnly?: boolean}) => {
  return (
    <TextInput name="notes" label="Poznámka" placeholder="Zadejte libovolnou poznámku" minRows={5} multiline readOnly={readOnly} />
  )
}

type GeneralPanelProps = {
  readOnly?: boolean
}

const GeneralPanel: FC<GeneralPanelProps> = ({readOnly}) => {
  return (
    <>
      <Tabs.Section>
        <GeneralPanelFieldsGeneral readOnly={readOnly} />
      </Tabs.Section>
      <Divider />
      <Tabs.Section>
        <Typography fontWeight="bold">Osvědčení dopravce</Typography>
        <GeneralPanelFieldsCertificate readOnly={readOnly} />
      </Tabs.Section>
      <Divider />
      <Tabs.Section>
        <Typography fontWeight="bold">Poznámka</Typography>
        <GeneralPanelFieldsNote readOnly={readOnly} />
      </Tabs.Section>
    </>
  )
}

export const GENERAL_PANEL_LABEL = 'Základní údaje'
export default GeneralPanel
