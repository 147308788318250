import {Divider, Typography} from '@mui/material'
import {If} from 'babel-plugin-jsx-control-statements'
import * as enums from 'common/labels/enums'
import type {GetSidingResponseSchema, ListCompanyResponseSchema, ListLicenseResponseSchema, ListSidingResponseSchema, ListUserResponseSchema} from 'common/responses'
import * as resources from 'constants/resources'
import {reject} from 'lodash'
import type {ComponentProps, FC} from 'react'
import {generatePath} from 'react-router'
import * as clientRoutes from '../../../../constants/routes'
import EnumInput from '../../../form/EnumInput'
import ReferenceInput from '../../../form/ReferenceInput'
import TextInput from '../../../form/TextInput'
import {InputStack, Tabs} from '../../../visual'
import SupervisionDateInput from '../components/SupervisionDateInput'


export const GeneralPanelFieldsGeneral = ({currentSupervisionOperational, readOnly}: {
  currentSupervisionOperational?: ComponentProps<typeof SupervisionDateInput>['supervision']
  readOnly?: boolean
}) => {
  return (
    <>
      <InputStack>
        <TextInput name="name" label="Název vlečky" readOnly={readOnly} required />
        <TextInput name="externalRef" label="Číslo vlečky u SŽ" readOnly={readOnly} />
      </InputStack>
      <InputStack>
        <TextInput name="mouthing" label="Zaústění" readOnly={readOnly} />
        <EnumInput name="trackCategory" label="Kategorie dráhy" enumName={enums.TRACK_CATEGORIES} readOnly={readOnly} required />
      </InputStack>
      <TextInput name="trackStart" label="Začátek dráhy" multiline readOnly={readOnly} />
      <TextInput name="trackEnd" label="Konec dráhy" multiline readOnly={readOnly} />
      <InputStack>
        <TextInput name="trackLength" label="Stavební délka v km" readOnly={readOnly} />
        <ReferenceInput<ListUserResponseSchema>
            name="userId"
            optionText={({firstName, lastName}) => `${firstName} ${lastName}`}
            label="Obvod"
            resource={resources.USERS}
            tagRoute={(user) => generatePath(clientRoutes.USERS_EDIT, {userId: user.value})}
            readOnly={readOnly}
            required
        />
      </InputStack>
      <InputStack>
        <TextInput name="trackExternalKJR" label="Číslo tratě dle KJŘ" readOnly={readOnly} />
        <TextInput name="trackExternalTTP" label="Číslo tratě dle TTP" readOnly={readOnly} />
      </InputStack>
      <If condition={!readOnly}>
        <SupervisionDateInput name="supervisionOperationalTechnical" supervision={currentSupervisionOperational} />
      </If>
    </>
  )
}
export const GeneralPanelFieldsOwnership = ({readOnly}: {readOnly?: boolean}) => {
  return (
    <>
      <ReferenceInput<ListCompanyResponseSchema, true>
          name="ownerIds"
          optionText="name"
          label="Vlastníci vlečky"
          resource={resources.COMPANIES}
          tagRoute={(company) => generatePath(clientRoutes.COMPANIES_EDIT, {companyId: company.value})}
          readOnly={readOnly}
          multiple
          required
      />
      <ReferenceInput<ListCompanyResponseSchema, true>
          name="renterIds"
          optionText="name"
          label="Nájemci vlečky"
          resource={resources.COMPANIES}
          tagRoute={(company) => generatePath(clientRoutes.COMPANIES_EDIT, {companyId: company.value})}
          readOnly={readOnly}
          multiple
      />
    </>
  )
}

export const GeneralPanelFieldsLicense = ({readOnly}: {readOnly?: boolean}) => {
  return (
    <ReferenceInput<ListLicenseResponseSchema, true>
        name="licenseIds"
        optionText={(option) => `${option.id} (${option.company.name})`}
        label="Licence"
        resource={resources.LICENSES}
        tagRoute={(license) => generatePath(clientRoutes.LICENSES_EDIT, {licenseId: license.value})}
        readOnly={readOnly}
        multiple
    />
  )
}

export const GeneralPanelFieldsSidingSiding = ({sidingId, readOnly}: {sidingId?: GetSidingResponseSchema['id'], readOnly?: boolean}) => {
  return (
    <ReferenceInput<ListSidingResponseSchema, true>
        name="sidingSidingIds"
        optionText="name"
        label="Zaústěné vlečky"
        resource={resources.SIDINGS}
        tagRoute={(option) => generatePath(clientRoutes.SIDINGS_EDIT, {sidingId: option.value})}
        filterOptions={(options) => {
          return reject(options, {value: String(sidingId)})
        }}
        readOnly={readOnly}
        multiple
    />
  )
}

export const GeneralPanelFieldsNotes = ({readOnly}: {readOnly?: boolean}) => {
  return (
    <TextInput
        name="notesOperation"
        label="Poznámka sekce provozně-technické"
        placeholder="Zadejte libovolnou poznámku"
        minRows={5}
        readOnly={readOnly}
        multiline
    />
  )
}

type GeneralPanelProps = {
  sidingId?: GetSidingResponseSchema['id']
  currentSupervisionOperational?: ComponentProps<typeof SupervisionDateInput>['supervision']
  readOnly?: boolean
}

const GeneralPanel: FC<GeneralPanelProps> = ({sidingId, currentSupervisionOperational, readOnly}) => {
  return (
    <>
      <Tabs.Section>
        <GeneralPanelFieldsGeneral currentSupervisionOperational={currentSupervisionOperational} readOnly={readOnly} />
      </Tabs.Section>
      <Divider />
      <Tabs.Section>
        <Typography fontWeight="bold">Vlastníci a nájemci</Typography>
        <GeneralPanelFieldsOwnership readOnly={readOnly} />
      </Tabs.Section>
      <Divider />
      <Tabs.Section>
        <Typography fontWeight="bold">Licence</Typography>
        <GeneralPanelFieldsLicense readOnly={readOnly} />
      </Tabs.Section>
      <Divider />
      <Tabs.Section>
        <Typography fontWeight="bold">Zaústěné vlečky</Typography>
        <GeneralPanelFieldsSidingSiding sidingId={sidingId} readOnly={readOnly} />
      </Tabs.Section>
      <Divider />
      <Tabs.Section>
        <Typography fontWeight="bold">Poznámky</Typography>
        <GeneralPanelFieldsNotes readOnly={readOnly} />
      </Tabs.Section>
    </>
  )
}

export const GENERAL_PANEL_LABEL = 'Základní údaje'
export default GeneralPanel
