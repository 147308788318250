import RemoveIcon from '@mui/icons-material/Remove'
import {Chip} from '@mui/material'
import {useQueries} from '@tanstack/react-query'
import {Choose, Otherwise, When} from 'babel-plugin-jsx-control-statements'
import type {JSONValue} from 'common/schemas'
import type {Resource} from 'constants/resources'
import {generateResourcePath} from 'constants/routes'
import {castArray, compact, isArray, isEmpty, isFunction, map, uniqBy} from 'lodash-es'
import type {ComponentProps, FC, ReactNode} from 'react'
import {Fragment, useState} from 'react'
import type {UseFieldConfig} from 'react-final-form'
import {useField} from 'react-final-form'
import {createListQuery} from '../../hooks/api'
import useDebouncedValue from '../../hooks/useDebouncedValue'
import Link from '../containers/Link'
import {Autocomplete, StaticField} from '../visual'
import type {ShowError} from './forms'
import {showErrorOnChange} from './forms'


export const getProperty = <TValue, >(
  option: TValue | undefined,
  optionKey: keyof TValue | ((option: TValue) => string),
) => {
  if (!option) return undefined
  if (isFunction(optionKey)) return optionKey(option)

  return option[optionKey]
}

type QueryDefArgs = {
  resource: Resource
  q?: string | null
  filter?: Record<string, JSONValue>
}

export const queryDef = <TValues, >({resource, q, filter = {}}: QueryDefArgs) => createListQuery<TValues>({
  resource,
  query: {
    filter: {
      deleted: false,
      q: q || undefined,
      ...filter,
    },
    pageSize: 10,
  },
})

type Option = {
  label: string,
  value: string,
}

type ReferenceInputProps<TValue, M extends boolean = false> = Omit<ComponentProps<typeof Autocomplete>, 'multiple'| 'options' | 'value' | 'onChange' | 'defaultValue' > & {
  name: string
  resource: Resource
  optionText: keyof TValue | ((option: TValue) => string)
  optionValue?: keyof TValue | ((option: TValue) => string)
  filter?: Record<string, JSONValue>
  fieldProps?: UseFieldConfig<M extends true ? string[] : string>
  required?: boolean
  disableSpacing?: boolean
  showError?: ShowError
  helperText?: ReactNode
  multiple?: M
  tagRoute?: string | ((option: {value: string, label: string}) => string)
}

const getTagRoute = (route: string | (({value}: {value: string}) => string) | null, option: Option) => {
  if (!route) return null
  if (isFunction(route)) return route(option)
  return generateResourcePath(route, option.value)
}

type TagProps = ComponentProps<typeof Chip> & {
  tagRoute?: string | (({value}: {value: string}) => string)
  option: {value: string, label: string}
  getTagProps?: (params: {index: number}) => void
  multiple?: boolean
}

const Tag: FC<TagProps> = ({tagRoute = null, option, ...props}) => {
  const route = getTagRoute(tagRoute, option)
  return (
    <Chip
        {...props}
        label={
          <Choose>
            <When condition={route}>
              <Link to={route || ''} underline="always">
                {option?.label}
              </Link>
            </When>
            <Otherwise>
              {option?.label}
            </Otherwise>
          </Choose>
        }
    />
  )
}

const ReferenceInput = <TValues extends {id: number}[], M extends boolean = false>({
  disableSpacing, readOnly, name, label, required, disabled, helperText, resource, filter, fullWidth = true,
  showError = showErrorOnChange, fieldProps, multiple, optionText, optionValue = 'id', tagRoute, size, ...props
}: ReferenceInputProps<TValues[number], M>) => {
  const {input: {value, onChange, ...restInput}, meta} = useField<M extends true ? string[] : string>(name, fieldProps)
  const {isError, helperTextOrError} = showError({meta, helperText})
  const [inputValue, setInputValue] = useState<string>('')
  const q = useDebouncedValue(inputValue, 300)

  const [selectedOptions, searchOptions] = useQueries({
    queries: [
      {
        ...queryDef<TValues>({resource, filter: {id: value}}),
        enabled: isArray(value) ? !isEmpty(value) : Boolean(value),
        suspense: false,
        keepPreviousData: true,
      },
      {
        ...queryDef<TValues>({resource, q, filter}),
        enabled: (isArray(value) ? isEmpty(value) : !value) || !isEmpty(q),
        suspense: false,
        keepPreviousData: true,
      },
    ],
  })

  const selectedOptionsData = (selectedOptions?.data?.data || []) as TValues
  const searchOptionsData = (searchOptions?.data?.data || []) as TValues

  const formattedOptions = compact([...selectedOptionsData, ...searchOptionsData])
    .map((item) => ({
      label: String(getProperty(item, optionText)),
      value: String(getProperty(item, optionValue)),
    }))
  const options = uniqBy(formattedOptions, 'value')

  return (
    <Choose>
      <When condition={!readOnly}>
        <Autocomplete
            fullWidth={fullWidth}
            options={options}
            value={value}
            multiple={multiple}
            loading={selectedOptions.isFetching || searchOptions.isFetching}
            onChange={(newValue) => {
              setInputValue('')
              onChange(newValue)
            }}
            inputValue={inputValue}
            filterOptions={(options) => options}
            onInputChange={(event, newInputValue) => {
              if (!event || event.type !== 'change') return
              event.preventDefault()
              setInputValue(newInputValue)
            }}
            disabled={disabled}
            size={size}
            renderTags={(selectedOptions, getTagProps, ownerState) => (
              !isEmpty(selectedOptions) && map(castArray(selectedOptions), (option, index) => (
                <Tag
                    {...(getTagProps ? getTagProps({index}) : {})}
                    key={index}
                    size={size}
                    tagRoute={tagRoute || resource}
                    option={option}
                    multiple={ownerState?.multiple}
                    onDelete={(e) => {
                      if (multiple && getTagProps) return getTagProps({index}).onDelete(e)
                      setInputValue('')
                      onChange(undefined)
                    }}
                />
              ))
            )}
            innerProps={{
              required,
              name,
              label,
              helperText: helperTextOrError,
              error: isError,
              inputProps: {
                required,
                ...restInput,
              },
              disabled,
            }}
            {...props}
        />
      </When>
      <Otherwise>
        <StaticField label={label} disableSpacing={disableSpacing}>
          <Choose>
            <When condition={!isEmpty(selectedOptionsData)}>
              {map(selectedOptionsData, (option, index) => {
                const label = String(getProperty(option, optionText))
                const value = String(getProperty(option, optionValue))
                const route = tagRoute && value ? getTagRoute(tagRoute, {value, label}) : null
                return (
                  <Fragment key={index}>
                    {index > 0 ? '; ' : null}
                    <Choose>
                      <When condition={route}>
                        <Link
                            to={route || ''}
                            underline="always"
                        >
                          {label}
                        </Link>
                      </When>
                      <Otherwise>
                        {label}
                      </Otherwise>
                    </Choose>
                  </Fragment>
                )
              })}
            </When>
            <Otherwise>
              <RemoveIcon />
            </Otherwise>
          </Choose>
        </StaticField>
      </Otherwise>
    </Choose>
  )
}

export default ReferenceInput
