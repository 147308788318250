import {createColumnHelper} from '@tanstack/react-table'
import type {ListPermitResponseSchema} from 'backend/src/controllers/permits/ListPermit'
import * as resources from 'constants/resources'
import type {ComponentProps, Dispatch, FC, SetStateAction} from 'react'
import {useNavigate} from 'react-router'
import * as clientRoutes from '../../../constants/routes'
import generatePath from '../../../utils/generatePath'
import {getSortableColumns} from '../../../utils/lists'
import ControlledDatagrid from '../../containers/Datagrid/ControlledDatagrid'
import dateCell from '../../containers/Datagrid/cells/dateCell'
import relationalCell from '../../containers/Datagrid/cells/relationalCell'


const columnHelper = createColumnHelper<ListPermitResponseSchema[number]>()

const columns = [
  columnHelper.accessor('id', {header: 'ID'}),
  columnHelper.accessor('siding.name', {id: 'siding.name', header: 'Název vlečky', cell: relationalCell({resource: resources.SIDINGS, id: 'sidingId'})}),
  columnHelper.accessor('company.name', {id: 'company.name', header: 'Název firmy', cell: relationalCell({resource: resources.COMPANIES, id: 'companyId'})}),
  columnHelper.accessor('validFrom', {header: 'Platnost od', cell: dateCell({fallback: 'Neuvedeno'})}),
  columnHelper.accessor('cancelledAt', {header: 'Platnost do', cell: dateCell({fallback: 'Neurčito'})}),
]

export const sortableColumns = getSortableColumns(columns)

type Query = ComponentProps<typeof ControlledDatagrid>['query']

type PermitsTableProps = {
  permits: ListPermitResponseSchema
  total: number | null
  query: Query
  setQuery: Dispatch<SetStateAction<Query>>
}

const PermitsTable: FC<PermitsTableProps> = ({
  permits, total, query, setQuery, ...props
}) => {
  const navigate = useNavigate()
  return (
    <ControlledDatagrid<ListPermitResponseSchema[number]>
        data={permits}
        columns={columns}
        total={total}
        query={query}
        setQuery={setQuery}
        onRowClick={(row) => navigate(
          generatePath(clientRoutes.PERMITS_EDIT, {permitId: row.id}),
        )}
        {...props}
    />
  )
}

export default PermitsTable
