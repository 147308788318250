import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material'
import type {GetSidingRevisionResponseSchema} from 'common/responses'
import {map} from 'lodash'
import {useState} from 'react'
import type {FC, SyntheticEvent} from 'react'
import FormView from '../../../../form/FormView'
import {SidingCancellationModalFieldsCancellation, SidingCancellationModalFieldsRemoval} from './SidingCancellationModalForm'
import SidingCancellationTitle from './SidingCancellationTitle'


type SidingCancellationRevisionDetailProps = {
  sidingCancellations: GetSidingRevisionResponseSchema['sidingCancellations']
}

const SidingCancellationRevisionDetail: FC<SidingCancellationRevisionDetailProps> = ({sidingCancellations}) => {
  const [expanded, setExpanded] = useState<number | boolean>(false)
  const handleChange = (panel: number) => (_event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <div>
      {map(sidingCancellations, (sidingCancellation, index) => (
        <Accordion expanded={expanded === index} onChange={handleChange(index)}>
          <AccordionSummary>
            <SidingCancellationTitle
                fullWidth
                cancelledPart={sidingCancellation.cancelledPart}
                removedAt={sidingCancellation.removedAt}
            />
          </AccordionSummary>
          <AccordionDetails>
            <FormView
                values={{
                  ...sidingCancellation,
                  isPart: Boolean(sidingCancellation.cancelledPart),
                  isRemoved: Boolean(sidingCancellation.removedAt),
                }}
            >
              <SidingCancellationModalFieldsCancellation
                  isPart={Boolean(sidingCancellation.cancelledPart)}
                  readOnly
              />
              <SidingCancellationModalFieldsRemoval
                  isRemoved={Boolean(sidingCancellation.removedAt)}
                  readOnly
              />
            </FormView>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}

export default SidingCancellationRevisionDetail
