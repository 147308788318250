import {Table as MuiTable, TableBody} from '@mui/material'
import type {RowData, Table} from '@tanstack/react-table'
import type {ComponentProps} from 'react'
import DatagridHead from './DatagridHead'
import DatagridRow from './DatagridRow'


type DatagridTableProps<TData> = {
  table: Table<TData>
  rowStyles?: ComponentProps<typeof DatagridRow<TData>>['rowStyles']
  onRowClick?: ComponentProps<typeof DatagridRow<TData>>['onRowClick']
}

const DatagridTable = <TData extends RowData>({table, rowStyles, onRowClick}:DatagridTableProps<TData>) => {
  return (
    <MuiTable>
      <DatagridHead table={table} />
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <DatagridRow key={row.id} row={row} onRowClick={onRowClick} rowStyles={rowStyles} />
        ))}
      </TableBody>
    </MuiTable>
  )
}


export default DatagridTable
