import type z from 'zod'
import * as common from './common'
import * as types from './types'
import * as schemas from './index'


export const permit = types.object({
  type: common.permitType().optional(),
  validFrom: types.timestamp().nullable().optional(),
  notes: types.text().optional(),
  cancelledAt: types.timestamp().nullable().optional(),
  cancelledRef: types.optionalString().optional(),
  cancelledReason: common.permitCancellationReason().nullable().optional(),
  cancelledGinisPid: types.requiredString().nullable().optional(),
  sidingId: types.id(),
  operatorId: types.id(),
  revisionNote: schemas.text().optional(),
})
export type Permit = z.infer<typeof permit>
