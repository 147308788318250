import {MenuItem, styled, Chip, FormControl, FormHelperText, InputLabel, Select as MuiSelect} from '@mui/material'
import {If} from 'babel-plugin-jsx-control-statements'
import {isString, keyBy, map} from 'lodash'
import type {ComponentProps, ReactNode} from 'react'


type StyledChipsProps = {
  size?: ComponentProps<typeof Chip>['size'],
}

const StyledChips = styled('div')<StyledChipsProps>(({size}) => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: '-7px 2px',
  ...(size === 'small' && {
    margin: '0',
  }),
}))

const StyledFormHelperText = styled(FormHelperText)({
  paddingLeft: '6px',
})

type SelectProps<T, > = ComponentProps<typeof MuiSelect<T>> & {
  options: {label: string, value: string}[],
  helperText?: ReactNode,
}

const Select = <T extends string | string[], >({
  fullWidth, value, label, options, disabled, required, onChange, multiple, error, helperText, size, ...props
}: SelectProps<T>) => {
  const optionsByValue = keyBy(options, 'value')
  const format = (value: '' | T | undefined) => value || (multiple ? [] : '')

  const renderValue = (selected: T) => {
    if (!selected) return null
    if (isString(selected)) return optionsByValue[selected]?.label
    return (
      <StyledChips size={size}>
        {map(selected, (item, index) => (
          <If condition={optionsByValue[item]} key={index}>
            <Chip
                size={size}
                label={optionsByValue[item]?.label}
            />
          </If>

        ))}
      </StyledChips>
    )
  }

  return (
    <FormControl size={size} required={required} error={error} fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect<T>
          multiple={multiple}
          disabled={disabled}
          label={label /* Must be present in outlined */}
          value={format(value) as T}
          fullWidth={fullWidth}
          onChange={(e, ...args) => {
            if (onChange) onChange(e, ...args)
          }}
          renderValue={renderValue}
          {...props}
      >
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>{option.label}</MenuItem>
        ))}
      </MuiSelect>
      <If condition={helperText}>
        <StyledFormHelperText>{helperText}</StyledFormHelperText>
      </If>
    </FormControl>
  )
}

export default Select
