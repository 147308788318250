import {CircularProgress, Autocomplete as MuiAutocomplete} from '@mui/material'
import type {AutocompleteRenderInputParams, AutocompleteRenderGetTagProps} from '@mui/material'
import {If} from 'babel-plugin-jsx-control-statements'
import {castArray, compact, isArray, keyBy, map} from 'lodash-es'
import type {ComponentProps, ReactNode} from 'react'
import useCombinedHandlers from '../../../hooks/useCombinedHandlers'
import TextInput from '../TextInput/TextInput'


type Option = {
  label: string,
  value: string,
}

type AutocompleteInputProps = ComponentProps<typeof TextInput> & {
  selectedValue?: Option | Option[] | null | undefined
  onDelete?: ((event: unknown) => void) | undefined
  loading?: boolean
  params: AutocompleteRenderInputParams
  renderTags?: (value: Option[]) => ReactNode
}

const AutocompleteInput = ({
  selectedValue, loading, params, InputProps, renderTags, ...props
}: AutocompleteInputProps) => {
  const onBlur = useCombinedHandlers(params.inputProps.onBlur)
  const onFocus = useCombinedHandlers(params.inputProps.onFocus)
  const tags = selectedValue && renderTags ? renderTags(castArray(selectedValue)) : null

  const {startAdornment} = params.InputProps
  return (
    <TextInput
        {...params}
        {...props}
        inputProps={{
          ...params.inputProps,
          onBlur,
          onFocus,
        }}
        InputProps={{
          ...InputProps,
          ...params.InputProps,
          ...(
            startAdornment
              ? {startAdornment}
              : {startAdornment: tags}
          ),
          endAdornment: (
            <>
              <If condition={loading}>
                <CircularProgress size={20} />
              </If>
              {InputProps?.endAdornment}
              {params?.InputProps?.endAdornment}
            </>
          ),
        }}
    />
  )
}

type AutocompleteProps<M extends boolean = false> = Omit<
  ComponentProps<typeof MuiAutocomplete<Option, M>>,
  'multiple' | 'renderInput' | 'onChange' | 'value'
> & {
  label?: string
  onChange: (value: unknown) => void
  innerProps?: Omit<ComponentProps<typeof AutocompleteInput>, 'params'>
  multiple?: M
  value: M extends true ? (string)[] : string | null
  renderTags?: (
    (
      value: Option[],
       getTagProps?: AutocompleteRenderGetTagProps,
       ownerState?: {multiple?: M}
    ) => ReactNode
  )
}

const AutoComplete = <M extends boolean = false>({
  multiple, options, loading, value, inputValue, onInputChange, innerProps, renderTags, onChange, ...props
}: AutocompleteProps<M>) => {
  const optionsByValue = keyBy(options, 'value')

  const format = (value: Option['value'] | Option['value'][] | null | undefined) => value === undefined ? undefined : isArray(value)
    ? compact(map(value, (item) => optionsByValue[item]))
    : value ? optionsByValue[value] : []

  const parse = (selected: Option | Option[] | null) => isArray(selected)
    ? map(selected, 'value')
    : selected ? selected.value : undefined

  const handleChange = (_event: unknown, value: Option | Option[] | null) => {
    onChange(parse(value))
  }

  return (
    <MuiAutocomplete<Option, boolean>
        options={options}
        multiple={multiple}
        loading={loading}
        value={format(value)}
        onChange={handleChange}
        inputValue={inputValue || ''}
        onInputChange={onInputChange}
        getOptionLabel={(option: Option) => option.label || ''}
        renderTags={renderTags}
        renderInput={(params) => (
          <AutocompleteInput
              selectedValue={format(value)}
              renderTags={renderTags}
              loading={loading}
              {...innerProps}
              params={params}
          />
        )}
        {...props}
    />
  )
}

export default AutoComplete
