import * as licenseCancellationReasons from 'constants/licenseCancellationReasons'


export default {
  [licenseCancellationReasons.REASON1]: 'Žádost dopravce',
  [licenseCancellationReasons.REASON2]: 'Nesplnění podmínek pro udělení oprávnění',
  [licenseCancellationReasons.REASON3]: 'Zánik dopravce',
  [licenseCancellationReasons.REASON4]: 'Pozbyla platnost zrušením dráhy',
  [licenseCancellationReasons.REASON5]: 'Licence neudělena',
  [licenseCancellationReasons.REASON6]: 'Porušení ustanovení zákona',
  [licenseCancellationReasons.REASON7]: 'Jiné důvody',
} as const
