import {useQuery} from '@tanstack/react-query'
import {If} from 'babel-plugin-jsx-control-statements'
import * as enums from 'common/labels/enums'
import type {ListCompanyResponseSchema, ListSupervisionResponseSchema, ListUserResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import {ALL_DEPARTMENTS} from 'constants/departments'
import * as resources from 'constants/resources'
import {omit} from 'lodash'
import type {TypeOf, ZodObject} from 'zod'
import * as clientRoutes from '../../../constants/routes'
import {createListQuery} from '../../../hooks/api'
import useQueryParams from '../../../hooks/useQueryParams'
import generatePath from '../../../utils/generatePath'
import {queryToSort} from '../../../utils/lists'
import Breadcrumbs from '../../containers/Breadcrumbs'
import ExportButton from '../../containers/ExportButton'
import Filters from '../../containers/Filters'
import DateInput from '../../form/DateInput'
import EnumInput from '../../form/EnumInput'
import ReferenceInput from '../../form/ReferenceInput'
import SearchBoxInput from '../../form/SearchBoxInput'
import {InputStack, ScreenHeader, ScreenWrapper} from '../../visual'
import NotFound from '../NotFound'
import SupervisionsEdit from './SupervisionsEdit'
import SupervisionsTable, {sortableColumns} from './SupervisionsTable'


const schema = {
  page: schemas.nonNegativeInteger().default(1),
  pageSize: schemas.positiveInteger().default(10).catch(10),
  sortBy: schemas.createEnum(sortableColumns).default('id').catch('id'),
  sortDirection: schemas.createEnum(['asc', 'desc']).default('desc').catch('desc'),
  supervisionId: schemas.nonNegativeInteger().optional(),
  filter: schemas.optionalObject({
    q: schemas.requiredString().optional(),
    companyId: schemas.oneOrMany(schemas.id()).optional(),
    department: schemas.oneOrMany(schemas.createEnum(ALL_DEPARTMENTS)).optional(),
    supervisedAtFrom: schemas.timestamp().nullable().optional(),
    supervisedAtTo: schemas.timestamp().nullable().optional(),
    userId: schemas.oneOrMany(schemas.id()).optional(),
  }).optional().catch({}),
}

type Query = TypeOf<ZodObject<typeof schema>>

const queryDef = (
  {query}: {query: Query},
) => createListQuery<ListSupervisionResponseSchema>({
  resource: resources.SUPERVISIONS,
  query: {
    ...queryToSort(query),
    page: query.page - 1,
    pageSize: query.pageSize,
    filter: {
      ...omit(query?.filter, ['supervisedAtFrom', 'supervisedAtTo']),
      supervisedAt: query?.filter?.supervisedAtFrom && query?.filter?.supervisedAtTo
        ? [query?.filter?.supervisedAtFrom, query?.filter?.supervisedAtTo]
        : undefined,
    },
  },
})

const SupervisionsList = () => {
  const [query, setQuery] = useQueryParams(schema)
  const {data} = useQuery(queryDef({query: {...query}}))
  const supervisions = data?.data

  if (!supervisions) return <NotFound />

  const handleCloseSupervisionEdit = () => {
    setQuery((prevQuery) => omit(prevQuery, 'supervisionId'))
  }
  return (
    <ScreenWrapper>
      <ScreenHeader
          title="Státní dozory"
          breadcrumbs={
            <Breadcrumbs>
              <Breadcrumbs.Item>Evidence vleček</Breadcrumbs.Item>
              <Breadcrumbs.Item active to={clientRoutes.SUPERVISIONS}>Státní dozory</Breadcrumbs.Item>
            </Breadcrumbs>
          }
      >
        <ExportButton resource={resources.SUPERVISIONS} query={query} />
      </ScreenHeader>

      <Filters query={query} setQuery={setQuery}>
        {({handleSubmit, handleReset}) => (
          <>
            <SearchBoxInput name="q" label="Hledat mezi dozory" placeholder="Vyhledávání potvrďte Enterem" onApply={handleSubmit} />
            <Filters.Drawer alwaysVisibleFilters={['q']} query={query} onApply={handleSubmit} onReset={handleReset}>
              <ReferenceInput<ListCompanyResponseSchema, true>
                  name="companyId"
                  label="Kontrolovaný subjekt"
                  optionText="name"
                  resource={resources.COMPANIES}
                  tagRoute={({value}) => generatePath(clientRoutes.COMPANIES_EDIT, {companyId: value})}
                  size="small"
                  fullWidth={false}
                  multiple
              />
              <ReferenceInput<ListUserResponseSchema, true>
                  name="userId"
                  label="Provedl"
                  optionText={({firstName, lastName}) => `${firstName} ${lastName}`}
                  resource={resources.USERS}
                  tagRoute={({value}) => generatePath(clientRoutes.USERS_EDIT, {userId: value})}
                  size="small"
                  fullWidth={false}
                  multiple
              />
              <EnumInput name="department" label="Vydala sekce" enumName={enums.DEPARTMENTS} size="small" fullWidth={false} multiple />
              <InputStack>
                <DateInput name="supervisedAtFrom" label="Výkon SD od" slotProps={{textField: {size: 'small'}}} fullWidth={false} />
                <DateInput name="supervisedAtTo" label="Výkon SD do" slotProps={{textField: {size: 'small'}}} fullWidth={false} />
              </InputStack>
            </Filters.Drawer>
          </>
        )}
      </Filters>

      <SupervisionsTable
          supervisions={supervisions}
          total={data.total}
          query={query}
          setQuery={setQuery}
      />
      <If condition={query?.supervisionId}>
        <SupervisionsEdit
            supervisionId={Number(query.supervisionId)}
            handleClose={handleCloseSupervisionEdit}
        />
      </If>
    </ScreenWrapper>
  )
}

export default SupervisionsList
