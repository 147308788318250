import {Radio, FormControlLabel} from '@mui/material'
import {styled} from '@mui/material/styles'
import type {ComponentProps, FC} from 'react'


type RadioGroupInputProps = ComponentProps<typeof Radio> & {
 label?: string
}

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  marginRight: '4.25rem',
}))

const RadioGroupInput: FC<RadioGroupInputProps> = ({checked, value, label, ...props}) => {
  return (
    <StyledFormControlLabel
        value={value}
        label={label}
        control={<Radio checked={checked} {...props} />}
    />
  )
}

export default RadioGroupInput
