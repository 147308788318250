import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import {If} from 'babel-plugin-jsx-control-statements'
import type {GetUserResponseSchema} from 'common/responses'
import {INVALID_CREDENTIALS_ERROR} from 'constants/errorCodes'
import * as resources from 'constants/resources'
import type {ComponentProps, FC} from 'react'
import {useState} from 'react'
import {createOneQuery, useUpdateResource} from '../../../hooks/api'
import {useAuth} from '../../../hooks/auth'
import {useAlert} from '../../../hooks/useAlert'
import {isFrontendError} from '../../../utils/api'
import Button from '../../containers/Button'
import {Modal} from '../../visual'
import ProfilePasswordChangeForm from './ProfilePasswordChangeForm'


export const queryDef = ({userId}: {userId: number}) => createOneQuery<GetUserResponseSchema>({
  resource: resources.USERS,
  id: userId,
})

type ProfilePasswordChangeProps = {
  userId: number,
}

const ProfilePasswordChange: FC<ProfilePasswordChangeProps> = ({userId}) => {
  const [passwordChangeOpen, setPasswordChangeOpen] = useState(false)
  const updateUser = useUpdateResource({resource: resources.USERS, id: userId})
  const {refreshAuth} = useAuth()
  const showAlert = useAlert()

  const handleSubmit: ComponentProps<typeof ProfilePasswordChangeForm>['onSubmit'] = async (values) => {
    try {
      const {data} = await updateUser.mutateAsync({
        password: values.password,
        oldPassword: values.oldPassword,
      })
      await refreshAuth()
      showAlert(`Heslo pro uživatele ${data.id} změněno, pro pokračování se opět přihlašte`, 'success')
    } catch (e: unknown) {
      if (isFrontendError(e) && e.data.errorCode === INVALID_CREDENTIALS_ERROR) return {oldPassword: 'Neplatné staré heslo'}
      throw e
    }
  }

  const handlePasswordChangeToggle = () => setPasswordChangeOpen((prevOpen) => !prevOpen)

  return (
    <>
      <Button onClick={handlePasswordChangeToggle}>Změna hesla</Button>
      <If condition={passwordChangeOpen}>
        <ProfilePasswordChangeForm onSubmit={handleSubmit}>
          {({submitting, form, handleSubmit}) => (
            <Modal open onClose={handlePasswordChangeToggle}>
              <Modal.Title>Změna hesla</Modal.Title>
              <Modal.Content>
                {form}
              </Modal.Content>
              <Modal.Actions>
                <Button icon={<CloseIcon />} onClick={handlePasswordChangeToggle}>Zavřít</Button>
                <Button
                    icon={<SaveIcon />}
                    variant="contained"
                    disabled={submitting}
                    onClick={handleSubmit}
                >
                  Uložit
                </Button>
              </Modal.Actions>
            </Modal>
          )}
        </ProfilePasswordChangeForm>
      </If>
    </>
  )
}

export default ProfilePasswordChange
