import type {GetSidingResponseSchema} from 'common/responses'
import * as resources from 'constants/resources'
import {omit} from 'lodash'
import type {ComponentProps, FC} from 'react'
import {useState} from 'react'
import {useCreateResource} from '../../../../../hooks/api'
import {useAlert} from '../../../../../hooks/useAlert'
import Button from '../../../../containers/Button'
import {Modal} from '../../../../visual'
import SidingCancellationModalForm from './SidingCancellationModalForm'


type SidingsCancellationModalProps = {
  sidingId: GetSidingResponseSchema['id']
}

const SidingCancellationModal: FC<SidingsCancellationModalProps> = ({sidingId}) => {
  const [open, setOpen] = useState(false)
  const cancelSiding = useCreateResource({resource: resources.SIDING_CANCELLATIONS})
  const showAlert = useAlert()

  const handleSubmit: ComponentProps<typeof SidingCancellationModalForm>['onSubmit'] = async (values, form) => {
    await cancelSiding.mutateAsync(omit(values, 'isRemoved'))
    setOpen(false)
    form.reset()
    showAlert(`Vlečka ${sidingId} zrušena`, 'success')
  }

  return (
    <>
      <Button color="error" onClick={() => setOpen(true)}>Zrušit vlečku / úsek</Button>
      <SidingCancellationModalForm initialValues={{sidingId}} onSubmit={handleSubmit} keepDirtyOnReinitialize={false}>
        {({form, handleSubmit}) => (
          <Modal open={open} onClose={() => setOpen(false)}>
            <Modal.Title>Rušení vlečky</Modal.Title>
            <Modal.Content>
              {form}
            </Modal.Content>
            <Modal.Actions>
              <Button variant="contained" color="error" onClick={handleSubmit}>Potvrdit zrušení</Button>
              <Button color="neutral" onClick={() => setOpen(false)}>Zavřít</Button>
            </Modal.Actions>
          </Modal>
        )}
      </SidingCancellationModalForm>
    </>
  )
}


export default SidingCancellationModal
