import type z from 'zod'
import * as common from './common'
import * as types from './types'


export const user = types.object({
  firstName: types.requiredString('Janko'),
  lastName: types.requiredString('Mrkvička'),
  department: common.department().optional().nullable(),
  email: common.email('admin@vlecky.cz'),
  password: types.minimalPassword().nullable(),
  roleId: types.id(),
})
export type User = z.infer<typeof user>
