import {Stack, Typography} from '@mui/material'
import type {GetSidingResponseSchema} from 'common/responses'
import type {FC} from 'react'


type SidingCancellationTitleProps = {
  fullWidth?: boolean
  cancelledPart?: NonNullable<GetSidingResponseSchema['sidingCancellations'][number]>['cancelledPart']
  removedAt?: NonNullable<GetSidingResponseSchema['sidingCancellations'][number]>['removedAt']
}

const SidingCancellationTitle: FC<SidingCancellationTitleProps> = ({fullWidth, cancelledPart, removedAt}) => {
  return (
    <Stack useFlexGap gap={1} direction={{sx: 'column', md: 'row'}} width={fullWidth ? '100%' : {md: '100%', lg: '70%', xl: '60%'}} justifyContent="space-between">
      <Typography component="span">
        Typ zrušení: <Typography component="span" fontWeight="bold">{cancelledPart ? 'Úsek vlečky' : 'Celá vlečka'}</Typography>
      </Typography>
      <Typography component="span">
        Odstránení kolejište: <Typography component="span" fontWeight="bold">{removedAt ? 'Ano' : 'Ne'}</Typography>
      </Typography>
    </Stack>
  )
}


export default SidingCancellationTitle
