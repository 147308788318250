import type {ComponentProps, FC, MouseEvent} from 'react'
import {useTransition} from 'react'
import {BaseButton} from '../visual'


type BaseButtonProps = Omit<ComponentProps<typeof BaseButton>, 'onClick'> & {
  onClick?: ((e: MouseEvent<HTMLButtonElement>) => void)
}

const Button: FC<BaseButtonProps> = ({
  variant = 'outlined', color = 'primary', disabled = false, onClick, children, ...props
}) => {
  const [isPending, startTransition] = useTransition()

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    startTransition(() => {
      if (onClick) onClick(e)
    })
  }

  return (
    <BaseButton
        disabled={disabled}
        isPending={isPending}
        onClick={onClick ? handleClick : undefined}
        variant={variant}
        color={color}
        {...props}
    >
      {children}
    </BaseButton>
  )
}

export default Button
