import {If} from 'babel-plugin-jsx-control-statements'
import * as enums from 'common/labels/enums'
import type {GetUserResponseSchema, ListRoleResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import * as resources from 'constants/resources'
import {isEmpty} from 'lodash-es'
import {useState} from 'react'
import * as clientRoutes from '../../../constants/routes'
import {useAsyncUniqueValidate} from '../../../hooks/useAsyncValidate'
import AsymetricLayout from '../../containers/AsymetricLayout'
import EnumInput from '../../form/EnumInput'
import PasswordInput from '../../form/PasswordInput'
import ReferenceInput from '../../form/ReferenceInput'
import TextInput from '../../form/TextInput'
import {showErrorOnBlur, showErrorOnChange} from '../../form/forms'
import withForm from '../../form/withForm'
import {FormSection, Tabs} from '../../visual'


const schema = schemas.user.user.partial({password: true})

type UsersFormProps = {
  user?: GetUserResponseSchema
  readOnly?: boolean
}

const tabs = ['Základní údaje']

const UsersForm = withForm({schema})<UsersFormProps>(({user, readOnly}) => {
  const [activeTab, setActiveTab] = useState(0)
  const isNewRecord = !user?.id
  const validateEmail = useAsyncUniqueValidate({
    resource: resources.USERS,
    column: 'email',
    deleted: true,
  })

  return (
    <AsymetricLayout>
      <AsymetricLayout.Items>
        <FormSection title="Detail uživatele">
          <Tabs
              labels={tabs}
              value={activeTab}
              onChange={(_e, value: number) => setActiveTab(value)}
          >
            <Tabs.Panel value={activeTab} index={0}>
              <TextInput name="firstName" label="Jméno" readOnly={readOnly} required />
              <TextInput name="lastName" label="Příjmení" readOnly={readOnly} required />
              <TextInput
                  name="email"
                  label="Email"
                  required
                  fieldProps={{validate: validateEmail}}
                  showError={showErrorOnBlur}
                  readOnly={!isNewRecord || readOnly}
              />
              <If condition={isNewRecord || !readOnly}>
                <PasswordInput
                    name="password"
                    label="Heslo"
                    autoComplete="new-password"
                    showError={showErrorOnChange}
                    fieldProps={{
                      validate: (password) => {
                      // Password is validated only when creating new user
                        if (isNewRecord && isEmpty(password)) return 'Povinné'
                      },
                    }}
                    required
                />
                <PasswordInput
                    name="confirmPassword"
                    label="Nové heslo znovu"
                    showError={showErrorOnBlur}
                    fieldProps={{
                      validate: (confirmPassword, {password}: {password: schemas.user.User['password']}) => {
                        if (password !== confirmPassword) return 'Hesla se musí shodovat'
                      },
                    }}
                    required
                />
              </If>
              <ReferenceInput<ListRoleResponseSchema>
                  name="roleId"
                  label="Role"
                  optionText="name"
                  resource={resources.ROLES}
                  tagRoute={({value}) => `${clientRoutes.ROLES}?roleId=${value}`}
                  readOnly={readOnly}
                  required
              />
              <EnumInput
                  name="department"
                  label="Sekce"
                  enumName={enums.DEPARTMENTS}
                  readOnly={readOnly}
              />
            </Tabs.Panel>
          </Tabs>
        </FormSection>
      </AsymetricLayout.Items>
    </AsymetricLayout>
  )
})

export default UsersForm
