import arrayMutators from 'final-form-arrays'
import {noop} from 'lodash'
import {Form} from 'react-final-form'


type FormViewProps<TValues> = {
  values: TValues
  children: React.ReactNode
}

const FormView = <TValues extends Record<string, unknown>>({values, children}: FormViewProps<TValues>) => {
  return (
    <Form
        initialValues={values}
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={noop}
    >
      {() => children}
    </Form>
  )
}

export default FormView
