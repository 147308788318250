import type z from 'zod'
import * as common from './common'
import * as types from './types'
import * as schemas from './index'


export const siding = types.object({
  requiresVerification: types.boolean().optional(),
  name: types.requiredString(),
  externalRef: types.optionalString().optional(),
  mouthing: types.optionalString().optional(),
  trackStart: types.text().optional(),
  trackEnd: types.optionalString().optional(),
  trackLength: types.number().optional().nullable(),
  trackCategory: common.trackCategory(),
  trackExternalKJR: types.optionalString().optional(),
  trackExternalTTP: types.optionalString().optional(),
  notesOperation: types.text().optional(),
  notesInfrastructure: types.text().optional(),
  closedAreal: common.closedArealType().optional(),
  hasTrolley: types.boolean().optional(),
  coordinates: common.coordinates().nullable(),
  userId: types.id(),
  infrastructureDocuments: types.array(types.object({
    type: common.infrastructureDocumentType(),
    referenceNumber: types.requiredString(),
    ginisPid: types.requiredString(),
  })).optional(),
  licenseIds: schemas.array(schemas.id()).optional(),
  ownerIds: schemas.array(schemas.id()).min(1),
  renterIds: schemas.array(schemas.id()).optional(),
  revisionNote: schemas.text().optional(),
  sidingSidingIds: schemas.array(schemas.id()).optional(),
})
export type Siding = z.infer<typeof siding>
