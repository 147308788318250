import type {JSONValue} from 'common/schemas'
import * as schemas from 'common/schemas'
import {fromPairs, isFunction} from 'lodash'
import {useMemo} from 'react'
import {redirect, useLocation, useSearchParams} from 'react-router-dom'
import type {ZodRawShape} from 'zod'
import type {Options} from '../utils/api'
import {getQueryString} from '../utils/api'


const parseSearchParams = (searchParams: URLSearchParams) => {
  return fromPairs(Array.from(searchParams.entries()).map(([key, value]) => {
    let newValue: JSONValue

    try {
      newValue = JSON.parse(value) as JSONValue
    } catch {
      newValue = value
    }

    return [key, newValue]
  }))
}

const useQueryParams = <TShape extends ZodRawShape>(shape: TShape) => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const query = useMemo(() => {
    const schema = schemas.object(shape)
    const result = schema.safeParse(parseSearchParams(searchParams))
    if (!result.success) {
      throw redirect(location.pathname, 301)
    }
    return result.data
  }, [location.pathname, searchParams, shape])


  const handleSetQuery = (query: Options['query'] | ((prevQuery: Options['query']) => Options['query'])) => {
    setSearchParams((prevSearchParams) => {
      if (isFunction(query)) return getQueryString(query(parseSearchParams(prevSearchParams)))

      return getQueryString(query)
    })
  }

  return [query, handleSetQuery] as const
}

export default useQueryParams
