export const COMPANIES = '/companies'
export const COMPANIES_CREATE = '/companies/create'
export const COMPANIES_EDIT = '/companies/:companyId'
export const FOREIGN_CARRIERS = '/foreign-carriers'
export const FOREIGN_CARRIERS_CREATE = '/foreign-carriers/create'
export const FOREIGN_CARRIERS_EDIT = '/foreign-carriers/:foreignCarrierId'
export const HOME = '/'
export const LICENSES = '/licenses'
export const LICENSES_CREATE = '/licenses/create'
export const LICENSES_EDIT = '/licenses/:licenseId'
export const PERMITS = '/permits'
export const PERMITS_CREATE = '/permits/create'
export const PERMITS_EDIT = '/permits/:permitId'
export const PROFILE = '/profile'
export const ROLES = '/users/roles'
export const SUPERVISIONS = '/supervisions'
export const SIDINGS = '/sidings'
export const SIDINGS_CREATE = '/sidings/create'
export const SIDINGS_EDIT = '/sidings/:sidingId'
export const USERS = '/users'
export const USERS_CREATE = '/users/create'
export const USERS_EDIT = '/users/:userId'

export const getEJusticeUrl = (companyNumber: string) => {
  return `https://or.justice.cz/ias/ui/rejstrik-$firma?ico=${companyNumber}&jenPlatne=PLATNE&polozek=50&typHledani=STARTS_WITH`
}
export const getGinisUrl = (pid: string) => {
  return `https://gusu.ducr.cz/Gordic/Ginis/App/SPRhtml5/?c=OpenDetail&ixx1=${pid}`
}

export const getMapUrl = (x: number, y: number) => {
  return `https://mapy.cz/zakladni?x=${x}&y=${y}&z=10`
}

export const getCadastreUrl = (x: number, y: number) => {
  return `https://www.ikatastr.cz/#kde=${x},${y}`
}
