import type z from 'zod'
import * as common from './common'
import * as types from './types'
import * as schemas from './index'


export const license = types.object({
  validFrom: types.timestamp().nullable().optional(),
  trackType: common.trackType(),
  transportType: common.transportType(),
  transportCategory: common.transportCategory(),
  newLaw: types.boolean().optional(),
  notes: types.text().optional(),
  cancelledAt: types.timestamp().nullable().optional(),
  cancelledRef: types.optionalString().optional(),
  cancelledReason: common.licenseCancellationReason().nullable().optional(),
  cancelledGinisPid: types.requiredString().nullable().optional(),
  carrierId: types.id(),
  revisionNote: schemas.text().optional(),
})
export type License = z.infer<typeof license>
