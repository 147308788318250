import {Stack} from '@mui/material'
import * as enums from 'common/labels/enums'
import type {ListRoleResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import * as resources from 'constants/resources'
import * as clientRoutes from '../../../constants/routes'
import AsymetricLayout from '../../containers/AsymetricLayout'
import EnumInput from '../../form/EnumInput'
import ReferenceInput from '../../form/ReferenceInput'
import TextInput from '../../form/TextInput'
import withForm from '../../form/withForm'
import {FormSection} from '../../visual'


const ProfileForm = withForm({schema: schemas.user.user.partial()})(() => {
  return (
    <AsymetricLayout>
      <AsymetricLayout.Items>
        <FormSection title="Profil">
          <Stack spacing={2}>
            <TextInput name="firstName" label="Jméno" required />
            <TextInput name="lastName" label="Příjmení" required />
            <TextInput name="email" label="Email" readOnly />
            <ReferenceInput<ListRoleResponseSchema>
                name="roleId"
                label="Role"
                optionText="name"
                resource={resources.ROLES}
                tagRoute={({value}) => `${clientRoutes.ROLES}?roleId=${value}`}
                required
                readOnly
            />
            <EnumInput
                name="department"
                label="Sekce"
                enumName={enums.DEPARTMENTS}
                multiple
                readOnly
            />
          </Stack>
        </FormSection>
      </AsymetricLayout.Items>
    </AsymetricLayout>
  )
})

export default ProfileForm
