import {Stack} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import {If} from 'babel-plugin-jsx-control-statements'
import type {GetSidingResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import * as departments from 'constants/departments'
import {EDIT_SIDINGS} from 'constants/permissions'
import * as resources from 'constants/resources'
import {isBefore} from 'date-fns'
import {filter, find, map, omit, orderBy} from 'lodash'
import type {ComponentProps} from 'react'
import {useNavigate} from 'react-router'
import * as clientRoutes from '../../../constants/routes'
import {createOneQuery, useUpdateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import useParams from '../../../hooks/useParams'
import usePermitted from '../../../hooks/usePermitted'
import useQueryParams from '../../../hooks/useQueryParams'
import AsymetricLayout from '../../containers/AsymetricLayout'
import Breadcrumbs from '../../containers/Breadcrumbs'
import ConfirmModal from '../../containers/ConfirmModal'
import ExternalLinkField from '../../containers/ExternalLinkField'
import FormBlocker from '../../containers/FormBlocker'
import Revisions from '../../containers/Revisions'
import FormErrorAlert from '../../containers/alerts/FormErrorAlert'
import ReadOnlyAlert from '../../containers/alerts/ReadOnlyAlert'
import TextInput from '../../form/TextInput'
import {FormSection, ScreenHeader, ScreenWrapper} from '../../visual'
import NotFound from '../NotFound'
import SidingsForm from './SidingsForm'
import SidingRevisionDetail from './SidingsRevisionDetail'


const schema = {
  revisionId: schemas.nonNegativeInteger().optional(),
}

export const queryDef = ({sidingId}: {sidingId: number}) => createOneQuery<GetSidingResponseSchema>({
  resource: resources.SIDINGS,
  id: sidingId,
})

const SidingsEdit = () => {
  const canEdit = usePermitted([EDIT_SIDINGS])
  const {sidingId} = useParams()
  const [query, setQuery] = useQueryParams(schema)
  const navigate = useNavigate()
  const showAlert = useAlert()
  const {data} = useQuery({...queryDef({sidingId: Number(sidingId)}), enabled: Boolean(Number(sidingId))})
  const updateSidings = useUpdateResource({resource: resources.SIDINGS, id: sidingId})
  const siding = data?.data

  if (!siding) return <NotFound />

  const permits = orderBy(filter(siding.permits, 'validFrom'), 'validFrom', 'desc')
  const currentPermit = permits[0] && (!permits[0].cancelledAt || isBefore(new Date(), permits[0].cancelledAt))
    ? permits[0]
    : null
  const currentSupervisions = orderBy(siding.supervisions, 'supervisedAt', 'desc')
  const currentSupervisionInfrastructure = find(currentSupervisions, {department: departments.INFRASTRUCTURE})
  const currentSupervisionOperational = find(currentSupervisions, {department: departments.OPERATIONAL_TECHNICAL})
  const xCoordinate = siding?.coordinates?.[0]?.[0]
  const yCoordinate = siding?.coordinates?.[0]?.[1]
  const cadastreUrl = xCoordinate && yCoordinate ? clientRoutes.getCadastreUrl(xCoordinate, yCoordinate) : ''
  const mapUrl = xCoordinate && yCoordinate ? clientRoutes.getMapUrl(xCoordinate, yCoordinate) : ''
  const sidingCancellations = siding.sidingCancellations || []


  const hadndleUpdateSidings: ComponentProps<typeof SidingsForm>['onSubmit'] = async (values) => {
    await updateSidings.mutateAsync({
      requiresVerification: values.requiresVerification,
      name: values.name,
      externalRef: values.externalRef || '',
      mouthing: values.mouthing || '',
      trackStart: values.trackStart || '',
      trackEnd: values.trackEnd || '',
      trackLength: values.trackLength || null,
      trackCategory: values.trackCategory,
      trackExternalKJR: values.trackExternalKJR || '',
      trackExternalTTP: values.trackExternalTTP || '',
      notesOperation: values.notesOperation || '',
      notesInfrastructure: values.notesInfrastructure || '',
      closedAreal: values.closedAreal,
      hasTrolley: values.hasTrolley,
      coordinates: values.coordinates,
      userId: values.userId,
      infrastructureDocuments: values.infrastructureDocuments,
      licenseIds: values.licenseIds,
      ownerIds: values.ownerIds,
      renterIds: values.renterIds,
      sidingSidingIds: values.sidingSidingIds,
      revisionNote: values.revisionNote,
    })
    navigate(clientRoutes.SIDINGS)
    showAlert(`Vlečka ${sidingId} uložena`, 'success')
  }

  return (
    <SidingsForm
        initialValues={{
          ...siding,
          ownerIds: map(siding.owners, 'id'),
          renterIds: map(siding.renters, 'id'),
          licenseIds: map(siding.licenses, 'id'),
          sidingSidingIds: map(siding.sidings, 'id'),
        }}
        innerProps={{
          sidingId: Number(sidingId),
          permits,
          supervisions: currentSupervisions || [],
          currentPermit,
          currentSupervisionInfrastructure,
          currentSupervisionOperational,
          readOnly: !canEdit,
          sidingCancellations,
        }}
        onSubmit={hadndleUpdateSidings}
    >
      {({form, initialValues, handleSubmit, valid}) => (
        <ScreenWrapper>
          <FormBlocker />
          <ScreenHeader
              title={siding.name}
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item>Evidence vleček</Breadcrumbs.Item>
                  <Breadcrumbs.Item to={clientRoutes.SIDINGS}>Vlečky</Breadcrumbs.Item>
                  <Breadcrumbs.Item active>{siding.name}</Breadcrumbs.Item>
                </Breadcrumbs>
              }
          >
            <If condition={canEdit}>
              <ConfirmModal
                  title="Přejete si uložit změnu údajů?"
                  valid={valid}
                  onConfirm={handleSubmit}
              >
                <p>
                  Chystáte se uložit veškeré změny, které byly na kartě Vlečka provedeny.
                  Detail změn si budete moci zobrazit v historii vlečky. Veškeré údaje lze kdykoli opět přepsat.
                </p>
                <TextInput name="revisionNote" label="Zpráva" multiline />
              </ConfirmModal>
            </If>
          </ScreenHeader>
          <FormErrorAlert />
          <If condition={!canEdit}>
            <ReadOnlyAlert />
          </If>
          <AsymetricLayout>
            <AsymetricLayout.Items>
              {form}
            </AsymetricLayout.Items>
            <AsymetricLayout.Items>
              <If condition={cadastreUrl || mapUrl}>
                <FormSection title="Užitečné odkazy">
                  <Stack gap={2}>
                    <If condition={cadastreUrl}>
                      <ExternalLinkField
                          label="Informace o pozemku vlečky"
                          link={cadastreUrl}
                          linkText="Otevřít iKatastr"
                      />
                    </If>
                    <If condition={mapUrl}>
                      <ExternalLinkField
                          label="Zobrazení vlečky v mapě"
                          link={mapUrl}
                          linkText="Otevřít Mapy.cz"
                      />
                    </If>
                  </Stack>
                </FormSection>
              </If>
              <Revisions
                  resource={resources.SIDING_REVISIONS}
                  query={{sidingId: 'id' in initialValues && Number(initialValues?.id)}}
                  onRevisionClick={(revisionId) => setQuery((prevQuery) => ({...prevQuery, revisionId}))}
              />
              <SidingRevisionDetail revisionId={query.revisionId || null} onClose={() => setQuery((prevQuery) => omit(prevQuery, 'revisionId'))} />
            </AsymetricLayout.Items>
          </AsymetricLayout>
        </ScreenWrapper>
      )}
    </SidingsForm>
  )
}

export default SidingsEdit
