import DownloadIcon from '@mui/icons-material/Download'
import type {JSONValue} from 'common/schemas'
import type {Resource} from 'constants/resources'
import * as routes from 'constants/routes'
import {generateResourcePath} from 'constants/routes'
import {pick} from 'lodash'
import type {FC} from 'react'
import {useDownload} from '../../hooks/api'
import {useAlert} from '../../hooks/useAlert'
import Button from './Button'


type ExportButtonProps = {
  resource: Resource
  query: Record<string, JSONValue>
  filename?: string
}

const ExportButton: FC<ExportButtonProps> = ({resource, query, filename}) => {
  const showAlert = useAlert()
  const {downloadFile, loading} = useDownload(generateResourcePath(resource, routes.EXPORT), {
    onSuccess: (filename) => {
      showAlert(`Sestava ${filename} úspěšně stažena`, 'success')
    },
    onError: () => {
      showAlert('Sestavu se nepodařilo vygenerovat', 'error')
    },
  })

  return (
    <Button
        color="neutral"
        icon={<DownloadIcon />}
        isPending={loading}
        disabled={loading}
        onClick={downloadFile(pick(query, ['sort', 'filter']), filename)}
    >
      Exportovat sestavu
    </Button>
  )
}

export default ExportButton
