import VisibilityIcon from '@mui/icons-material/Visibility'
import {IconButton, Skeleton, Stack, TablePagination} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import {Choose, If, Otherwise, When} from 'babel-plugin-jsx-control-statements'
import type {
  ListForeignCarrierRevisionResponseSchema, 
  ListCompanyRevisionResponseSchema,
  ListSidingRevisionResponseSchema, 
  ListPermitRevisionResponseSchema,
  ListLicenseRevisionResponseSchema,
} from 'common/responses'
import type {JSONValue} from 'common/schemas'
import type {Resource} from 'constants/resources'
import {format} from 'date-fns'
import {map, range} from 'lodash'
import {useState} from 'react'
import {createListQuery} from '../../hooks/api'
import {Revisions as VisualRevisions} from '../visual'


type RevisionResource = {
  sidingRevisions: ListSidingRevisionResponseSchema
  foreignCarrierRevisions: ListForeignCarrierRevisionResponseSchema
  companyRevisions: ListCompanyRevisionResponseSchema
  permitRevisions: ListPermitRevisionResponseSchema
  licenseRevisions: ListLicenseRevisionResponseSchema
}

type QueryDefArgs = {
  resource: Resource
  q?: string | null
  query?: Record<string, JSONValue>
}

const initialPageSize = 10

const queryDef = <TValues, >({resource, query}: QueryDefArgs) => createListQuery<TValues>({
  resource,
  query: {
    ...query,
    pageSize: initialPageSize,
    filter: {
      deleted: false,
    },
  },
})

type RevisionsProps<TResource extends keyof RevisionResource> = {
  resource: TResource
  query: Record<string, JSONValue>
  onRevisionClick: (revisionId: RevisionResource[TResource][number]['revisionId']) => void
}

const Revisions = <TResource extends keyof RevisionResource>({
  resource, query, onRevisionClick,
}: RevisionsProps<TResource>) => {
  const [page, setPage] = useState(0)
  const {data, isLoading} = useQuery({
    ...queryDef<RevisionResource[TResource]>({
      resource,
      query: {...query, page},
    }),
    suspense: false,
  })
  const revisions = (data?.data || []) as RevisionResource[TResource]
  return (
    <>
      <VisualRevisions
          title="Historie změn"
          actions={
            <If condition={(data?.total || 0) > 10}>
              <TablePagination
                  component="div"
                  rowsPerPage={10}
                  rowsPerPageOptions={[10]}
                  count={data?.total || 0}
                  page={page}
                  onPageChange={(_e, page) => setPage(page)}
              />
            </If>
          }
      >
        <Choose>
          <When condition={isLoading}>
            {map(range(initialPageSize), (id) => (
              <VisualRevisions.Item
                  key={id}
                  date={<Skeleton width="10ch" />}
                  user={<Skeleton width="20ch" />}
                  message={(
                    <Stack width="100%">
                      <Skeleton width="100%" variant="text" />
                      <Skeleton width="80%" variant="text" />
                    </Stack>
                  )}
                  action={<div />}
              />
            ))}
          </When>
          <Otherwise>
            {revisions.map((revision) => (
              <VisualRevisions.Item
                  key={revision.revisionId}
                  date={format(new Date(revision.revisionCreatedAt), 'dd/MM/yyyy')}
                  user={`${revision.revisionUser.firstName} ${revision.revisionUser.lastName}`}
                  message={revision.revisionNote || '–'}
                  action={(
                    <IconButton edge="end" onClick={() => onRevisionClick(revision.revisionId)}>
                      <VisibilityIcon />
                    </IconButton>
                  )}
              />
            ))}
          </Otherwise>
        </Choose>
      </VisualRevisions>
    </>
  )
}

export default Revisions
