import {MANAGE_FOREIGN_CARRIERS} from 'constants/permissions'
import * as resources from 'constants/resources'
import {omit} from 'lodash'
import type {ComponentProps} from 'react'
import {useNavigate} from 'react-router-dom'
import * as clientRoutes from '../../../constants/routes'
import {useCreateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import usePermitted from '../../../hooks/usePermitted'
import generatePath from '../../../utils/generatePath'
import AsymetricLayout from '../../containers/AsymetricLayout'
import Breadcrumbs from '../../containers/Breadcrumbs'
import ConfirmModal from '../../containers/ConfirmModal'
import FormBlocker from '../../containers/FormBlocker'
import FormErrorAlert from '../../containers/alerts/FormErrorAlert'
import TextInput from '../../form/TextInput'
import {ScreenHeader, ScreenWrapper} from '../../visual'
import ForeignCarriersForm from './ForeignCarriersForm'


const ForeignCarriersCreate = () => {
  usePermitted([MANAGE_FOREIGN_CARRIERS], {renderNotFound: true})
  const createForeignCarriers = useCreateResource({resource: resources.FOREIGN_CARRIERS})
  const showAlert = useAlert()
  const navigate = useNavigate()

  const handleCreateForeignCarriers: ComponentProps<typeof ForeignCarriersForm>['onSubmit'] = async (values) => {
    const {data} = await createForeignCarriers.mutateAsync(omit(values, 'isCarrier'))

    navigate(generatePath(clientRoutes.FOREIGN_CARRIERS_EDIT, {foreignCarrierId: data.id}))
    showAlert(`Zahraniční dopravce ${data.id} vytvořen`, 'success')
  }

  return (
    <ForeignCarriersForm onSubmit={handleCreateForeignCarriers}>
      {({form, valid, handleSubmit}) => (
        <ScreenWrapper>
          <FormBlocker />
          <ScreenHeader
              title="Vytvořit zahraničního dopravce"
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item>Adresář</Breadcrumbs.Item>
                  <Breadcrumbs.Item to={clientRoutes.FOREIGN_CARRIERS}>Zahraniční dopravci</Breadcrumbs.Item>
                  <Breadcrumbs.Item active>Vytvořit zahraničního dopravce</Breadcrumbs.Item>
                </Breadcrumbs>
              }
          >
            <ConfirmModal
                title="Přejete si uložit změnu údajů?"
                valid={valid}
                onConfirm={handleSubmit}
            >
              <p>
                Chystáte se uložit veškeré změny, které byly na kartě Zahraniční dopravci provedeny.
                Detail změn si budete moci zobrazit v historii zahraničního dopravce.
                Veškeré údaje lze kdykoli opět přepsat.
              </p>
              <TextInput name="revisionNote" label="Zpráva" multiline />
            </ConfirmModal>
          </ScreenHeader>
          <FormErrorAlert />
          <AsymetricLayout>
            <AsymetricLayout.Items>
              {form}
            </AsymetricLayout.Items>
          </AsymetricLayout>
        </ScreenWrapper>
      )}
    </ForeignCarriersForm>
  )
}

export default ForeignCarriersCreate
