import {Chip} from '@mui/material'
import type {CellContext} from '@tanstack/react-table'
import type {Resource} from 'constants/resources'
import {generateResourcePath} from 'constants/routes'
import {get, isArray, isEmpty, map} from 'lodash'
import type {ReactNode} from 'react'
import Link from '../../Link'
import Fallback from './Fallback'


const defaultRender = (value: unknown) => <>{value}</>

type RelationalCell = {
  resource: Resource
  id: string
  render?: (value: unknown, original: unknown) => ReactNode
  fallback?: ReactNode
}

const relationalCell = ({resource, id, render = defaultRender, fallback = '–'}: RelationalCell) => {
  const renderRelationalCell = <TData, TValue>(
    {getValue, row}: CellContext<TData, TValue>,
  ) => {
    const value = getValue()

    if (!value) return <Fallback>{fallback}</Fallback>

    if (isArray(value)) {
      if (isEmpty(value)) return <Fallback>{fallback}</Fallback>
      return map(value, ((value) => {
        const path = generateResourcePath(resource, get(value, id) as string)
        return (
          <Chip
              key={path}
              label={
                <Link to={path} onClick={(e) => e.stopPropagation()} underline="always">
                  {render(value, row?.original || {})}
                </Link>
              }
          />
        )
      }))
    }

    const path = generateResourcePath(resource, get(row?.original, id) as string)
    return (
      <Chip
          label={
            <Link to={path} onClick={(e) => e.stopPropagation()} underline="always">
              {render(value, row?.original || {})}
            </Link>
          }
      />
    )
  }

  return renderRelationalCell
}

export default relationalCell
