import {isFunction} from 'lodash-es'
import {useCallback} from 'react'


type Fn = (...args: unknown[]) => unknown

const useCombinedHandlers = (...handlers: (Fn | undefined)[]) => {
  return useCallback((...args: unknown[]) => {
    return handlers.filter(isFunction).reduce((_, handler: Fn) => handler(...args), null)
  }, // We check whether the functions in the array have changed, not the elements of the array
  handlers) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useCombinedHandlers
