import FindInPageIcon from '@mui/icons-material/FindInPage'
import {useNavigate} from 'react-router'
import {ErrorScreen} from '../visual/'


const NotFound = () => {
  const navigate = useNavigate()
  const handleBack = () => {
    navigate(-1)
  }

  return (
    <ErrorScreen
        Icon={FindInPageIcon}
        title="Nenalezeno"
        buttonLabel="Domů"
        onClick={handleBack}
    >
      <div>Zadali jste špatnou adresu URL nebo link...</div>
    </ErrorScreen>
  )
}

export default NotFound
