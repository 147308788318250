import CloseIcon from '@mui/icons-material/Close'
import {useQuery} from '@tanstack/react-query'
import type {GetSupervisionResponseSchema} from 'common/responses'
import {map, noop} from 'lodash'
import type {FC} from 'react'
import * as resources from '../../../../../constants/resources'
import {createOneQuery} from '../../../hooks/api'
import Button from '../../containers/Button'
import {Modal} from '../../visual'
import SupervisionsForm from './SupervisionsForm'


export const queryDef = ({supervisionId}: {supervisionId: number}) => createOneQuery<GetSupervisionResponseSchema>({
  resource: resources.SUPERVISIONS,
  id: supervisionId,
})

type SupervisionsEditProps = {
  supervisionId: number
  handleClose: () => void
}

const SupervisionsEdit: FC<SupervisionsEditProps> = ({supervisionId, handleClose}) => {
  const {data} = useQuery({...queryDef({supervisionId}), suspense: false})
  const supervision = data?.data
  if (!supervision) return null

  return (
    <SupervisionsForm
        initialValues={{
          ...supervision,
          userIds: map(supervision.users, 'id'),
        }}
        onSubmit={noop}
    >
      {({form}) => (
        <Modal open onClose={handleClose}>
          <Modal.Title>Státní dozor {supervisionId}</Modal.Title>
          <Modal.Content>
            {form}
          </Modal.Content>
          <Modal.Actions>
            <Button color="neutral" icon={<CloseIcon />} onClick={handleClose}>Zavřít</Button>
          </Modal.Actions>
        </Modal>
      )}
    </SupervisionsForm>
  )
}

export default SupervisionsEdit
