import {TableCell, TableHead, TableSortLabel} from '@mui/material'
import type {RowData, Table} from '@tanstack/react-table'
import {flexRender} from '@tanstack/react-table'
import {TableRow} from '../../visual'


type DatagridHeadProps<TData> = {
  table: Table<TData>
}

const DatagridHead = <TData extends RowData>({table}: DatagridHeadProps<TData>) => (
  <TableHead>
    {table.getHeaderGroups().map((headerGroup) => (
      <TableRow key={headerGroup.id}>
        {headerGroup.headers.map((header) => (
          <TableCell
              variant="head"
              key={header.id}
              padding={header.column.columnDef.meta?.padding || 'normal'}
              sortDirection={
                header.column.getCanSort() ? header.column.getIsSorted() : false
              }
          >
            <TableSortLabel
                active={Boolean(header.column.getIsSorted())}
                direction={header.column.getIsSorted() || undefined}
                disabled={!(header.column.columnDef.meta?.hasAction || header.column.getCanSort())}
                onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                hideSortIcon={Boolean(header.column.columnDef.meta?.hasAction)}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    ))}
  </TableHead>
)

export default DatagridHead
