import {Divider, Typography} from '@mui/material'
import * as enums from 'common/labels/enums'
import type {ListCompanyResponseSchema} from 'common/responses'
import * as resources from 'constants/resources'
import type {FC} from 'react'
import BooleanInput from '../../../form/BooleanInput'
import DateInput from '../../../form/DateInput'
import EnumInput from '../../../form/EnumInput'
import ReferenceInput from '../../../form/ReferenceInput'
import TextInput from '../../../form/TextInput'
import {InputStack, Tabs} from '../../../visual'


export const GeneralPanelFieldsGeneral = ({readOnly}: {readOnly?: boolean}) => {
  return (
    <>
      <InputStack>
        <DateInput name="validFrom" label="Platnost od" readOnly={readOnly} />
      </InputStack>
      <InputStack>
        <EnumInput name="trackType" label="Kategorie dráhy" enumName={enums.TRACK_TYPES} required readOnly={readOnly} />
        <EnumInput name="transportType" label="Typ dopravy" enumName={enums.TRANSPORT_TYPES} required readOnly={readOnly} />
      </InputStack>
      <InputStack>
        <EnumInput name="transportCategory" label="Kategorie dopravy" enumName={enums.TRANSPORT_CATEGORIES} required readOnly={readOnly} />
        <ReferenceInput<ListCompanyResponseSchema>
            name="carrierId"
            optionText={({id, name}) => `${id} (${name})`}
            label="Dopravce"
            resource={resources.COMPANIES}
            readOnly={readOnly}
            required
        />
      </InputStack>
    </>
  )
}

export const GeneralPanelFieldsIssueMethod = ({readOnly}: {readOnly?: boolean}) => {
  return (
    <BooleanInput name="newLaw" label="Licence je vydána dle nového zákona" readOnly={readOnly} />
  )
}

export const GeneralPanelFieldsNote = ({readOnly}: {readOnly?: boolean}) => {
  return (
    <TextInput name="notes" label="Poznámka" placeholder="Zadejte libovolnou poznámku" minRows={5} multiline readOnly={readOnly} />
  )
}

type GeneralPanelProps = {
  readOnly?: boolean
}

const GeneralPanel: FC<GeneralPanelProps> = ({readOnly}) => {
  return (
    <>
      <Tabs.Section>
        <GeneralPanelFieldsGeneral readOnly={readOnly} />
      </Tabs.Section>
      <Divider />
      <Tabs.Section>
        <Typography fontWeight="bold">Způsob vydání licence</Typography>
        <GeneralPanelFieldsIssueMethod readOnly={readOnly} />
      </Tabs.Section>
      <Divider />
      <Tabs.Section>
        <Typography fontWeight="bold">Poznámka</Typography>
        <GeneralPanelFieldsNote readOnly={readOnly} />
      </Tabs.Section>
    </>
  )
}

export const GENERAL_PANEL_LABEL = 'Základní údaje'
export default GeneralPanel
