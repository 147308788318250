import {useState, useEffect} from 'react'


const useDebouncedValue = <TValue>(value: TValue, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState<TValue | null>(null)

  useEffect(
    () => {
      if (value === debouncedValue) return
      const handler = window.setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      return () => {
        window.clearTimeout(handler)
      }
    },
    [value, delay, debouncedValue],
  )

  return debouncedValue
}

export default useDebouncedValue
