import {Stack} from '@mui/material'
import * as schemas from 'common/schemas'
import {isEmpty} from 'lodash'
import PasswordInput from '../../form/PasswordInput'
import {showErrorOnChange} from '../../form/forms'
import withForm from '../../form/withForm'


const schema = schemas.object({
  oldPassword: schemas.requiredString(),
  password: schemas.requiredString(),
  confirmPassword: schemas.requiredString(),
})

const ProfilePasswordChangeForm = withForm({schema})(() => (
  <Stack gap={2}>
    <PasswordInput
        name="oldPassword"
        label="Původní heslo"
        margin="dense"
        fieldProps={{
          validate: (oldPassword, {password}: {password: string}) => {
            if (!isEmpty(password) && isEmpty(oldPassword)) {
              return 'Při změně hesla je nejprve třeba zadat staré heslo'
            }
          },
        }}
        required
    />
    <PasswordInput
        name="password"
        label="Nové heslo"
        autoComplete="new-password"
        showError={showErrorOnChange}
        margin="dense"
        required
    />
    <PasswordInput
        name="confirmPassword"
        label="Nové heslo znovu"
        autoComplete="new-password"
        showError={showErrorOnChange}
        margin="dense"
        fieldProps={{
          validate: (confirmPassword, {password}: {password: string}) => {
            if (password !== confirmPassword) return 'Hesla se musí zhodovat'
          },
        }}
        required
    />
  </Stack>
))

export default ProfilePasswordChangeForm
