import * as schemas from 'common/schemas'
import {useState} from 'react'
import type {TypeOf} from 'zod'
import withForm from '../../form/withForm'
import {FormSection, Tabs} from '../../visual'
import GeneralPanel, {GENERAL_PANEL_LABEL} from './panels/GeneralPanel'
import StatutoryPanel, {STATUTORY_PANEL_LABEL} from './panels/StatutoryPanel'


const schema = schemas.company.company

const tabs = [GENERAL_PANEL_LABEL, STATUTORY_PANEL_LABEL]

const parse = (values: TypeOf<typeof schema>) => {
  let newValues = {...values}

  if ('isCarrier' in values && !values.isCarrier) {
    newValues = {...newValues, carrierCertificate: null, carrierValidUntil: null}
  }

  return newValues
}

type CompaniesFormProps = {
  values?: {
    isCarrier: boolean
  }
  readOnly?: boolean
}

const CompaniesForm = withForm({schema, parse})<CompaniesFormProps>(({values, readOnly}) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <FormSection title="Detail firmy">
      <Tabs
          labels={tabs}
          value={activeTab}
          onChange={(_e, value: number) => setActiveTab(value)}
      >
        <Tabs.Panel value={activeTab} index={0}>
          <GeneralPanel isCarrier={Boolean(values?.isCarrier)} readOnly={readOnly} />
        </Tabs.Panel>
        <Tabs.Panel value={activeTab} index={1}>
          <StatutoryPanel readOnly={readOnly} />
        </Tabs.Panel>
      </Tabs>
    </FormSection>
  )
})

export default CompaniesForm
