import {FormControl, FormControlLabel, FormHelperText, Switch as MuiSwitch} from '@mui/material'
import type {ComponentProps, FC, ReactNode} from 'react'


type SwitchProps = ComponentProps<typeof MuiSwitch> & {
  label?: string
  helperText?: ReactNode,
  fullWidth?: boolean,
  error?: boolean,
  required?: boolean,
  checked?: boolean,
}

const Switch: FC<SwitchProps> = ({label, onChange, helperText, fullWidth, error, required, checked, ...props}) => {
  return (
    <FormControl
        fullWidth={fullWidth}
        error={error}
        required={required}
    >
      <FormControlLabel
          label={label ? label : null}
          control={
            <MuiSwitch
                onChange={onChange}
                checked={checked}
                {...props}
            />
          }
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default Switch
