import SaveIcon from '@mui/icons-material/Save'
import {Tooltip} from '@mui/material'
import type {FC, ReactNode} from 'react'
import {useState} from 'react'
import {Modal} from '../visual'
import Button from './Button'


type ConfirmModalProps = {
  title: ReactNode
  buttonText?: ReactNode
  buttonIcon?: ReactNode
  saveText?: ReactNode
  closeText?: ReactNode
  valid?: boolean
  disabled?: boolean
  tooltip?: string
  onConfirm: () => void
  children: ReactNode
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  title,
  buttonText = 'Uložit změny',
  buttonIcon = <SaveIcon />,
  saveText = 'Potvrdit uložení',
  closeText = 'Zrušit',
  valid = true,
  disabled,
  tooltip,
  onConfirm,
  children,
}) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    if (!valid) return onConfirm()
    setOpen(true)
  }

  return (
    <>
      <Tooltip title={tooltip}>
        <div>
          <Button
              variant="contained"
              icon={buttonIcon}
              onClick={handleClick}
              disabled={disabled}
              fullWidth
          >
            {buttonText}
          </Button>
        </div>
      </Tooltip>
      <Modal open={open}>
        <Modal.Title>{title}</Modal.Title>
        <Modal.Content>
          {children}
        </Modal.Content>
        <Modal.Actions>
          <Button
              variant="contained"
              onClick={() => {
                setOpen(false)
                onConfirm()
              }}
          >
            {saveText}
          </Button>
          <Button onClick={() => setOpen(false)}>{closeText}</Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default ConfirmModal
