import {hasPermissions} from 'common/auth'
import type {Permission} from 'constants/permissions'
import {useSession} from './auth'


class RouteError extends Error {
  status: number
  statusText: string
  internal: boolean
  data: string
}

type RouteErrorOptions = {
  statusText?: string
  status?: number
}

const throwRouteError = ({statusText = 'Not Found', status = 404}: RouteErrorOptions = {}) => {
  const error = new RouteError()
  error.status = status
  error.statusText = statusText
  error.internal = true
  error.data = ''
  throw error
}

type Options = {
  renderNotFound?: boolean
}

const usePermitted = (permissions: Permission[], options: Options = {renderNotFound: false}) => {
  const session = useSession()
  const permitted = hasPermissions(session?.permissions ? session.permissions : [], permissions)

  if (!permitted && options.renderNotFound) {
    throw throwRouteError()
  }

  return permitted
}

export default usePermitted
