import {Stack} from '@mui/material'
import type {ComponentProps, FC, ReactNode} from 'react'


type InputStackProps = ComponentProps<typeof Stack> & {
  children: ReactNode
}

const InputStack: FC<InputStackProps> = ({gap = 2, children, ...props}) => {
  return (
    <Stack
        useFlexGap
        gap={gap}
        direction={{xs: 'column', md: 'row'}}
        alignItems={{xs: 'stretch', md: 'flex-start'}}
        width="100%"
        {...props}
    >
      {children}
    </Stack>
  )
}


export default InputStack
