import * as permitTypes from 'constants/permitTypes'


export default {
  [permitTypes.LOCAL]: 'Místní dráha',
  [permitTypes.REGIONAL]: 'Regionální dráha',
  [permitTypes.NATIONAL]: 'Celostátní dráha',
  [permitTypes.SIDING]: 'Vlečka',
  [permitTypes.TEST]: 'Zkušební dráha',
} as const
