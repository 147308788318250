import AddCircleIcon from '@mui/icons-material/AddCircle'
import {useQuery} from '@tanstack/react-query'
import {If} from 'babel-plugin-jsx-control-statements'
import * as enums from 'common/labels/enums'
import type {ListLicenseResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import {MANAGE_LICENSES} from 'constants/permissions'
import * as resources from 'constants/resources'
import {ALL_TRACK_TYPES} from 'constants/trackTypes'
import {ALL_TRANSPORT_CATEGORIES} from 'constants/transportCategories'
import {ALL_TRANSPORT_TYPES} from 'constants/transportTypes'
import type {TypeOf, ZodObject} from 'zod'
import * as clientRoutes from '../../../constants/routes'
import {createListQuery} from '../../../hooks/api'
import usePermitted from '../../../hooks/usePermitted'
import useQueryParams from '../../../hooks/useQueryParams'
import {queryToSort} from '../../../utils/lists'
import Breadcrumbs from '../../containers/Breadcrumbs'
import Button from '../../containers/Button'
import ExportButton from '../../containers/ExportButton'
import Filters from '../../containers/Filters'
import Link from '../../containers/Link'
import DateInput from '../../form/DateInput'
import EnumInput from '../../form/EnumInput'
import IndeterminateCheckbox from '../../form/IndeterminateCheckbox'
import SearchBoxInput from '../../form/SearchBoxInput'
import {InputStack, ScreenHeader, ScreenWrapper} from '../../visual'
import NotFound from '../NotFound'
import LicensesTable, {sortableColumns} from './LicensesTable'


const schema = {
  page: schemas.nonNegativeInteger().default(1),
  pageSize: schemas.positiveInteger().default(10).catch(10),
  sortBy: schemas.createEnum(sortableColumns).default('id').catch('id'),
  sortDirection: schemas.createEnum(['asc', 'desc']).default('desc').catch('desc'),
  filter: schemas.optionalObject({
    q: schemas.requiredString().optional(),
    trackType: schemas.oneOrMany(schemas.createEnum(ALL_TRACK_TYPES)).optional(),
    transportType: schemas.oneOrMany(schemas.createEnum(ALL_TRANSPORT_TYPES)).optional(),
    transportCategory: schemas.oneOrMany(schemas.createEnum(ALL_TRANSPORT_CATEGORIES)).optional(),
    newLaw: schemas.boolean().optional(),
    validFrom: schemas.timestamp().nullable().optional(),
    validTo: schemas.timestamp().nullable().optional(),
  }).optional().catch({}),
}

const queryDef = ({query}: {query: TypeOf<ZodObject<typeof schema>>}) => createListQuery<ListLicenseResponseSchema>({
  resource: resources.LICENSES,
  query: {
    ...queryToSort(query),
    page: query.page - 1,
    pageSize: query.pageSize,
    filter: {
      ...query?.filter,
      valid: query?.filter?.validFrom && query?.filter?.validTo
        ? [query?.filter?.validFrom, query?.filter?.validTo]
        : undefined,
    },
  },
})

const LicensesList = () => {
  const canCreate = usePermitted([MANAGE_LICENSES])
  const [query, setQuery] = useQueryParams(schema)
  const {data} = useQuery(queryDef({query}))
  const licenses = data?.data

  if (!licenses) return <NotFound />

  return (
    <ScreenWrapper>
      <ScreenHeader
          title="Licence"
          breadcrumbs={
            <Breadcrumbs>
              <Breadcrumbs.Item>Evidence vleček</Breadcrumbs.Item>
              <Breadcrumbs.Item active to={clientRoutes.LICENSES}>Licence</Breadcrumbs.Item>
            </Breadcrumbs>
          }
      >
        <ExportButton resource={resources.LICENSES} query={query} />
        <If condition={canCreate}>
          <Link to={clientRoutes.LICENSES_CREATE}>
            <Button icon={<AddCircleIcon />} variant="contained" fullWidth>
              Přidat novou licenci
            </Button>
          </Link>
        </If>
      </ScreenHeader>

      <Filters query={query} setQuery={setQuery}>
        {({handleSubmit, handleReset}) => (
          <>
            <SearchBoxInput name="q" label="Hledat mezi licencemi" placeholder="Vyhledávání potvrďte Enterem" onApply={handleSubmit} />
            <Filters.Drawer alwaysVisibleFilters={['q']} query={query} onApply={handleSubmit} onReset={handleReset}>
              <EnumInput name="transportType" label="Typ dopravy" enumName={enums.TRANSPORT_TYPES} size="small" fullWidth={false} multiple />
              <EnumInput name="transportCategory" label="Kategorie dopravy" enumName={enums.TRANSPORT_CATEGORIES} size="small" fullWidth={false} multiple />
              <EnumInput name="trackType" label="Kategorie dráhy" enumName={enums.TRACK_TYPES} size="small" fullWidth={false} multiple />
              <InputStack>
                <DateInput name="validFrom" label="Platnost od" slotProps={{textField: {size: 'small'}}} fullWidth={false} />
                <DateInput name="validTo" label="Platnost do" slotProps={{textField: {size: 'small'}}} fullWidth={false} />
              </InputStack>
              <IndeterminateCheckbox name="newLaw" label="Nový zákon" fullWidth={false} />
            </Filters.Drawer>
          </>
        )}
      </Filters>

      <LicensesTable
          licenses={licenses}
          total={data.total}
          query={query}
          setQuery={setQuery}
      />
    </ScreenWrapper>
  )
}

export default LicensesList
