import {Stack} from '@mui/material'
import type {ComponentProps, FC} from 'react'


type ScreenWrapperProps = ComponentProps<typeof Stack>

const ScreenWrapper: FC<ScreenWrapperProps> = ({children, ...props}) => {
  return (
    <Stack useFlexGap gap={3} {...props}>
      {children}
    </Stack>
  )
}

export default ScreenWrapper
