import {Divider, Typography} from '@mui/material'
import {If} from 'babel-plugin-jsx-control-statements'
import type {ListSidingResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import * as resources from 'constants/resources'
import type {TypeOf} from 'zod'
import * as clientRoutes from '../../../../../constants/routes'
import BooleanInput from '../../../../form/BooleanInput'
import DateInput from '../../../../form/DateInput'
import GinisPidInput from '../../../../form/GinisPidInput'
import ReferenceInput from '../../../../form/ReferenceInput'
import TextInput from '../../../../form/TextInput'
import withForm from '../../../../form/withForm'
import {InputStack, Tabs} from '../../../../visual'


const schema = schemas.sidingCancellation.sidingCancellation.and(schemas.object({
  isRemoved: schemas.boolean().optional(),
  isPart: schemas.boolean().optional(),
}))

const formatRemoval = (values: TypeOf<typeof schema>) => ({isRemoved: Boolean(values.removedAt)})
const formatPart = (values: TypeOf<typeof schema>) => ({isPart: Boolean(values.cancelledPart)})

const format = (values: TypeOf<typeof schema>) => {
  return {
    ...values,
    ...formatRemoval(values),
    ...formatPart(values),
  }
}

const parseRemoval = (values: TypeOf<typeof schema>) => {
  if (values.isRemoved) return {}
  return {
    removalReferenceNumber: '',
    removalGinisPid: null,
    removedAt: null,
  }
}
const parsePart = (values: TypeOf<typeof schema>) => {
  if (values.isPart) return {}
  return {
    cancelledPart: null,
  }
}

const parse = (values: TypeOf<typeof schema>) => ({
  ...values,
  ...parseRemoval(values),
  ...parsePart(values),
})

export const SidingCancellationModalFieldsCancellation = (
  {isPart, readOnly}: {isPart?: boolean, readOnly?: boolean},
) => {
  return (
    <>
      <BooleanInput name="isPart" label="Zrušit pouze část vlečky" readOnly={readOnly} />
      <ReferenceInput<ListSidingResponseSchema>
          name="sidingId"
          optionText="name"
          label="Vlečka"
          resource={resources.SIDINGS}
          tagRoute={({value}) => `${clientRoutes.SIDINGS}/${value}`}
          readOnly
      />
      <InputStack>
        <DateInput name="cancelledAt" label="Datum zrušení" readOnly={readOnly} required />
        <TextInput name="referenceNumber" label="Spisová zn." readOnly={readOnly} required />
      </InputStack>
      <InputStack>
        <GinisPidInput name="ginisPid" label="GINIS PID" readOnly={readOnly} isNewRecord required />
        <If condition={isPart}>
          <TextInput name="cancelledPart" label="Úsek" readOnly={readOnly} />
        </If>
      </InputStack>
    </>
  )
}

export const SidingCancellationModalFieldsRemoval = (
  {isRemoved, readOnly}: {isRemoved?: boolean, readOnly?: boolean},
) => {
  return (
    <>
      <BooleanInput name="isRemoved" label="Proběhlo odstranění příslušného kolejiště" readOnly={readOnly} />
      <If condition={isRemoved}>
        <DateInput name="removedAt" label="Datum odstranění" readOnly={readOnly} />
        <InputStack>
          <TextInput name="removalReferenceNumber" label="Číslo jednací" readOnly={readOnly} />
          <GinisPidInput name="removalGinisPid" label="GINIS PID odstránení" readOnly={readOnly} isNewRecord />
        </InputStack>
      </If>
    </>
  )
}

type SidingCancellationModalFormProps = {
  readOnly?: boolean
}

const SidingCancellationModalForm = withForm({schema, format, parse})<SidingCancellationModalFormProps>((
  {values, readOnly},
) => {
  return (
    <Tabs.Section>
      <SidingCancellationModalFieldsCancellation isPart={values.isPart} readOnly={readOnly} />
      <Typography fontWeight="bold">Odstranění kolejiště</Typography>
      <SidingCancellationModalFieldsRemoval isRemoved={values.isRemoved} readOnly={readOnly} />
      <Divider />
      <TextInput name="revisionNote" label="Správa revize" readOnly={readOnly} />
    </Tabs.Section>
  )
})
export default SidingCancellationModalForm
