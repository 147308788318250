import {Accordion, AccordionDetails, AccordionSummary, Alert, Divider, Stack} from '@mui/material'
import {Choose, If, Otherwise, When} from 'babel-plugin-jsx-control-statements'
import type {GetSidingResponseSchema, ListPermitResponseSchema} from 'common/responses'
import * as resources from 'constants/resources'
import {map, reject} from 'lodash'
import type {FC} from 'react'
import {generatePath} from 'react-router'
import * as clientRoutes from '../../../../constants/routes'
import Button from '../../../containers/Button'
import Link from '../../../containers/Link'
import FormView from '../../../form/FormView'
import ReferenceInput from '../../../form/ReferenceInput'
import {GeneralPanelFieldsGeneral} from '../../permits/panels/GeneralPanel'


type PermitsPanelProps = {
  permit: GetSidingResponseSchema['permits'][number] | null
  permits?: GetSidingResponseSchema['permits']
  sidingId: GetSidingResponseSchema['id'] | null
  canCreatePermit?: boolean
}

const PermitsPanel: FC<PermitsPanelProps> = ({sidingId, permit: currentPermit, permits, canCreatePermit}) => {
  const permitsWithoutCurrent = reject(permits, {id: currentPermit?.id})

  return (
    <FormView values={{...currentPermit, permits: map(permitsWithoutCurrent, 'id')}}>
      <If condition={canCreatePermit}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <Link to={{pathname: clientRoutes.PERMITS_CREATE}} state={{sidingId}}>
            <Button>Nové úřední povolení</Button>
          </Link>
        </Stack>
      </If>
      <Choose>
        <When condition={currentPermit}>
          <Accordion expanded>
            <AccordionSummary>
              <Link to={generatePath(clientRoutes.PERMITS_EDIT, {permitId: String(currentPermit?.id)})}>
                Úřední povolení {currentPermit?.id}
              </Link>
            </AccordionSummary>
            <AccordionDetails>
              <GeneralPanelFieldsGeneral readOnly />
            </AccordionDetails>
          </Accordion>
        </When>
        <Otherwise>
          <Alert>Vlečka je neprovozovaná</Alert>
        </Otherwise>
      </Choose>
      <Divider />
      <ReferenceInput<ListPermitResponseSchema, true>
          name="permits"
          optionText={({id, company}) => `${id} (${company.name})`}
          label="Historicky přiřazená úřední povolení"
          resource={resources.PERMITS}
          tagRoute={resources.PERMITS}
          multiple
          readOnly
      />
    </FormView>
  )
}

export const PERMITS_PANEL_LABEL = 'Úřední povolení'
export default PermitsPanel
