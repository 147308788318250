import RemoveIcon from '@mui/icons-material/Remove'
import {Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import {keyBy} from 'lodash'
import type {ChangeEvent, ComponentProps, FC} from 'react'
import type {UseFieldConfig} from 'react-final-form'
import {useField} from 'react-final-form'
import {RadioGroup, StaticField, RadioGroupInput as VisualRadioGroupInput} from '../visual'
import type {ShowError} from './forms'
import {showErrorOnChange} from './forms'


type RadioGroupInputProps = ComponentProps<typeof VisualRadioGroupInput> & {
  name: string
  label?: string
  row?: boolean
  helperText?: string,
  showError?: ShowError
  options: {value: string | number, label: string}[],
  disableSpacing?: boolean,
  fieldProps?: UseFieldConfig<string, string>,
}

const RadioGroupInput: FC<RadioGroupInputProps> = ({
  disableSpacing, options, readOnly, name, label, row, required, disabled, helperText,
  showError = showErrorOnChange, fieldProps, ...props
}) => {
  const {input: {value, onChange, ...restInput}, meta} = useField<string | number>(name, fieldProps)
  const {isError, helperTextOrError} = showError({meta, helperText})

  const optionsByValue = keyBy(options, 'value')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e)
  }


  return (
    <Choose>
      <When condition={!readOnly}>
        <RadioGroup
            name={name}
            error={isError}
            required={required}
            disabled={disabled}
            value={value}
            onChange={handleChange}
            row={row}
            label={label}
            helperText={helperTextOrError}
        >
          {options.map((option) => (
            <VisualRadioGroupInput
                label={option.label}
                value={option.value}
                inputProps={{required, ...restInput}}
                checked={value === option?.value}
                key={option.value}
                {...props}
            />
          ))}
        </RadioGroup>
      </When>
      <Otherwise>
        <StaticField label={label} disableSpacing={disableSpacing}>
          <Choose>
            <When condition={optionsByValue[value]?.label}>
              {optionsByValue[value]?.label}
            </When>
            <Otherwise>
              <RemoveIcon />
            </Otherwise>
          </Choose>
        </StaticField>
      </Otherwise>
    </Choose>
  )
}

export default RadioGroupInput
