import {Stack} from '@mui/material'
import * as enums from 'common/labels/enums'
import type {ListCompanyResponseSchema, ListUserResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import * as resources from 'constants/resources'
import * as clientRoutes from '../../../constants/routes'
import DateInput from '../../form/DateInput'
import EnumInput from '../../form/EnumInput'
import GinisPidInput from '../../form/GinisPidInput'
import ReferenceInput from '../../form/ReferenceInput'
import TextInput from '../../form/TextInput'
import withForm from '../../form/withForm'
import {InputStack} from '../../visual'


const schema = schemas.supervision.supervision

type SupervisionsFormProps = {
  canChangeDepartment: boolean
}

const SupervisionsForm = withForm({schema})<SupervisionsFormProps>(({initialValues, canChangeDepartment}) => {
  const isNewRecord = !('id' in initialValues)
  return (
    <Stack useFlexGap gap={2}>
      <InputStack>
        <ReferenceInput<ListCompanyResponseSchema>
            name="sidingId"
            label="Vlečka"
            optionText="name"
            resource={resources.SIDINGS}
            tagRoute={(option) => `${clientRoutes.SIDINGS}/${option.value}`}
            readOnly
        />
      </InputStack>
      <InputStack>
        <ReferenceInput<ListCompanyResponseSchema>
            name="companyId"
            label="Kontrolovaný subjekt"
            optionText="name"
            resource={resources.COMPANIES}
            tagRoute={(option) => `${clientRoutes.COMPANIES}/${option.value}`}
            readOnly={!isNewRecord}
        />
        <DateInput name="supervisedAt" label="Výkon státního dozoru" readOnly={!isNewRecord} required />
      </InputStack>
      <InputStack>
        <EnumInput name="department" label="Vydala sekce" enumName={enums.DEPARTMENTS} readOnly={!isNewRecord || !canChangeDepartment} />
        <TextInput name="referenceNumber" label="Spisová zn." readOnly={!isNewRecord} required />
      </InputStack>
      <InputStack>
        <ReferenceInput<ListUserResponseSchema, true>
            name="userIds"
            label="Oprávněná úřední osoba"
            optionText={(option) => `${option.firstName} ${option.lastName}`}
            resource={resources.USERS}
            tagRoute={(option) => `${clientRoutes.USERS}/${option.value}`}
            readOnly={!isNewRecord}
            multiple
            required
        />
        <GinisPidInput name="ginisPid" label="PID GINIS" required isNewRecord={isNewRecord} readOnly={!isNewRecord} />
      </InputStack>
    </Stack>
  )
})

export default SupervisionsForm
