import * as closedArealTypes from 'constants/closedArealTypes'
import {MANAGE_SIDINGS} from 'constants/permissions'
import * as resources from 'constants/resources'
import {omit} from 'lodash'
import type {ComponentProps} from 'react'
import {useNavigate} from 'react-router-dom'
import * as clientRoutes from '../../../constants/routes'
import {useCreateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import usePermitted from '../../../hooks/usePermitted'
import generatePath from '../../../utils/generatePath'
import AsymetricLayout from '../../containers/AsymetricLayout'
import Breadcrumbs from '../../containers/Breadcrumbs'
import ConfirmModal from '../../containers/ConfirmModal'
import FormBlocker from '../../containers/FormBlocker'
import FormErrorAlert from '../../containers/alerts/FormErrorAlert'
import TextInput from '../../form/TextInput'
import {ScreenHeader, ScreenWrapper} from '../../visual'
import SidingsForm from './SidingsForm'


const SidingsCreate = () => {
  usePermitted([MANAGE_SIDINGS], {renderNotFound: true})
  const createSidings = useCreateResource({resource: resources.SIDINGS})
  const showAlert = useAlert()
  const navigate = useNavigate()

  const handleCreateSidings: ComponentProps<typeof SidingsForm>['onSubmit'] = async (values) => {
    const {data} = await createSidings.mutateAsync(omit(values, 'isCarrier'))

    navigate(generatePath(clientRoutes.SIDINGS_EDIT, {sidingId: data.id}))
    showAlert(`Vlečka ${data.id} vytvořena`, 'success')
  }

  return (
    <SidingsForm
        initialValues={{
          closedAreal: closedArealTypes.PARTIALLY,
          hasTrolley: false,
          coordinates: null,
        }}
        onSubmit={handleCreateSidings}
    >
      {({form, valid, handleSubmit}) => (
        <ScreenWrapper>
          <FormBlocker />
          <ScreenHeader
              title="Vytvořit vlečku"
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item>Evidence vleček</Breadcrumbs.Item>
                  <Breadcrumbs.Item to={clientRoutes.SIDINGS}>Vlečky</Breadcrumbs.Item>
                  <Breadcrumbs.Item active>Vytvořit vlečku</Breadcrumbs.Item>
                </Breadcrumbs>
              }
          >
            <ConfirmModal
                title="Přejete si uložit změnu údajů?"
                valid={valid}
                onConfirm={handleSubmit}
            >
              <p>
                Chystáte se uložit veškeré změny, které byly na kartě Vlečka provedeny.
                Detail změn si budete moci zobrazit v historii vlečky. Veškeré údaje lze kdykoli opět přepsat.
              </p>
              <TextInput name="revisionNote" label="Zpráva" multiline />
            </ConfirmModal>
          </ScreenHeader>
          <FormErrorAlert />
          <AsymetricLayout>
            <AsymetricLayout.Items>
              {form}
            </AsymetricLayout.Items>
          </AsymetricLayout>
        </ScreenWrapper>
      )}
    </SidingsForm>
  )
}

export default SidingsCreate
