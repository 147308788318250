import type {Theme} from '@mui/material'
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery} from '@mui/material'
import type {ComponentProps, FC} from 'react'


type ModalProps = ComponentProps<typeof Dialog>

const Modal: FC<ModalProps> = ({open, onClose, children, ...props}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  return (
    <Dialog fullWidth fullScreen={isMobile} open={open} onClose={onClose} {...props}>
      {children}
    </Dialog>
  )
}

export default Object.assign(Modal, {
  Title: DialogTitle,
  Content: DialogContent,
  ContentText: DialogContentText,
  Actions: DialogActions,
})
