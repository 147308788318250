import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded'
import DirectionsRailwayRoundedIcon from '@mui/icons-material/DirectionsRailwayRounded'
import GavelRoundedIcon from '@mui/icons-material/GavelRounded'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import LocalPoliceRoundedIcon from '@mui/icons-material/LocalPoliceRounded'
import PeopleIcon from '@mui/icons-material/People'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded'
import type {Theme} from '@mui/material'
import {useMediaQuery} from '@mui/material'
import {isObject} from 'lodash'
import type {FC, ReactNode} from 'react'
import {Navigate, Route, createRoutesFromElements, isRouteErrorResponse, useOutlet, useRouteError} from 'react-router'
import {NavLink} from 'react-router-dom'
import * as clientRoutes from '../../constants/routes'
import {useMenu} from '../../hooks/useMenu'
import Screen from '../containers/Screen'
import {ContentContainer, Navigation, NavigationItem, NavigationList, NestedNavigationItem, NestedNavigationList} from '../visual'
import NotFound from './NotFound'
import CompaniesCreate from './companies/CompaniesCreate'
import CompaniesEdit from './companies/CompaniesEdit'
import CompaniesList from './companies/CompaniesList'
import ForeignCarriersCreate from './foreignCarriers/ForeignCarriersCreate'
import ForeignCarriersEdit from './foreignCarriers/ForeignCarriersEdit'
import ForeignCarriersList from './foreignCarriers/ForeignCarriersList'
import LicensesCreate from './licenses/LicensesCreate'
import LicensesEdit from './licenses/LicensesEdit'
import LicensesList from './licenses/LicensesList'
import PermitsCreate from './permits/PermitsCreate'
import PermitsEdit from './permits/PermitsEdit'
import PermitsList from './permits/PertmitsList'
import ProfileEdit from './profile/ProfileEdit'
import RolesList from './roles/RolesList'
import SidingsCreate from './sidings/SidingsCreate'
import SidingsEdit from './sidings/SidingsEdit'
import SidingsList from './sidings/SidingsList'
import SupervisionsList from './supervisions/SupervisionsList'
import UsersCreate from './users/UsersCreate'
import UsersEdit from './users/UsersEdit'
import UsersList from './users/UsersList'


type LayoutProps = {
  children?: ReactNode
}

const Layout: FC<LayoutProps> = ({children}) => {
  const outlet = useOutlet()
  const {openMenu, setOpenMenu} = useMenu()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const handleClose = () => setOpenMenu(false)
  const handleCloseOnMobile = () => isMobile && setOpenMenu(false)

  return (
    <ContentContainer>
      <Navigation open={openMenu} onClose={handleClose}>
        <NavigationList open={openMenu}>
          <NavLink to={clientRoutes.SIDINGS} onClick={handleCloseOnMobile}>
            {({isActive}) => (
              <NavigationItem selected={isActive} icon={<DirectionsRailwayRoundedIcon />}>
                Vlečky
              </NavigationItem>
            )}
          </NavLink>
          <NavLink to={clientRoutes.SUPERVISIONS} onClick={handleCloseOnMobile}>
            {({isActive}) => (
              <NavigationItem selected={isActive} icon={<WorkspacePremiumRoundedIcon />}>
                Státní dozory
              </NavigationItem>
            )}
          </NavLink>
          <NavLink to={clientRoutes.PERMITS} onClick={handleCloseOnMobile}>
            {({isActive}) => (
              <NavigationItem selected={isActive} icon={<GavelRoundedIcon />}>
                Úřední povolení
              </NavigationItem>
            )}
          </NavLink>
          <NavLink to={clientRoutes.LICENSES} onClick={handleCloseOnMobile}>
            {({isActive}) => (
              <NavigationItem selected={isActive} icon={<LocalPoliceRoundedIcon />}>
                Licence
              </NavigationItem>
            )}
          </NavLink>
          <NestedNavigationList text="Adresář" icon={<LibraryBooksIcon />}>
            <NavLink to={clientRoutes.COMPANIES} onClick={handleCloseOnMobile}>
              {({isActive}) => (
                <NestedNavigationItem selected={isActive} icon={<BusinessRoundedIcon />}>
                  Firmy
                </NestedNavigationItem>
              )}
            </NavLink>
            <NavLink to={clientRoutes.FOREIGN_CARRIERS} onClick={handleCloseOnMobile}>
              {({isActive}) => (
                <NestedNavigationItem selected={isActive} icon={<PublicRoundedIcon />}>
                  Zahraniční dopravci
                </NestedNavigationItem>
              )}
            </NavLink>
          </NestedNavigationList>
          <NestedNavigationList text="Administrace" icon={<SettingsApplicationsIcon />}>
            <NavLink to={clientRoutes.USERS} onClick={handleCloseOnMobile}>
              {({isActive}) => (
                <NestedNavigationItem selected={isActive} icon={<PeopleIcon />}>
                  Uživatelé
                </NestedNavigationItem>
              )}
            </NavLink>
          </NestedNavigationList>
        </NavigationList>
      </Navigation>
      <Screen>
        {outlet || children}
      </Screen>
    </ContentContainer>
  )
}

const isRedirect = (error: unknown): error is Response => {
  return isObject(error) && 'status' in error && error.status === 301
}

/**
 * Prevent react-router from swallowing errors and pass them to AuthProvider ErrorBoundary
*/
const ErrorBoundary = () => {
  const error = useRouteError()

  if (!error) return null

  if (isRedirect(error)) {
    return <Navigate to={error.headers.get('location') || '/'} replace />
  }

  if (isRouteErrorResponse(error) && error.status === 404) {
    return (
      <Layout>
        <NotFound />
      </Layout>
    )
  }

  throw error
}

export default () => createRoutesFromElements(
  <Route path={clientRoutes.HOME} element={<Layout />} errorElement={<ErrorBoundary />}>
    <Route index element={<Navigate to={clientRoutes.SIDINGS} />} />

    <Route path={clientRoutes.SIDINGS}>
      <Route index element={<SidingsList />} />
      <Route path={clientRoutes.SIDINGS_CREATE} element={<SidingsCreate />} />
      <Route path={clientRoutes.SIDINGS_EDIT} element={<SidingsEdit />} />
    </Route>

    <Route path={clientRoutes.SUPERVISIONS}>
      <Route index element={<SupervisionsList />} />
    </Route>

    <Route path={clientRoutes.PERMITS}>
      <Route index element={<PermitsList />} />
      <Route path={clientRoutes.PERMITS_CREATE} element={<PermitsCreate />} />
      <Route path={clientRoutes.PERMITS_EDIT} element={<PermitsEdit />} />
    </Route>

    <Route path={clientRoutes.LICENSES}>
      <Route index element={<LicensesList />} />
      <Route path={clientRoutes.LICENSES_CREATE} element={<LicensesCreate />} />
      <Route path={clientRoutes.LICENSES_EDIT} element={<LicensesEdit />} />
    </Route>

    <Route path={clientRoutes.COMPANIES}>
      <Route index element={<CompaniesList />} />
      <Route path={clientRoutes.COMPANIES_CREATE} element={<CompaniesCreate />} />
      <Route path={clientRoutes.COMPANIES_EDIT} element={<CompaniesEdit />} />
    </Route>

    <Route path={clientRoutes.FOREIGN_CARRIERS}>
      <Route index element={<ForeignCarriersList />} />
      <Route path={clientRoutes.FOREIGN_CARRIERS_CREATE} element={<ForeignCarriersCreate />} />
      <Route path={clientRoutes.FOREIGN_CARRIERS_EDIT} element={<ForeignCarriersEdit />} />
    </Route>

    <Route path={clientRoutes.USERS}>
      <Route index element={<UsersList />} />
      <Route path={clientRoutes.USERS_CREATE} element={<UsersCreate />} />
      <Route path={clientRoutes.USERS_EDIT} element={<UsersEdit />} />
    </Route>

    <Route path={clientRoutes.ROLES}>
      <Route index element={<RolesList />} />
    </Route>

    <Route path={clientRoutes.PROFILE} element={<ProfileEdit />} />
    <Route path="*" element={<NotFound />} />
  </Route>,
)
