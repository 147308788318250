import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import type {GetPermitRevisionResponseSchema} from 'common/responses'
import * as resources from 'constants/resources'
import type {FC, SyntheticEvent} from 'react'
import {useState} from 'react'
import {createOneQuery} from '../../../hooks/api'
import Button from '../../containers/Button'
import FormView from '../../form/FormView'
import RevisionMeta from '../../form/RevisionMeta'
import {Modal} from '../../visual'
import {CancellationPanelFieldsGeneral} from './panels/CancellationPanel'
import {GeneralPanelFieldsGeneral, GeneralPanelFieldsNote} from './panels/GeneralPanel'


export const queryDef = ({revisionId}: {revisionId: number}) => createOneQuery<GetPermitRevisionResponseSchema>({
  resource: resources.PERMIT_REVISIONS,
  id: revisionId,
})

type PermitsRevisionDetailProps = {
  revisionId: GetPermitRevisionResponseSchema['revisionId'] | null
  onClose: () => void
}

const PermitRevisionDetail: FC<PermitsRevisionDetailProps> = ({revisionId, onClose}) => {
  const {data} = useQuery({
    ...queryDef({revisionId: Number(revisionId)}),
    enabled: Boolean(revisionId),
    suspense: false,
  })
  const permit = data?.data
  const [expanded, setExpanded] = useState<string | boolean>('general')

  const handleChange = (panel: string) => (_event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }
  
  return (
    <Modal open={Boolean(revisionId)} onClose={onClose}>
      <Modal.Title>Detail záznamu změny vlečky</Modal.Title>
      <Modal.Content>
        <FormView values={{...permit}}>
          <RevisionMeta />
          <div>
            <Accordion expanded={expanded === 'general'} onChange={handleChange('general')}>
              <AccordionSummary>Základní údaje</AccordionSummary>
              <AccordionDetails>
                <GeneralPanelFieldsGeneral readOnly />
                <GeneralPanelFieldsNote readOnly />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'cancellation'} onChange={handleChange('cancellation')}>
              <AccordionSummary>Ukončení povolení</AccordionSummary>
              <AccordionDetails>
                <CancellationPanelFieldsGeneral isNewRecord={!permit} readOnly />
              </AccordionDetails>
            </Accordion>
          </div>
        </FormView>
      </Modal.Content>
      <Modal.Actions>
        <Button color="neutral" onClick={onClose}>
          Zavřít
        </Button>
      </Modal.Actions>
    </Modal>
  )
}


export default PermitRevisionDetail
