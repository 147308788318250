import {QueryErrorResetBoundary} from '@tanstack/react-query'
import type {SnackbarKey} from 'notistack'
import {useSnackbar} from 'notistack'
import {Suspense, useEffect, useMemo} from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {Outlet, RouterProvider, createBrowserRouter, useRouteError} from 'react-router-dom'
import AppShell from './components/containers/AppShell'
import routes from './components/screens'
import ErrorFallback from './components/screens/ErrorFallback'
import SplashScreen from './components/screens/SplashScreen'
import Login from './components/screens/login/Login'
import {ApiProvider, useOnline} from './hooks/api'
import {AuthProvider, useSession} from './hooks/auth'
import {AlertsProvider} from './hooks/useAlert'
import {MenuProvider, useMenu} from './hooks/useMenu'
import ThemeProvider from './theme/ThemeProvider'


const Shell = () => {
  const {openMenu, setOpenMenu} = useMenu()
  const menuClick = () => {
    setOpenMenu((prevOpen) => !prevOpen)
  }

  return (
    <AppShell onMenuClick={menuClick} openMenu={openMenu}>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </AppShell>
  )
}

const ErrorElement = () => {
  const error = useRouteError()
  throw error
}

const Screens = () => {
  const session = useSession()
  const isOnline = useOnline()
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()

  useEffect(() => {
    let snackbarKey: SnackbarKey | null = null
    if (!isOnline) {
      snackbarKey = enqueueSnackbar('Nemáte připojení k internetu nebo server je nedostupný', {
        variant: 'error',
        persist: true,
      })
    }
    return () => {
      if (snackbarKey) {
        closeSnackbar(snackbarKey)
        snackbarKey = null
      }
    }
  }, [isOnline, enqueueSnackbar, closeSnackbar])

  const router = useMemo(() => createBrowserRouter([
    {
      id: 'root',
      element: <Shell />,
      errorElement: <ErrorElement />,
      children: routes(),
    },
  ]), [])

  if (!session) return <Login />

  return (
    <RouterProvider router={router} />
  )
}

const App = () => (
  <QueryErrorResetBoundary>
    {({reset}) => (
      <ErrorBoundary
          onReset={reset}
          fallbackRender={({resetErrorBoundary}) => (
            <ErrorFallback resetError={resetErrorBoundary} />
          )}
      >
        <ThemeProvider>
          <AlertsProvider>
            <AuthProvider>
              <ApiProvider>
                <MenuProvider>
                  <Screens />
                </MenuProvider>
              </ApiProvider>
            </AuthProvider>
          </AlertsProvider>
        </ThemeProvider>
      </ErrorBoundary>
    )}
  </QueryErrorResetBoundary>
)


export default App
