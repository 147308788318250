import {Grid} from '@mui/material'
import {If} from 'babel-plugin-jsx-control-statements'
import invariant from 'invariant'
import type {ComponentProps, FC, ReactNode} from 'react'
import {Children} from 'react'


type ItemProps = {
  children: ReactNode
}

const Items: FC<ItemProps> = ({children}) => {
  const childrenArray = Children.toArray(children)
  return (
    <>
      {Children.map(childrenArray, (child) => (
        <Grid item xs={12}>
          {child}
        </Grid>
      ))}
    </>
  )
}

type FormLayoutProps = {
  spacing?: ComponentProps<typeof Grid>['spacing'],
  reversed?: boolean,
  children: ReactNode
}

const AsymetricLayout: FC<FormLayoutProps> & {Items: typeof Items} = ({spacing = 2, reversed, children}) => {
  const childrenCount = Children.count(children)
  const [leftChildren, rightChildren] = Children.toArray(children)
  invariant(childrenCount <= 2, 'FormLayout can only have two children')
  return (
    <Grid container spacing={spacing} alignItems="baseline">
      <If condition={leftChildren}>
        <Grid item container xs={12} md={reversed ? 5 : 7} spacing={spacing}>
          {leftChildren}
        </Grid>
      </If>
      <If condition={rightChildren}>
        <Grid item container xs={12} md={reversed ? 7 : 5} spacing={spacing}>
          {rightChildren}
        </Grid>
      </If>
    </Grid>
  )
}

AsymetricLayout.Items = Items

export default AsymetricLayout
