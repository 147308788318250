export const SYNTAX_ERROR = 'SYNTAX_ERROR'
export const VALIDATION_ERROR = 'VALIDATION_ERROR'
export const EXTERNAL_SERVICE_ERROR = 'EXTERNAL_SERVICE_ERROR'

export const NOT_FOUND_ERROR = 'NOT_FOUND_ERROR'
export const AUTHORIZATION_ERROR = 'AUTHORIZATION_ERROR'
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR'
export const INVALID_CREDENTIALS_ERROR = 'INVALID_CREDENTIALS_ERROR'
export const REFERENCE_ERROR = 'REFERENCE_ERROR'
export const PERMISSIONS_ERROR = 'PERMISSIONS_ERROR'
export const SIDING_ALREADY_REMOVED_ERROR = 'SIDING_ALREADY_REMOVED_ERROR'
export const NO_CONNECTION_TO_MAIN_TRACK_ERROR = 'NO_CONNECTION_TO_MAIN_TRACK_ERROR'
