import {Link as MuiLink} from '@mui/material'
import type {ComponentProps, FC} from 'react'
import {Link as RouterLink} from 'react-router-dom'


type LinkProps = Omit<ComponentProps<typeof MuiLink>, 'component'> & ComponentProps<typeof RouterLink>

const Link: FC<LinkProps> = ({children, to, ...props}) => {
  return (
    <MuiLink component={RouterLink} to={to} {...props}>
      {children}
    </MuiLink>
  )
}

export default Link
