import FilterListIcon from '@mui/icons-material/FilterList'
import type {Theme} from '@mui/material'
import {Badge, IconButton, useMediaQuery} from '@mui/material'
import {Choose, If, Otherwise, When} from 'babel-plugin-jsx-control-statements'
import type {JSONValue} from 'common/schemas'
import {countBy, isArray, isEmpty, isUndefined, omit, omitBy} from 'lodash'
import type {Dispatch, FC, ReactNode, SetStateAction} from 'react'
import {useState} from 'react'
import {Form} from 'react-final-form'
import {FiltersContainer, FiltersContainerDrawer} from '../visual'
import Button from './Button'


type Query = {filter?: Record<string, JSONValue>, page: number}

type FiltersDrawerProps = {
  alwaysVisibleFilters?: string[]
  query: Query
  onApply?: () => void
  onReset?: () => void
  children: ReactNode
}

const FiltersDrawer: FC<FiltersDrawerProps> = ({alwaysVisibleFilters = [], query, onApply, onReset, children}) => {
  const [open, setOpen] = useState(false)
  const {false: filtersCount} = countBy(omit(query.filter, alwaysVisibleFilters), isUndefined)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))


  const handleApply = () => {
    setOpen(false)
    if (onApply) {
      onApply()
    }
  }

  const handleReset = () => {
    if (onReset) {
      onReset()
    }
  }

  const handleClose = () => {
    setOpen(false)
    if (onApply) {
      onApply()
    }
  }

  return (
    <>
      <Badge invisible={!filtersCount} color="primary" badgeContent={filtersCount} >
        <Choose>
          <When condition={isMobile}>
            <IconButton onClick={() => setOpen(true)}>
              <FilterListIcon />
            </IconButton>
          </When>
          <Otherwise>
            <Button icon={<FilterListIcon />} onClick={() => setOpen(true)}>
              Filtry
            </Button>
          </Otherwise>
        </Choose>
      </Badge>
      <FiltersContainerDrawer
          open={open}
          anchor="right"
          onClose={handleClose}
          actions={
            <>
              <Button fullWidth={false} variant="text" color="neutral" onClick={handleReset}>Vyčistit filtry</Button>
              <Button fullWidth={false} variant="contained" onClick={handleApply}>Aplikovat filtry</Button>
            </>
          }
      >
        {children}
      </FiltersContainerDrawer>
    </>
  )
}

type FiltersProps = {
  query: Query
  setQuery: Dispatch<SetStateAction<Query>>
  children: ({handleSubmit, handleReset}: {handleSubmit: () => void, handleReset: () => void}) => ReactNode
}

const Filters: FC<FiltersProps> & {Drawer: typeof FiltersDrawer} = ({query, setQuery, children}) => {
  const handleSave = async (filter: Record<string, JSONValue>) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      page: 1,
      filter: omitBy(filter, (value) => isArray(value) && isEmpty(value)),
    }))
  }

  const handleReset = () => {
    setQuery((prevQuery) => ({...prevQuery, filter: undefined}))
  }

  return (
    <Form
        initialValues={query?.filter || {}}
        onSubmit={handleSave}
    >
      {({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <FiltersContainer
              actions={
                <If condition={!isEmpty(query?.filter)}>
                  <Button color="neutral" variant="text" onClick={handleReset}>
                    Vyčistit filtry
                  </Button>
                </If>
              }
          >
            {children({handleSubmit, handleReset})}
          </FiltersContainer>
        </form>
      )}
    </Form>
  )
}

Filters.Drawer = FiltersDrawer

export default Filters
