import {Choose, If, Otherwise, When} from 'babel-plugin-jsx-control-statements'
import type {GetSidingResponseSchema} from 'common/responses'
import {add, differenceInYears, formatDuration, intervalToDuration} from 'date-fns'
import {cs} from 'date-fns/locale'
import type {FC} from 'react'
import * as clientRoutes from '../../../../constants/routes'
import Link from '../../../containers/Link'
import DateInput from '../../../form/DateInput'


type Supervision = GetSidingResponseSchema['supervisions'][number]

type SupervisionDateInputProps = {
  name: string,
  supervision?: Supervision | null,
}

const formatSupervisionDistance = (start: Date, end: Date) => {
  if (!start || !end) return null
  const interval = intervalToDuration({start, end})
  return formatDuration(interval, {format: ['years', 'months', 'days'], locale: cs})
}

const SupervisionDateInput: FC<SupervisionDateInputProps> = ({name, supervision}) => {
  const supervisedAt = new Date(Number(supervision?.supervisedAt))
  const difference = differenceInYears(new Date(), supervisedAt)
  const formatedDistance = supervision ? formatSupervisionDistance(new Date(), add(supervisedAt, {years: 3})) : null

  return (
    <DateInput
        name={name}
        label="Výkon státního dozoru"
        fieldProps={{defaultValue: supervision?.supervisedAt}}
        helperText={(
          <If condition={supervision}>
            <Choose>
              <When condition={difference < 3}>
                <span>
                  Výkon státního dozoru splňuje lhůtu 3 let (zbýva {formatedDistance}).&nbsp;
                  <Link to={{pathname: clientRoutes.SUPERVISIONS, search: `?supervisionId=${supervision?.id}`}}>
                    Přejít na detail dozoru
                  </Link>
                </span>
              </When>
              <Otherwise>
                <span>
                  Státní dozor byl vykonán před více než 3 lety.&nbsp;
                  <Link to={{pathname: clientRoutes.SUPERVISIONS, search: `?supervisionId=${supervision?.id}`}}>
                    Přejít na detail dozoru
                  </Link>
                </span>
              </Otherwise>
            </Choose>
          </If>
        )}
        helperTextProps={{variant: difference < 3 ? 'success' : 'error'}}
        readOnly
    />
  )
}

export default SupervisionDateInput
