import * as schemas from 'common/schemas'
import withForm from '../../form/withForm'
import {FormSection, Tabs} from '../../visual'
import GeneralPanel, {GENERAL_PANEL_LABEL} from './panels/GeneralPanel'


const schema = schemas.foreignCarrier.foreignCarrier

const tabs = [GENERAL_PANEL_LABEL]

type ForeignCarriersFormProps = {
  readOnly?: boolean
}

const ForeignCarriersForm = withForm({schema})<ForeignCarriersFormProps>(({readOnly}) => {
  return (
    <FormSection title="Detail zahraničního dopravce">
      <Tabs labels={tabs} value={0}>
        <Tabs.Panel value={0} index={0}>
          <GeneralPanel readOnly={readOnly} />
        </Tabs.Panel>
      </Tabs>
    </FormSection>
  )
})

export default ForeignCarriersForm
