import {useQuery} from '@tanstack/react-query'
import {If} from 'babel-plugin-jsx-control-statements'
import type {GetLicenseResponseSchema} from 'common/responses'
import * as schemas from 'common/schemas'
import {EDIT_LICENSES} from 'constants/permissions'
import * as resources from 'constants/resources'
import {omit} from 'lodash-es'
import type {ComponentProps} from 'react'
import {useNavigate} from 'react-router'
import * as clientRoutes from '../../../constants/routes'
import {createOneQuery, useUpdateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import useParams from '../../../hooks/useParams'
import usePermitted from '../../../hooks/usePermitted'
import useQueryParams from '../../../hooks/useQueryParams'
import AsymetricLayout from '../../containers/AsymetricLayout'
import Breadcrumbs from '../../containers/Breadcrumbs'
import ConfirmModal from '../../containers/ConfirmModal'
import FormBlocker from '../../containers/FormBlocker'
import Revisions from '../../containers/Revisions'
import FormErrorAlert from '../../containers/alerts/FormErrorAlert'
import ReadOnlyAlert from '../../containers/alerts/ReadOnlyAlert'
import TextInput from '../../form/TextInput'
import {ScreenWrapper, ScreenHeader} from '../../visual'
import NotFound from '../NotFound'
import LicensesForm from './LicensesForm'
import LicenseRevisionDetail from './LicensesRevisionDetail'


const schema = {
  revisionId: schemas.nonNegativeInteger().optional(),
}

export const queryDef = ({licenseId}: {licenseId: number}) => createOneQuery<GetLicenseResponseSchema>({
  resource: resources.LICENSES,
  id: licenseId,
})

const LicensesEdit = () => {
  const canEdit = usePermitted([EDIT_LICENSES])
  const {licenseId} = useParams()
  const [query, setQuery] = useQueryParams(schema)
  const navigate = useNavigate()
  const showAlert = useAlert()
  const {data} = useQuery({...queryDef({licenseId: Number(licenseId)}), enabled: Boolean(Number(licenseId))})
  const updateLicenses = useUpdateResource({resource: resources.LICENSES, id: licenseId})
  const license = data?.data


  if (!license) return <NotFound />

  const hadndleUpdateLicenses: ComponentProps<typeof LicensesForm>['onSubmit'] = async (values) => {
    await updateLicenses.mutateAsync({
      trackType: values.trackType || null,
      transportType: values.transportType,
      transportCategory: values.transportCategory,
      carrierId: values.carrierId,
      validFrom: values.validFrom || null,
      newLaw: values.newLaw,
      notes: values.notes || '',
      cancelledAt: values.cancelledAt || null,
      cancelledRef: values.cancelledRef || '',
      cancelledReason: values.cancelledReason || null,
      cancelledGinisPid: values.cancelledGinisPid || null,
      revisionNote: values.revisionNote || '',
    })
    navigate(clientRoutes.LICENSES)
    showAlert(`Licence ${licenseId} uložena`, 'success')
  }

  return (
    <LicensesForm
        initialValues={license}
        onSubmit={hadndleUpdateLicenses}
        innerProps={{license, readOnly: !canEdit}}
    >
      {({form, initialValues, valid, handleSubmit}) => (
        <ScreenWrapper>
          <FormBlocker />
          <ScreenHeader
              title={`Licence ${license.id}`}
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item>Evidence vleček</Breadcrumbs.Item>
                  <Breadcrumbs.Item to={clientRoutes.LICENSES}>Licence</Breadcrumbs.Item>
                  <Breadcrumbs.Item active>Licence {license.id}</Breadcrumbs.Item>
                </Breadcrumbs>
              }
          >
            <If condition={canEdit}>
              <ConfirmModal
                  title="Přejete si uložit změnu údajů?"
                  valid={valid}
                  onConfirm={handleSubmit}
              >
                <p>
                  Chystáte se uložit veškeré změny, které byly na kartě Licence provedeny.
                  Detail změn si budete moci zobrazit v historii licence. Veškeré údaje lze kdykoli opět přepsat.
                </p>
                <TextInput name="revisionNote" label="Zpráva" multiline />
              </ConfirmModal>
            </If>
          </ScreenHeader>
          <FormErrorAlert />
          <If condition={!canEdit}>
            <ReadOnlyAlert />
          </If>
          <AsymetricLayout>
            <AsymetricLayout.Items>
              {form}
            </AsymetricLayout.Items>
            <AsymetricLayout.Items>
              <Revisions
                  resource={resources.LICENSE_REVISIONS}
                  query={{licenseId: 'id' in initialValues && Number(initialValues?.id)}}
                  onRevisionClick={(revisionId) => setQuery((prevQuery) => ({...prevQuery, revisionId}))}
              />
              <LicenseRevisionDetail revisionId={query.revisionId || null} onClose={() => setQuery((prevQuery) => omit(prevQuery, 'revisionId'))} />
            </AsymetricLayout.Items>
          </AsymetricLayout>
        </ScreenWrapper>
      )}
    </LicensesForm>
  )
}

export default LicensesEdit
