import type {CellContext} from '@tanstack/react-table'
import {createColumnHelper} from '@tanstack/react-table'
import type {ListSidingResponseSchema} from 'backend/src/controllers/sidings/ListSiding'
import * as resources from 'constants/resources'
import type {ComponentProps, Dispatch, FC, SetStateAction} from 'react'
import {useNavigate} from 'react-router'
import * as clientRoutes from '../../../constants/routes'
import generatePath from '../../../utils/generatePath'
import {getSortableColumns} from '../../../utils/lists'
import ControlledDatagrid from '../../containers/Datagrid/ControlledDatagrid'
import Fallback from '../../containers/Datagrid/cells/Fallback'
import dateCell from '../../containers/Datagrid/cells/dateCell'
import optionalCell from '../../containers/Datagrid/cells/optionalCell'
import relationalCell from '../../containers/Datagrid/cells/relationalCell'
import {getSupervisionColor, supervisionColorMap} from '../../form/forms'
import {ColorDot} from '../../visual'


const supervisionCell = <TData extends {requiresVerification: boolean}, TValue extends number>(
  {getValue, row}: CellContext<TData, TValue>,
) => {
  const value = getValue()
  if (!value) return <Fallback>Neuvedeno</Fallback>
  const color = row.original.requiresVerification
    ? supervisionColorMap.verification
    : getSupervisionColor(new Date(value))
  return (
    <>
      <ColorDot color={color || undefined}>
        {dateCell({})({getValue})}
      </ColorDot>
    </>
  )
}

const columnHelper = createColumnHelper<ListSidingResponseSchema[number]>()

const columns = [
  columnHelper.accessor('id', {header: 'ID'}),
  columnHelper.accessor('permit.id', {
    id: 'permit.id',
    header: 'Číslo ÚP',
    cell: relationalCell({resource: resources.PERMITS, id: 'permit.id', fallback: 'Zaniklo'}),
  }),
  columnHelper.accessor('name', {header: 'Název vlečky'}),
  columnHelper.accessor('permit.company.name', {
    id: 'company.name',
    header: 'Provozovatel',
    cell: relationalCell({resource: resources.COMPANIES, id: 'permit.company.id', fallback: 'Bez provozovatele'}),
  }),
  columnHelper.accessor('owners', {
    header: 'Vlastníci vlečky',
    cell: relationalCell({
      resource: resources.COMPANIES,
      id: 'id',
      render: (value: ListSidingResponseSchema[number]['owners'][number]) => value?.name,
    }),
    enableSorting: false,
  }),
  columnHelper.accessor('mouthing', {header: 'Zaústění', cell: optionalCell({fallback: 'Neuvedeno'})}),
  columnHelper.accessor('supervisionOperational.supervisedAt', {
    id: 'supervisionOperational.supervisedAt',
    header: 'SD PT',
    cell: supervisionCell,
  }),
  columnHelper.accessor('supervisionInfrastructure.supervisedAt', {
    id: 'supervisionInfrastructure.supervisedAt',
    header: 'SD Infra',
    cell: supervisionCell,
  }),
  columnHelper.accessor('sidingCancellation.cancelledAt', {
    header: 'Zrušena dne',
    cell: dateCell({fallback: 'Nezrušena'}),
    enableSorting: false,
  }),
]

export const sortableColumns = getSortableColumns(columns)

type Query = ComponentProps<typeof ControlledDatagrid>['query']

type SidingsTableProps = {
  sidings: ListSidingResponseSchema
  total: number | null
  query: Query
  setQuery: Dispatch<SetStateAction<Query>>
}

const SidingsTable: FC<SidingsTableProps> = ({
  sidings, total, query, setQuery, ...props
}) => {
  const navigate = useNavigate()
  return (
    <ControlledDatagrid<ListSidingResponseSchema[number]>
        data={sidings}
        columns={columns}
        total={total}
        query={query}
        setQuery={setQuery}
        onRowClick={(row) => navigate(
          generatePath(clientRoutes.SIDINGS_EDIT, {sidingId: row.id}),
        )}
        {...props}
    />
  )
}

export default SidingsTable
