import type z from 'zod'
import * as common from './common'
import * as types from './types'


export const supervision = types.object({
  supervisedAt: types.timestamp(),
  referenceNumber: types.requiredString(),
  ginisPid: types.requiredString(),
  department: common.department(),
  sidingId: types.id(),
  companyId: types.id(),
  userIds: types.array(types.id()).min(1),
})
export type Supervision = z.infer<typeof supervision>
