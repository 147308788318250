import type z from 'zod'
import * as common from './common'
import * as types from './types'


export const role = types.object({
  name: types.requiredString('admin'),
  permissions: types.array(common.permissions()).optional(),
})
export type Role = z.infer<typeof role>
