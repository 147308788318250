import {Divider, Typography} from '@mui/material'
import * as enums from 'common/labels/enums'
import type {ListCompanyResponseSchema, ListSidingResponseSchema} from 'common/responses'
import * as resources from 'constants/resources'
import type {FC} from 'react'
import * as clientRoutes from '../../../../constants/routes'
import DateInput from '../../../form/DateInput'
import EnumInput from '../../../form/EnumInput'
import ReferenceInput from '../../../form/ReferenceInput'
import TextInput from '../../../form/TextInput'
import {InputStack, Tabs} from '../../../visual'


export const GeneralPanelFieldsGeneral = ({readOnly}: {readOnly?: boolean}) => {
  return (
    <>
      <InputStack>
        <DateInput name="validFrom" label="Platnost od" readOnly={readOnly} />
        <EnumInput name="type" label="Typ ÚP" enumName={enums.PERMIT_TYPES} readOnly={readOnly} />
      </InputStack>
      <InputStack>
        <ReferenceInput<ListSidingResponseSchema>
            name="sidingId"
            optionText="name"
            label="Přiřazená trať/vlečka"
            resource={resources.SIDINGS}
            tagRoute={({value}) => `${clientRoutes.SIDINGS}/${value}`}
            readOnly={readOnly}
            required
        />
        <ReferenceInput<ListCompanyResponseSchema>
            name="operatorId"
            optionText="name"
            label="Obchodní jméno provozovatele dráhy"
            resource={resources.COMPANIES}
            tagRoute={({value}) => `${clientRoutes.COMPANIES}/${value}`}
            readOnly={readOnly}
            required
        />
      </InputStack>
    </>
  )
}

export const GeneralPanelFieldsNote = ({readOnly}: {readOnly?: boolean}) => {
  return (
    <TextInput name="notes" label="Poznámka" placeholder="Zadejte libovolnou poznámku" minRows={5} multiline readOnly={readOnly} />
  )
}

type GeneralPanelProps = {
  readOnly?: boolean
}

const GeneralPanel: FC<GeneralPanelProps> = ({readOnly}) => {
  return (
    <>
      <Tabs.Section>
        <GeneralPanelFieldsGeneral readOnly={readOnly} />
      </Tabs.Section>
      <Divider />
      <Tabs.Section>
        <Typography fontWeight="bold">Poznámka</Typography>
        <GeneralPanelFieldsNote readOnly={readOnly} />
      </Tabs.Section>
    </>
  )
}

export const GENERAL_PANEL_LABEL = 'Základní údaje'
export default GeneralPanel
