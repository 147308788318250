import AddIcon from '@mui/icons-material/Add'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {Divider, IconButton, Stack, Typography} from '@mui/material'
import {If} from 'babel-plugin-jsx-control-statements'
import closedArealTypes from 'common/labels/closedArealTypes'
import * as enums from 'common/labels/enums'
import {isString, map} from 'lodash'
import type {ComponentProps, FC} from 'react'
import {FieldArray} from 'react-final-form-arrays'
import Button from '../../../containers/Button'
import EnumInput from '../../../form/EnumInput'
import GinisPidInput from '../../../form/GinisPidInput'
import RadioGroupInput from '../../../form/RadioGroupInput'
import TextInput from '../../../form/TextInput'
import {InputStack, Tabs} from '../../../visual'
import SupervisionDateInput from '../components/SupervisionDateInput'


type Supervision = ComponentProps<typeof SupervisionDateInput>['supervision']

const closedArealTypesOptions = map(closedArealTypes, (label, value) => ({value, label}))
const hasTrolleyOptions = [{value: 'true', label: 'Ano'}, {value: 'false', label: 'Ne'}]

export const InfrastructurePanelFieldsGeneral = ({readOnly}: {readOnly?: boolean}) => {
  return (
    <>
      <RadioGroupInput
          name="closedAreal"
          label="Vlečka se nachází v uzavřeném areálu"
          options={closedArealTypesOptions}
          readOnly={readOnly}
      />
      <RadioGroupInput
          name="hasTrolley"
          label="Vlečka má trolej"
          options={hasTrolleyOptions}
          fieldProps={{format: (value) => String(value)}}
          readOnly={readOnly}
      />
    </>
  )
}

export const InfrastructurePanelFieldsCoordinates = ({readOnly}: {readOnly?: boolean}) => {
  return (
    <>
      <InputStack>
        <TextInput type="number" name="coordinatesX" label="Začátek vlečky X" readOnly={readOnly} />
        <TextInput type="number" name="coordinatesY" label="Začátek vlečky Y" readOnly={readOnly} />
      </InputStack>
      <InputStack>
        <TextInput type="number" name="coordinatesX2" label="Začátek vlečky X2" readOnly={readOnly} />
        <TextInput type="number" name="coordinatesY2" label="Začátek vlečky Y2" readOnly={readOnly} />
      </InputStack>
    </>
  )
}

export const InfrastructurePanelFieldsDocuments = (
  {isNewRecord = false, readOnly}: {isNewRecord?: boolean, readOnly?: boolean},
) => {
  return (
    <FieldArray name="infrastructureDocuments">
      {({fields, meta: {error, touched}}) => (
        <>
          {fields.map((name, index) => (
            <Stack useFlexGap direction={{sm: 'column', md: 'row'}} alignItems="center" gap={2} key={name}>
              <EnumInput
                  name={`${name}.type`}
                  label="Typ dokumentu"
                  enumName={enums.INFRASTRUCTURE_DOCUMENT_TYPES}
                  readOnly={readOnly}
                  required
              />
              <TextInput
                  name={`${name}.referenceNumber`}
                  label="Číslo jednací"
                  readOnly={readOnly}
                  required
              />
              <GinisPidInput
                  name={`${name}.ginisPid`}
                  label="PID GINIS"
                  isNewRecord={isNewRecord}
                  readOnly={readOnly}
                  required
              />
              <If condition={!readOnly}>
                <IconButton
                    color="error"
                    onClick={() => {
                      fields.remove(index)
                    }}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </If>
            </Stack>
          ))}
          <If condition={!readOnly}>
            <Stack alignItems="end">
              <If condition={touched && isString(error)}>
                <Typography color="error">{error}</Typography>
              </If>
              <Button variant="text" color="neutral" onClick={() => fields.push({})} endIcon={<AddIcon />}>Přidat dokument</Button>
            </Stack>
          </If>
        </>
      )}
    </FieldArray>
  )
}

export const InfrastructurePanelFieldsSupervisions = (
  {currentSupervisionInfrastructure}: {currentSupervisionInfrastructure: Supervision},
) => {
  return (
    <SupervisionDateInput name="supervisionInfrastrucure" supervision={currentSupervisionInfrastructure} />
  )
}

export const InfrastructurePanelFieldsNotes = ({readOnly}: {readOnly?: boolean}) => {
  return (
    <TextInput
        name="notesInfrastructure"
        label="Poznámka sekce infrastruktury"
        placeholder="Zadejte libovolnou poznámku"
        minRows={5}
        readOnly={readOnly}
        multiline
    />
  )
}

type InfrastructurePanelProps = {
  isNewRecord: boolean
  currentSupervisionInfrastructure: Supervision
  readOnly?: boolean
}

const InfrastructurePanel:FC<InfrastructurePanelProps> = ({
  isNewRecord, currentSupervisionInfrastructure, readOnly,
}) => {
  return (
    <>
      <Tabs.Section>
        <InfrastructurePanelFieldsGeneral readOnly={readOnly} />
      </Tabs.Section>
      <Divider />
      <Tabs.Section>
        <Typography fontWeight="bold">Koordináty vlečky</Typography>
        <InfrastructurePanelFieldsCoordinates readOnly={readOnly} />
      </Tabs.Section>
      <Divider />
      <Tabs.Section>
        <Typography fontWeight="bold">Dokumenty infrastruktury</Typography>
        <InfrastructurePanelFieldsDocuments isNewRecord={isNewRecord} readOnly={readOnly} />
      </Tabs.Section>
      <Divider />
      <Tabs.Section>
        <Typography fontWeight="bold">Státní dozory</Typography>
        <SupervisionDateInput name="supervisionInfrastrucure" supervision={currentSupervisionInfrastructure} />
      </Tabs.Section>
      <Divider />
      <Tabs.Section>
        <Typography fontWeight="bold">Poznámky</Typography>
        <InfrastructurePanelFieldsNotes readOnly={readOnly} />
      </Tabs.Section>
    </>
  )
}

export const INFRASTRUCTURE_PANEL_LABEL = 'Infrastruktura'
export default InfrastructurePanel
